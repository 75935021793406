import { Fragment } from "react";
import { TableToggleWrapper, RTLWrapper } from "./styled";
import { CURRENCY_CONVERT, getTimeOfMillisecondsWithTimezone } from "utils";
import Toggle from "@swvl/toggle";
import omit from "lodash/omit";
import { Column } from "react-table";
import { Plan } from "resources/Plan/useListPlans";
import Tooltip from "@swvl/tooltip";

export const planColumns: Column<Plan>[] = [
  {
    Header: "Plan Name",
    accessor: "nameI18n",
    id: "name",
    Cell: ({ value }) => {
      return value.en;
    },
  },
  {
    Header: "Local Name",
    accessor: "nameI18n",
    id: "localName",
    Cell: ({ value, cell: { getCellProps } }) => {
      const { key } = getCellProps();
      const localLanguages = omit(value, "en");
      const languagesString = Object.values(localLanguages).join(", ");

      return (
        <Fragment>
          <RTLWrapper data-for={key} data-tip={languagesString}>
            {languagesString}
          </RTLWrapper>
          <Tooltip id={key as string} />
        </Fragment>
      );
    },
  },
  {
    Header: "Time",
    Cell: ({
      row: {
        original: { schedules },
      },
    }) => {
      if (schedules.length) {
        return `${getTimeOfMillisecondsWithTimezone(
          schedules[0].time,
          schedules[0].city.timezone
        )} - ${getTimeOfMillisecondsWithTimezone(
          schedules[schedules.length - 1].predictedEndTime,
          schedules[schedules.length - 1].city.timezone
        )}`;
      } else return <span>-</span>;
    },
  },
  {
    Header: "Bustype",
    accessor: "busType",
    Cell: ({ value }) => {
      return value?.name;
    },
  },
  {
    Header: "Total KMs",
    accessor: "rideKMs",
  },
  {
    Header: "Price",
    accessor: "price",
    Cell: ({ value }) => {
      return `${(value?.amount / CURRENCY_CONVERT).toFixed(2)}
      ${value.currency}`;
    },
  },
  {
    Header: "Bidding Campaign ",
    accessor: "isOnBidding",
    Cell: ({ value }) => {
      return value ? "Yes" : "No";
    },
  },
  {
    Header: "No. of Schedules",
    accessor: "noOfSchedules",
  },
  {
    Header: "Active",
    accessor: "active",
    Cell: ({ value }) => {
      return (
        <TableToggleWrapper>
          <Toggle id="table-status" labelOff="" labelOn="" checked={value} />
        </TableToggleWrapper>
      );
    },
  },
];
