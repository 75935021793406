import { useMutation, useQueryClient } from "react-query";
import { showAlert } from "@swvl/alert";
import apiInstance from "api";

const updateBustype = async ({ planId, busType }) => {
  return await apiInstance.patch(`plans/${planId}/update_bustype`, {
    busType,
  });
};

const useUpdatePlanBustype = (id) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(updateBustype, {
    mutationKey: "plans/update_bustype",
    onSuccess: () => {
      queryClient.invalidateQueries(["plan/details", id]);
      showAlert({
        message: "Plan Bustype has been updated successfully",
        type: "success",
        id: "alert-success",
      });
    },
  });
  return mutation;
};

export { useUpdatePlanBustype, updateBustype };
