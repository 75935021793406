/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, Text } from "theme-ui";
import { useState } from "react";
import Popup from "@swvl/popup";
import Icon from "@swvl/icon";
import { Select } from "@swvl/select";
import Button from "@swvl/button";

import { useBiddingCancellationReasons } from "resources/PlanSelling/useBiddingCancellationReasons";
import { useCancelBidding } from "resources/PlanSelling/useCancelBidding";

type Props = {
  isOpen: boolean;
  onDismiss: () => void;
  campaignId: string;
  state: string;
  bids: number;
};

const CancelCampaignPopup = ({
  isOpen,
  onDismiss,
  campaignId,
  state,
  bids,
}: Props): JSX.Element => {
  const { data: cancellationReasons } = useBiddingCancellationReasons();
  const [reason, setReason] = useState<{ label: string; value: string }>(null);
  const { mutate: cancelBidding } = useCancelBidding(campaignId);

  return (
    <Popup isOpen={isOpen} closePopup={onDismiss} withClose>
      <div
        sx={{
          py: 3,
          px: 5,
        }}
      >
        <Icon name="warning" size={48} />
        <Text sx={{ fontSize: 3, mt: 3, fontWeight: "bold" }}>
          Cancel Bidding Campaign
        </Text>
        <Text sx={{ fontSize: 2, mt: 2, color: "black-54" }}>
          Are you sure you want to cancel this campaign in the{" "}
          <span sx={{ fontWeight: "bold" }}>{state}</span> state?
        </Text>
        <Text
          sx={{ fontSize: 2, mt: 2, color: "black-54", fontWeight: "bold" }}
        >
          This action will cancel all {bids} bids.
        </Text>
        <Text
          sx={{ fontSize: 2, mt: 4, fontWeight: "bold", textAlign: "left" }}
        >
          Cancellation Reason <span sx={{ color: "crimson" }}>*</span>
        </Text>
        <Select
          sx={{ mt: 2 }}
          label={null}
          name="Cancellation Reason"
          value={reason}
          options={cancellationReasons}
          onChange={(value) => {
            setReason(value);
          }}
        />
        <div
          sx={{
            mt: 4,
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button
            variant="gray"
            disabled={reason === null}
            onClick={() => {
              cancelBidding({
                id: campaignId,
                reason_key: reason.value,
              });
              onDismiss();
            }}
          >
            Yes, Cancel it
          </Button>
          <Button variant="primary" onClick={onDismiss}>
            No, Don&apos;t cancel
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default CancelCampaignPopup;
