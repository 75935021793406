import { jsx, Flex, Heading, Divider } from "theme-ui";
import { useHistory } from "react-router-dom";

import { Button } from "@swvl/button";
import Icon from "@swvl/icon";
import React from "react";

const ListHeader = () => {
  const history = useHistory();
  return (
    <React.Fragment>
      <Flex
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Heading>All Plans</Heading>
        <Button
          variant="primary"
          icon={<Icon name="add" size={20} fill={"white"} />}
          onClick={() => {
            history.push("/plans/create");
          }}
          data-test-id="add_plan_btn"
        >
          Add Plan
        </Button>
      </Flex>
      <Divider color="gainsboro" sx={{ m: "20px -20px" }} />
    </React.Fragment>
  );
};

export default ListHeader;
