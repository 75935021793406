import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import apiInstance from "api";
import { isNil } from "lodash";

const planAutocomplete = "plans/autocomplete";
export interface SearchPlansResponse {
  hits: {
    _id: string;
    _source: {
      nameI18n: { [key: string]: string };
    };
  }[];
}

export type SearchPlansModel = {
  id: string;
  nameI18n: { [key: string]: string };
};

type SearchPlansListModel = SearchPlansModel[];

const transformResponse = (
  response: SearchPlansResponse
): SearchPlansListModel => {
  return response.hits.map((hit) => ({
    id: hit._id,
    ...hit._source,
  })) as SearchPlansListModel;
};

const searchPlans = async (input: string): Promise<SearchPlansListModel> => {
  const response = await apiInstance.get<AxiosResponse, SearchPlansResponse>(
    planAutocomplete,
    {
      params: { query: input },
    }
  );

  return transformResponse(response);
};

const useSearchPlans = (input: string) => {
  return useQuery([planAutocomplete, input], () => searchPlans(input), {
    enabled: !isNil(input),
    initialData: [] as SearchPlansListModel,
    onError: undefined,
  });
};

export { useSearchPlans, searchPlans };
