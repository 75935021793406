import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import isNil from "lodash/fp/isNil";
import apiInstance, { checkIfRequestIntrceptorUsed } from "api";

type PriceModel = {
  amount: number;
  currency: string;
};

type BusTypeModel = {
  id: string;
  name: string;
};

type CityModel = {
  id: string;
  name: string;
};

type CaptainModel = {
  id: string;
  name: string;
};

type CategoryModel = {
  id: string;
  rideType: string;
  serviceType: string;
};

type RouteModel = {
  id: string;
  nameI18n: { [key: string]: string };
};
export type Schedules = {
  city: CityModel;
  category: CategoryModel;
  route: RouteModel;
  startTime: number;
  predictedEndTime: number;
  rideKMs: number;
};
type Location = {
  id: string;
  nameI18n: { [key: string]: string };
};
type BiddingCampaign = {
  id: string;
  numberOfBids: number;
  lowestReceivedBid?: number;
};
export interface PlanDetailsResponse {
  active: boolean;
  busType: BusTypeModel;
  deadKMs: number;
  descriptionI18n: { [key: string]: string };
  id: string;
  labels: string[];
  locked: boolean;
  nameI18n: { [key: string]: string };
  price: PriceModel;
  rideKMs: number;
  schedules: Schedules[];
  state: string;
  type: string;
  weekdays: number[];
  firstDistrict: Location;
  lastDistrict: Location;
  captain: CaptainModel;
  startDate: string;
  biddingCampaign: BiddingCampaign;
}

const getPlanDetails = async ({
  id,
}: {
  id: string;
}): Promise<PlanDetailsResponse> => {
  const response = await apiInstance.get<AxiosResponse, PlanDetailsResponse>(
    `plans/${id}`
  );

  return response;
};

const usePlanDetails = (id: string) => {
  return useQuery(["plan/details", id], () => getPlanDetails({ id }), {
    enabled: !isNil(id) && checkIfRequestIntrceptorUsed(),
    onError: undefined,
  });
};

export { usePlanDetails, getPlanDetails };
