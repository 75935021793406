import {
  useCallback,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";

const useRoveFocus = (
  listLength: number
): [number, Dispatch<SetStateAction<number>>] => {
  const [currentFocusIndex, setCurrentFocusIndex] = useState<number>(-1);
  const handleKeyUpAndDown = useCallback(
    (e: KeyboardEvent) => {
      /** to Handle beginning and end of list */
      if (e.key === "ArrowDown") {
        setCurrentFocusIndex(
          currentFocusIndex === listLength - 1 ? 0 : currentFocusIndex + 1
        );
      } else if (e.key === "ArrowUp") {
        setCurrentFocusIndex(
          currentFocusIndex === 0 ? listLength - 1 : currentFocusIndex - 1
        );
      } else {
        setCurrentFocusIndex(-1);
      }
    },
    [listLength, currentFocusIndex, setCurrentFocusIndex]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyUpAndDown);
    return () => {
      document.removeEventListener("keydown", handleKeyUpAndDown);
    };
  }, [handleKeyUpAndDown]);

  return [currentFocusIndex, setCurrentFocusIndex];
};

export default useRoveFocus;
