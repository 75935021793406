/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from "theme-ui";
import { useRef, useState, useEffect, useCallback, useMemo } from "react";

import DatePicker from "@swvl/date-picker";
import TimePicker from "@swvl/time-picker";
import Button from "@swvl/button";

import set from "date-fns/fp/set";
import format from "date-fns/fp/format";

type DateTimePickerProps = {
  label?: string;
  initialDate?: Date;
  minDate?: Date;
  required?: boolean;
  onChange: (date: Date) => void;
};

const today = set(
  { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 },
  new Date()
);

function getTime(date: Date): [number, number, string] {
  const hours24 = date.getHours();
  const minutes = date.getMinutes();
  const hours12 = hours24 % 12;
  const amPm = hours24 - hours12 === 0 ? "am" : "pm";

  return [hours12, minutes, amPm];
}

function geHoursAndMinutes(timePickerValue: string): [number, number] {
  const [hours, , minutes, amPm] = timePickerValue.split(" ");
  let hours24 = Number(hours);

  if (amPm === "pm") {
    if (Number(hours) < 12) hours24 = Number(hours) + 12;
  } else if (amPm === "am") {
    if (hours === "12") hours24 = 0;
  }

  return [hours24, Number(minutes)];
}

function formatePlaceholder(date: Date): string {
  return format("EEE, MMM do - hh:mm aaa", date);
}

const DateTimePicker = ({
  label = "",
  initialDate = today,
  minDate,
  required,
  onChange,
  ...rest
}: DateTimePickerProps): JSX.Element => {
  const wrapperRef = useRef<HTMLDivElement>();
  const datePickerRef = useRef(null);
  const [timePickerHours, timePickerMinutes, timePickerAmPm] = useMemo(
    () => getTime(initialDate),
    [initialDate]
  );
  const [date, setDate] = useState<Date>(initialDate);
  const [isTimePickerOpen, setIsTimePickerOpen] = useState<boolean>(false);

  const closeOnEsc = useCallback(({ code }) => {
    if (code === "Escape") {
      setIsTimePickerOpen(false);
    }
  }, []);

  const closeOnClickOutside = useCallback(({ target }) => {
    if (target.offsetParent == null) {
      return;
    }

    const wrapperNode = wrapperRef.current;
    if (!wrapperNode?.contains(target)) {
      setIsTimePickerOpen(false);
    }
  }, []);

  useEffect(() => {
    if (isTimePickerOpen) {
      document.addEventListener("keydown", closeOnEsc);
      document.addEventListener("click", closeOnClickOutside, false);
    } else {
      document.removeEventListener("keydown", closeOnEsc);
      document.removeEventListener("click", closeOnClickOutside, false);

      // Update on close
      onChange(date);
    }

    return () => {
      document.removeEventListener("keydown", closeOnEsc);
      document.removeEventListener("click", closeOnClickOutside, false);
    };
  }, [isTimePickerOpen]);

  return (
    <div
      ref={wrapperRef}
      sx={{
        // Time Picker
        "& > div:nth-of-type(2)": {
          // Wrapper
          "& > div:nth-of-type(1)": {
            display: "none",
          },

          //Content
          "& > div:nth-of-type(2)": {
            width: "max-content",
          },
        },
      }}
      {...rest}
    >
      <DatePicker
        ref={datePickerRef}
        label={label}
        placeholder={date ? formatePlaceholder(date) : `Pick ${label}`}
        selected={date}
        startDate={date}
        endDate={date}
        minDate={minDate}
        required={required}
        selectsRange={false}
        onCalendarClose={() => onChange(date)}
        onChange={(date: Date) => {
          const timeSetDate = set(
            {
              seconds: 0,
              milliseconds: 0,
            },
            date
          );
          setDate(timeSetDate);
        }}
      >
        <div sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            data-test-id="Next-Button"
            variant="primary"
            type="button"
            onClick={() => {
              datePickerRef.current.setOpen(false);
              setIsTimePickerOpen(true);
            }}
          >
            Next
          </Button>
        </div>
      </DatePicker>

      <TimePicker
        initialHours={String(timePickerHours)}
        initialMinutes={String(timePickerMinutes)}
        initialAmPm={timePickerAmPm}
        isOpen={isTimePickerOpen}
        onInputChange={(time) => {
          const [hours, minutes] = geHoursAndMinutes(time);
          const timeSetDate = set(
            { hours, minutes, seconds: 0, milliseconds: 0 },
            date
          );

          setDate(timeSetDate);
        }}
      >
        <Button
          data-test-id="Apply-Button"
          variant="primary"
          type="button"
          onClick={() => setIsTimePickerOpen(false)}
        >
          Apply
        </Button>
      </TimePicker>
    </div>
  );
};

export default DateTimePicker;
