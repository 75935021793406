import { useMutation } from "react-query";
import apiInstance from "api";
import { showAlert } from "@swvl/alert";
import { useHistory } from "react-router-dom";
import useLocalStorage from "utils/useLocalStorage";

// Types
export type AuthenticationModel = {
  token?: string;
  expiryDate?: string;
  refreshToken?: string;
  jwt?: string;
  role?: string;
  owner?: string;
  error?: string;
};
type LogoutRequest = {
  token?: string;
  refresh_token?: string;
};
// Initial State
const initialState: AuthenticationModel = {
  token: null,
  expiryDate: null,
  refreshToken: null,
  jwt: null,
  role: null,
  error: null,
};

const logoutUser = async (data: LogoutRequest) => {
  const response = await apiInstance.post<LogoutRequest>("sign_out", data);
  return response;
};

const useLogout = () => {
  const { setValue: setAuthenticationData } =
    useLocalStorage<AuthenticationModel>("ls.authdata");
  const history = useHistory();

  const mutation = useMutation(logoutUser, {
    mutationKey: "user/logout",
    onSuccess: () => {
      setAuthenticationData(initialState);
      history.push("/login");
      showAlert({
        message: "Logout successfully",
        type: "success",
        id: "alert-success",
      });
    },
    onError: (response) => {
      if (response["status"] === 404) {
        setAuthenticationData(initialState);
      }
      showAlert({
        message: "Logout failed",
        type: "error",
        id: "alert-error",
      });
    },
  });

  return mutation;
};

export { useLogout, initialState };
