import { Column } from "react-table";
import format from "date-fns/fp/format";

import { Hit } from "resources/PlanSelling/useListBidding";

export const biddingListColumns: Column<Hit>[] = [
  {
    Header: "City",
    accessor: "active_contract",
    id: "city",
    Cell: ({ value }) => {
      return value.cities.map(({ name }) => name).join(" - ");
    },
  },
  {
    Header: "Start Location",
    accessor: "active_contract",
    id: "start_location",
    Cell: ({ value }) => {
      return value.start_location.name_i18n.en;
    },
  },
  {
    Header: "End Location",
    accessor: "active_contract",
    id: "end_location",
    Cell: ({ value }) => {
      return value.end_location.name_i18n.en;
    },
  },
  {
    Header: "Bus Type",
    accessor: "active_contract",
    id: "bus_type",
    Cell: ({ value }) => {
      return value.bus_type.name;
    },
  },
  {
    Header: "State",
    accessor: "state",
  },
  {
    Header: "Bid Start Date Time",
    accessor: "open_date",
    Cell: ({ value }) => {
      return format("dd/MM/yy, hh:mm a", new Date(value));
    },
  },
  {
    Header: "Bid End Date Time",
    accessor: "close_date",
    Cell: ({ value }) => {
      return format("dd/MM/yy, hh:mm a", new Date(value));
    },
  },
];
