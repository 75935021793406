/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag */
import React, { FC, useEffect, useState } from "react";

import Icon from "@swvl/icon";
import Popup from "@swvl/popup";
import { Button } from "@swvl/button";

import { jsx, Heading, Text, Flex } from "theme-ui";

import { format } from "date-fns";
import { TIME_FORMAT, DATE_TIME_FORMAT } from "../../utils";

import {
  PlanConflictPopupHeader,
  PlanConflictPopupConflictEntityGroup,
  PlanConflictPopupConflictEntity,
  PlanConflictPopupInfo,
} from "./styled";

const Buffer = ({ entityType, value, dataTestId }) => {
  return (
    entityType === "schedule" && (
      <PlanConflictPopupInfo>
        <label htmlFor="entity_type">Buffer</label>
        <Text data-test-id={dataTestId}>{value}</Text>
      </PlanConflictPopupInfo>
    )
  );
};

const StartDateAndEndDate = ({
  entityType,
  startDate,
  endDate,
  startDateTestId,
  endDateTestId,
}) => {
  if (entityType === "ride" || entityType === "backup") {
    const start = format(new Date(startDate), DATE_TIME_FORMAT);
    const end = format(new Date(endDate), DATE_TIME_FORMAT);
    return (
      <React.Fragment>
        <PlanConflictPopupInfo>
          <label htmlFor="entity_start_date">Start date</label>
          <Text data-test-id={startDateTestId}>{start}</Text>
        </PlanConflictPopupInfo>

        <PlanConflictPopupInfo>
          <label htmlFor="entity_end_date">End date</label>
          <Text data-test-id={endDateTestId}>{end}</Text>
        </PlanConflictPopupInfo>
      </React.Fragment>
    );
  } else {
    return null;
  }
};

const StartTimeAndEndTime = ({
  entityType,
  startTime,
  endTime,
  timeZone,
  startTimeTestId,
  endTimeTestId,
}) => {
  if (entityType === "schedule" || entityType === "backupSchedule") {
    const start = format(new Date(startTime - timeZone), TIME_FORMAT);
    const end = format(new Date(endTime - timeZone), TIME_FORMAT);
    return (
      <React.Fragment>
        <PlanConflictPopupInfo>
          <label htmlFor="entity_start_time">Start time</label>
          <Text data-test-id={startTimeTestId}>{start}</Text>
        </PlanConflictPopupInfo>

        <PlanConflictPopupInfo>
          <label htmlFor="entity_end_time">End time</label>
          <Text sx={{ color: "#DE350B" }} data-test-id={endTimeTestId}>
            {end}
          </Text>
        </PlanConflictPopupInfo>
      </React.Fragment>
    );
  } else {
    return null;
  }
};

const ActionBtns = ({
  conflicts,
  onUnassignRides,
  onDismiss,
  showUnassignButton = true,
}) => {
  const [isRideOnly, setIsRideOnly] = useState(true);

  useEffect(() => {
    if (conflicts.length) {
      conflicts.forEach((conflict) => {
        if (conflict.type !== "ride") {
          setIsRideOnly(false);
          return;
        }
      });
    }
  }, [conflicts]);

  return isRideOnly && showUnassignButton ? (
    <div sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
      <Button
        type="button"
        variant="gray"
        onClick={onDismiss}
        data-test-id="cancel_button"
      >
        Cancel
      </Button>
      <Button
        type="button"
        variant="primary"
        data-test-id="unassign_rides"
        onClick={() => onUnassignRides()}
      >
        Unassign rides
      </Button>
    </div>
  ) : (
    <div sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
      <Button
        type="button"
        variant="primary"
        data-test-id="cancel_button"
        onClick={onDismiss}
      >
        Close
      </Button>
    </div>
  );
};

type conflictEntity = {
  id: string;
  startTime?: number;
  endTime?: number;
  startDate?: string;
  endDate?: string;
  buffer?: number;
  type: string;
};

type conflictData = {
  action: string;
  parent: conflictEntity;
  conflicts: conflictEntity[];
};

type ConflictSchedulesPopupProps = {
  conflictData: conflictData;
  isOpen: boolean;
  showUnassignButton?: boolean;
  timeZone: number;
  onUnassignRides?: () => void;
  onDismiss?: () => void;
};

const PlanConflictPopup: FC<ConflictSchedulesPopupProps> = ({
  conflictData,
  isOpen,
  showUnassignButton = true,
  timeZone,
  onUnassignRides,
  onDismiss,
}) => {
  const popupTitle = showUnassignButton
    ? `${conflictData.action.replaceAll(
        "_",
        " "
      )} is conflicting with other entities`
    : "There are conflicting schedules in the plan you are trying to create";

  return (
    <Popup
      withClose={true}
      closePopup={onDismiss}
      isOpen={isOpen}
      width={967}
      data-test-id="conflicting-popup"
    >
      <PlanConflictPopupHeader>
        <Icon name="warning" size={48} />
        <Heading
          sx={{ marginTop: "15px", fontSize: "24px" }}
          data-test-id="conflicting-popup-title"
        >
          {popupTitle}
        </Heading>
      </PlanConflictPopupHeader>

      {/* Parent Entity */}
      <PlanConflictPopupConflictEntityGroup>
        <Text
          sx={{
            fontWeight: "700",
            fontSize: "20px",
            mb: "12px",
          }}
          data-test-id="popup-content"
        >
          {`${conflictData.action.replaceAll("_", " ")}`}
        </Text>
        <PlanConflictPopupConflictEntity>
          <Flex
            sx={{ justifyContent: "space-between" }}
            data-test-id="parent-entity"
          >
            <PlanConflictPopupInfo>
              <label htmlFor="entity_type">Entity type</label>
              <Text data-test-id="parent-entity-type">
                {conflictData.parent.type === "backupSchedule"
                  ? "backup schedule"
                  : conflictData.parent.type}
              </Text>
            </PlanConflictPopupInfo>
            <PlanConflictPopupInfo>
              <label htmlFor="entity_type">Entity ID</label>
              <Text data-test-id="parent-entity-id">
                {conflictData.parent.id}
              </Text>
            </PlanConflictPopupInfo>

            <Buffer
              entityType={conflictData.parent.type}
              value={conflictData.parent.buffer}
              dataTestId="parent-buffer"
            />

            <StartDateAndEndDate
              entityType={conflictData.parent.type}
              startDate={conflictData.parent.startDate}
              endDate={conflictData.parent.endDate}
              startDateTestId="parent-start-date"
              endDateTestId="parent-end-date"
            />

            <StartTimeAndEndTime
              entityType={conflictData.parent.type}
              startTime={conflictData.parent.startTime}
              endTime={conflictData.parent.endTime}
              timeZone={timeZone}
              startTimeTestId="parent-start-time"
              endTimeTestId="parent-end-time"
            />
          </Flex>
        </PlanConflictPopupConflictEntity>
      </PlanConflictPopupConflictEntityGroup>

      {/* Conflicts Entity */}
      <PlanConflictPopupConflictEntityGroup>
        <Text
          sx={{ fontWeight: "700", fontSize: "20px", mb: "12px" }}
          data-test-id="conflicting-entities-header"
        >
          {conflictData.conflicts.length == 1
            ? "1 Conflicting entity"
            : `${conflictData.conflicts.length} Conflicting entities`}
        </Text>
        {conflictData.conflicts.map((conflict) => {
          return (
            <PlanConflictPopupConflictEntity key={conflict.id}>
              <Flex
                sx={{ justifyContent: "space-between" }}
                data-test-id="conflicting-entity"
              >
                <PlanConflictPopupInfo>
                  <label htmlFor="entity_type">Entity type</label>
                  <Text data-test-id="conflicting-entity-type">
                    {conflict.type === "backupSchedule"
                      ? "backup schedule"
                      : conflict.type}
                  </Text>
                </PlanConflictPopupInfo>

                <PlanConflictPopupInfo>
                  <label htmlFor="entity_type">Entity ID</label>
                  <Text data-test-id="conflicting-entity-id">
                    {conflict.id}
                  </Text>
                </PlanConflictPopupInfo>

                <Buffer
                  entityType={conflict.type}
                  value={conflict.buffer}
                  dataTestId="conflicting-entity-buffer"
                />

                <StartDateAndEndDate
                  entityType={conflict.type}
                  startDate={conflict.startDate}
                  endDate={conflict.endDate}
                  startDateTestId="conflicting-start-date"
                  endDateTestId="conflicting-end-date"
                />

                <StartTimeAndEndTime
                  entityType={conflict.type}
                  startTime={conflict.startTime}
                  endTime={conflict.endTime}
                  timeZone={timeZone}
                  startTimeTestId="conflicting-start-time"
                  endTimeTestId="conflicting-end-time"
                />
              </Flex>
            </PlanConflictPopupConflictEntity>
          );
        })}
      </PlanConflictPopupConflictEntityGroup>

      <ActionBtns
        conflicts={conflictData.conflicts}
        onDismiss={onDismiss}
        onUnassignRides={onUnassignRides}
        showUnassignButton={showUnassignButton}
      />
    </Popup>
  );
};

export default PlanConflictPopup;
