export const STATE_OPTIONS = [
  {
    label: "Scheduled",
    value: "scheduled",
  },
  {
    label: "Opened",
    value: "opened",
  },
  {
    label: "Closed",
    value: "closed",
  },
  {
    label: "Completed",
    value: "completed",
  },
  {
    label: "Cancelled",
    value: "cancelled",
  },
];

export const BOOLEAN_OPTIONS = [
  {
    label: "Any",
    value: undefined,
  },
  {
    label: "Yes",
    value: "true",
  },
  {
    label: "No",
    value: "false",
  },
];

export const TYPE_OPTIONS = [
  {
    label: "Any",
    value: undefined,
  },
  {
    label: "Fixed Price",
    value: "fixed_price",
  },
  {
    label: "Variable Price",
    value: "variable_price",
  },
];
