/** @jsxRuntime classic */
/** @jsx jsx */
import {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  ReactNode,
  Fragment,
} from "react";
import { jsx } from "theme-ui";
import Icon from "@swvl/icon";

type MenuContextValue = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const MenuContext = createContext<MenuContextValue>(null);

function useMenuContext() {
  const context = useContext(MenuContext);

  if (!context) {
    throw new Error(
      "Menu compound components cannot be rendered outside the Menu component"
    );
  }
  return context;
}

const Menu = ({ children }: { children: ReactNode }): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <MenuContext.Provider value={{ isOpen, setIsOpen }}>
      <div sx={{ position: "relative" }}>{children}</div>
    </MenuContext.Provider>
  );
};

const Trigger = ({ disabled, children }): JSX.Element => {
  const { isOpen, setIsOpen } = useMenuContext();

  return (
    <div sx={{ display: "flex", alignItems: "center" }}>
      <button
        sx={{
          width: "100%",
          textAlign: "left",
          padding: 16,
          margin: 0,
          background: "inherit",
          border: "none",
          cursor: disabled ? "not-allowed" : "pointer",
          borderBottom: "thin",
          boxShadow: "none",
          "&:focus": {
            outline: "none",
          },
        }}
        onClick={() => setIsOpen(!isOpen)}
        disabled={disabled}
      >
        {children}
      </button>

      <span
        sx={{
          transition: "transform 0.2s",
          transform: isOpen ? "rotate(-180deg)" : null,
          opacity: disabled ? 0.3 : null,
        }}
      >
        <Icon name="down_arrow" />
      </span>
    </div>
  );
};

const MenuWrapper = ({ children }): JSX.Element => {
  const { isOpen, setIsOpen } = useMenuContext();

  return isOpen ? (
    <Fragment>
      {/* Layout div to close on click outside */}
      <div
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 1,
        }}
        onClick={() => setIsOpen(false)}
      />
      <ul
        sx={{
          position: "absolute",
          top: "100%",
          left: 0,
          p: 0,
          m: 0,
          maxHeight: 400,
          backgroundColor: "#fff",
          width: "max-content",
          listStyleType: "none",
          zIndex: "dropdown",
          boxShadow: "0px 16px 16px rgba(0, 0, 0, 0.08)",
        }}
      >
        {children}
      </ul>
    </Fragment>
  ) : null;
};

const Item = ({ children, onClick }): JSX.Element => {
  const { setIsOpen } = useMenuContext();

  return (
    <li role="option">
      <button
        sx={{
          width: "100%",
          textAlign: "left",
          padding: 16,
          margin: 0,
          background: "inherit",
          border: "none",
          cursor: "pointer",
          borderBottom: "thin",
          boxShadow: "none",
          "&:hover": {
            background: "ghostwhite",
          },
          "&:focus": {
            outline: "none",
          },
        }}
        onClick={() => {
          onClick();
          setIsOpen(false);
        }}
      >
        {children}
      </button>
    </li>
  );
};

Menu.Trigger = Trigger;
Menu.MenuWrapper = MenuWrapper;
Menu.Item = Item;

export default Menu;
