/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Flex, Text } from "theme-ui";
import { useState, FC } from "react";
import Icon from "@swvl/icon";
import theme from "@swvl/theme";
import LogoImage from "assets/swvl-logo.svg";
import MenuList from "../components/MenuList";
import { NAV_HEIGHT } from "../constants/styles";
import useLocalStorage from "utils/useLocalStorage";
import {
  AuthenticationModel,
  initialState,
  useLogout,
} from "resources/Auth/useLogout";

export const Header: FC = () => {
  const [accountMenuEl, setAccountMenuEl] = useState(false);
  const {
    storedValue: { token, refreshToken },
  } = useLocalStorage<AuthenticationModel>("ls.authdata", initialState);
  const { mutate: logoutUser } = useLogout();

  const handleLogout = () => {
    logoutUser({ token, refresh_token: refreshToken });
  };

  const options = [{ label: "Logout", key: "logout", onClick: handleLogout }];
  const renderAccountsMenu = () => {
    return accountMenuEl ? (
      <div
        sx={{
          width: "10rem",
          position: "absolute",
          willChange: "transform",
          top: "66px",
          right: 0,
          boxShadow: theme.shadows[1],
        }}
      >
        <MenuList
          options={options}
          getOptionKey={(option) => option.key as string}
          renderOption={(option) => <div>{option.label}</div>}
          handleSelect={(option) => {
            setAccountMenuEl(true);
            (option.onClick as () => void)();
          }}
        />
      </div>
    ) : null;
  };

  return (
    <div
      sx={{
        gridArea: "header",
        position: "fixed",
        width: "100%",
        top: 0,
        zIndex: 9999,
      }}
    >
      <Flex
        as="nav"
        sx={{
          width: "100%",
          height: NAV_HEIGHT,
          background: "whitesmoke",
          color: theme.colors.white,
          boxShadow: "0px 0px 8px rgba(124, 134, 156, 0.24)",
        }}
        data-test-id="main-header"
      >
        <Flex
          sx={{
            width: "100%",
            justifyContent: "space-between",
            pl: "10px",
            pr: "10px",
          }}
        >
          <Flex
            sx={{
              alignItems: "center",
            }}
          >
            <img
              alt="Swvl Logo"
              width={70}
              src={LogoImage}
              sx={{
                borderRadius: "4px",
              }}
            />
            <Text
              sx={{ color: "black", fontWeight: "bold", marginLeft: "10px" }}
            >
              Marketplace Dashboard
            </Text>
          </Flex>
          {
            <Flex
              id="right-section"
              sx={{
                alignItems: "center",
              }}
            >
              <Flex
                aria-label="account of current admin"
                aria-controls={"controls"}
                aria-haspopup="true"
                data-test-id="current-admin-button"
                sx={{
                  cursor: "pointer",
                  alignItems: "center",
                }}
                onClick={() => {
                  if (accountMenuEl) {
                    setAccountMenuEl(false);
                  } else {
                    setAccountMenuEl(true);
                  }
                }}
              >
                <Icon name="gender" fill="deepdarkslategray" />
                {accountMenuEl ? (
                  <div
                    sx={{
                      alignItems: "center",
                      width: "18px",
                      height: "18px",
                      transform: "rotate(180deg)",
                      transition: "0.3s all ease-in-out",
                    }}
                  >
                    <Icon
                      name="down_arrow"
                      fill="black"
                      sx={{
                        transform: "rotate(180deg)",
                        transition: "0.1s all ease-in-out",
                      }}
                    />
                  </div>
                ) : (
                  <div
                    sx={{
                      width: "18px",
                      height: "18px",
                      alignItems: "center",
                      transition: "0.3s all ease-in-out",
                    }}
                  >
                    <Icon name="down_arrow" fill="black" />
                  </div>
                )}
              </Flex>
              {renderAccountsMenu()}
            </Flex>
          }
        </Flex>
      </Flex>
    </div>
  );
};

export default Header;
