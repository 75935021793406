import { useMutation, useQueryClient } from "react-query";
import { showAlert } from "@swvl/alert";
import apiInstance from "api";

const unassignCaptain = async ({ planId, captain }) => {
  return await apiInstance.patch(`plans/${planId}/unassign_captain`, {
    captain,
  });
};

const useUnassignCaptain = (id) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(unassignCaptain, {
    mutationKey: "plans/unassign_captain",
    onSuccess: () => {
      queryClient.invalidateQueries(["plan/details", id]);
      showAlert({
        message: "You have unassigned captain from a plan successfully",
        type: "success",
        id: "alert-success",
      });
    },
  });
  return mutation;
};

export { useUnassignCaptain, unassignCaptain };
