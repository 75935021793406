/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, Text } from "theme-ui";
import Popup from "@swvl/popup";
import Icon from "@swvl/icon";
import Button from "@swvl/button";

import { useEndBidding } from "resources/PlanSelling/useEndBidding";

type Props = {
  isOpen: boolean;
  onDismiss: () => void;
  campaignId: string;
  state: string;
};

const EndCampaignPopup = ({
  isOpen,
  onDismiss,
  campaignId,
  state,
}: Props): JSX.Element => {
  const { mutate: endBidding } = useEndBidding(campaignId);

  return (
    <Popup isOpen={isOpen} closePopup={onDismiss} withClose>
      <div
        sx={{
          py: 3,
          px: 5,
        }}
      >
        <Icon name="warning" size={48} />
        <Text sx={{ fontSize: 3, mt: 3, fontWeight: "bold" }}>
          End Campaign Early
        </Text>
        <Text sx={{ fontSize: 2, mt: 2, color: "black-54" }}>
          Are you sure you want to end this campaign in the{" "}
          <span sx={{ fontWeight: "bold" }}>{state}</span> state?.
        </Text>
        <Text
          sx={{ fontSize: 2, mt: 2, color: "black-54", fontWeight: "bold" }}
        >
          This action will close bidding on this campaign
        </Text>

        <div
          sx={{
            mt: 4,
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button variant="gray" onClick={onDismiss}>
            Cancel
          </Button>

          <Button
            variant="primary"
            onClick={() => {
              endBidding(campaignId);
              onDismiss();
            }}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default EndCampaignPopup;
