import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import apiInstance from "api";
import { showAlert } from "@swvl/alert";
import { handleQuery } from "utils";

import { FormData, Bidding } from "./types";

// Resources
const createBidding = async (params) => {
  const response = await apiInstance.post<FormData, Bidding>(
    "bidding_campaigns",
    params
  );
  return response;
};

const useCreateBidding = () => {
  const history = useHistory();
  const mutation = useMutation(createBidding, {
    mutationKey: "bidding/create",
    onSuccess: (data) => {
      history.push(`/plan-selling/${data._id}`);
      handleQuery("remove", history, "plan");
      showAlert({
        message: "You have added a new bidding plan successfully",
        type: "success",
        id: "alert-success",
      });
    },
  });
  return mutation;
};

export { useCreateBidding };
