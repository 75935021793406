/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, Text } from "theme-ui";
import { useState } from "react";
import Popup from "@swvl/popup";
import Icon from "@swvl/icon";
import { Select } from "@swvl/select";
import Button from "@swvl/button";

import { useBidRejectionReasons } from "resources/PlanSelling/useBidRejectionReasons";
import { useAcceptBid } from "resources/PlanSelling/useAcceptBid";

type Props = {
  biddingPlanId: string;
  data: { id: string; captainName: string; price: string } | null;
  onDismiss: () => void;
};

const BidAcceptancePopupData = ({
  biddingPlanId,
  data,
  onDismiss,
}: Props): JSX.Element => {
  const [reason, setReason] = useState<{ label: string; value: string }>(null);
  const { id, captainName, price } = data || {};
  const { data: rejectionReasons } = useBidRejectionReasons();
  const { mutate: acceptBid } = useAcceptBid(biddingPlanId, captainName, price);

  return (
    <Popup isOpen={data !== null} closePopup={onDismiss} withClose>
      <div
        sx={{
          py: 3,
          px: 4,
        }}
      >
        <Icon name="completed" size={48} />
        <Text sx={{ fontSize: 3, mt: 3, fontWeight: "bold" }}>Accept Bid</Text>
        <Text sx={{ fontSize: 1, mt: 3, color: "black-54" }}>
          Are you sure you want to select captain{" "}
          <span sx={{ color: "black-54", fontWeight: "bold" }}>
            {captainName}
          </span>{" "}
          as the winner for this campaign at a price of{" "}
          <span sx={{ color: "black-54", fontWeight: "bold" }}>{price}</span>
        </Text>
        <Text sx={{ fontSize: 1, mt: 4, color: "black-54" }}>
          To complete this action, please select a{" "}
          <span sx={{ color: "black-54", fontWeight: "bold" }}>
            Rejection reason
          </span>{" "}
          for the remaining bids
        </Text>
        <Text
          sx={{ fontSize: 2, mt: 4, fontWeight: "bold", textAlign: "left" }}
        >
          Rejection Reason <span sx={{ color: "crimson" }}>*</span>
        </Text>
        <Select
          sx={{ mt: 2 }}
          label={null}
          name="Rejection Reason"
          value={reason}
          options={rejectionReasons}
          onChange={(value) => {
            setReason(value);
          }}
        />
        <div
          sx={{
            mt: 4,
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button variant="gray" onClick={onDismiss}>
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={reason === null}
            onClick={() => {
              onDismiss();
              acceptBid({ id, rejection_reason_key: reason?.value });
            }}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default BidAcceptancePopupData;
