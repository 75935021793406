import { useMutation } from "react-query";
import { showAlert } from "@swvl/alert";
import apiInstance from "api";
import { AxiosResponse } from "axios";
import { getErrorMessage } from "utils";

export type bulkUnassignRequestType = {
  planId: string;
  captain: string;
  newPlanStartDate?: string;
};

const bulkUnassign = async ({
  planId,
  captain,
  newPlanStartDate,
}: bulkUnassignRequestType) => {
  return await apiInstance.patch(
    `plans/${planId}/captain/${captain}/unassign_conflicting_rides`,
    {
      newPlanStartDate,
    }
  );
};

const useBulkUnassign = () => {
  const mutation = useMutation(bulkUnassign, {
    mutationKey: "plans/captain/unassign_conflicting_rides",
    onSuccess: () => {
      // queryClient.invalidateQueries(["plan/details", planId]);
    },
    onError: (error: AxiosResponse) => {
      let message = "";
      if (error?.data?.failedRideIds && error?.data?.failedRideIds?.length) {
        message = `These rides can't be unassigned ${error.data.failedRideIds.join(
          " "
        )}`;
      } else message = getErrorMessage(error);
      showAlert({
        message,
        type: "error",
        id: "bulk-unassign-alert-error",
      });
    },
  });
  return mutation;
};

export { useBulkUnassign, bulkUnassign };
