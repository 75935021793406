import { useQuery, UseQueryResult } from "react-query";
import { AxiosResponse } from "axios";
import isNil from "lodash/isNil";
import apiInstance, { checkIfRequestIntrceptorUsed } from "api";

export interface BiddingPlanBidsResponse {
  total: number;
  hits: Hit[];
}

export type Hit = {
  _id: string;
  captain: Captain;
  plan_selling_contract_version_md5: string;
  amount: number;
  currency: string;
  state: string;
  rejection_reason: string;
  cancellation_reason: string;
};

export interface Captain {
  _id: string;
  name: string;
}

const getBiddingPlanBids = async (id: string) => {
  const response = await apiInstance.get<
    AxiosResponse,
    BiddingPlanBidsResponse
  >(`bidding_campaigns/${id}/bids`);

  return response;
};

const useBiddingPlanBids = (
  id: string
): UseQueryResult<BiddingPlanBidsResponse, unknown> => {
  const query = useQuery(
    ["bidding_campaigns", id, "bids"],
    () => getBiddingPlanBids(id),
    {
      enabled: !isNil(id) && checkIfRequestIntrceptorUsed(),
    }
  );
  return query;
};

export { useBiddingPlanBids };
