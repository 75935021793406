/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, Box, Image, Text } from "theme-ui";
import Button from "@swvl/button";
import ErrorImage from "assets/something-wrong.svg";

const ErrorCallback = ({ resetError }) => {
  return (
    <Box
      css={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Image
        src={ErrorImage}
        alt="Something went wrong"
        sx={{ mt: 2, width: ["300px", "500px", "700px"] }}
      />
      <Text
        sx={{ my: 3, fontWeight: 500, fontSize: "23px", lineHeight: "27px" }}
      >
        Uh oh! Something went wrong. We’re working on fixing this.{" "}
      </Text>
      <Text>
        In the meantime, reach out to{" "}
        <a href="mailto: support@swvl.com">support@swvl.com</a> for any queries.
      </Text>
      <Button sx={{ mt: 3 }} onClick={resetError}>
        Go To Main Page
      </Button>
    </Box>
  );
};

export default ErrorCallback;
