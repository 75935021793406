import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import apiInstance from "api";
import { isNil } from "lodash";

const districtAutocomplete = "district/autocomplete";
export interface SearchDistrictsResponse {
  hits: {
    _id: string;
    _source: {
      name: string;
    };
  }[];
}

export type SearchDistrictsModel = {
  value: string;
  label: string;
};

type SearchDistrictsListModel = SearchDistrictsModel[];

const transformResponse = (
  response: SearchDistrictsResponse
): SearchDistrictsListModel => {
  return response.hits.map((hit) => ({
    value: hit._id,
    label: hit._source.name,
  }));
};

const searchDistricts = async (
  input: string
): Promise<SearchDistrictsListModel> => {
  const response = await apiInstance.get<
    AxiosResponse,
    SearchDistrictsResponse
  >(districtAutocomplete, {
    params: { query: input },
  });

  return transformResponse(response);
};

const useSearchDistricts = (input: string) => {
  return useQuery([districtAutocomplete, input], () => searchDistricts(input), {
    enabled: !isNil(input),
    initialData: [] as SearchDistrictsListModel,
    onError: undefined,
  });
};

export { useSearchDistricts, searchDistricts };
