/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text, Heading } from "theme-ui";
import { FC } from "react";
import Icon from "@swvl/icon";
import Popup from "@swvl/popup";
import { Button } from "@swvl/button";
import { useHistory } from "react-router-dom";

type BiddingCampaignPopupProps = {
  onDismiss?: () => void;
  isOpen: boolean;
  biddingCampaign: string;
};

const BiddingCampaignPopup: FC<BiddingCampaignPopupProps> = ({
  onDismiss,
  isOpen,
  biddingCampaign,
}) => {
  const history = useHistory();

  const Content = () => {
    return (
      <section sx={{ mt: 3, mx: 5 }} data-test-id="bidding_delete_popup">
        <div
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Icon name="warning" size={50} />
        </div>
        <section sx={{ display: "flex", flexDirection: "column" }}>
          <Heading
            sx={{ mb: 3, fontWeight: "bold" }}
            variant={"headline"}
            data-test-id="popup_title"
          >
            Delete Plan
          </Heading>
          <Text
            sx={{ mb: 4, color: "opacity.black-54", lineHeight: "1.8" }}
            data-test-id="popup_message"
          >
            Cannot delete this plan because an active bidding campaign exists,
            please click{" "}
            <button
              sx={{
                color: "deepskyblue",
                textDecoration: "underline",
                background: "transparent",
                cursor: "pointer",
                fontSize: "16px",
                border: 0,
                px: 0,
              }}
              onClick={() => {
                history.push(`/plan-selling/${biddingCampaign}/`);
              }}
              data-test-id="popup-navigate-button"
            >
              here
            </button>{" "}
            to open the bidding campaign
          </Text>
          <div sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
            <Button
              data-test-id="cancel-button"
              type="button"
              variant="gray"
              onClick={onDismiss}
            >
              Cancel
            </Button>
          </div>
        </section>
      </section>
    );
  };

  return (
    <Popup withClose closePopup={onDismiss} isOpen={isOpen} width={500}>
      <Content />
    </Popup>
  );
};

export default BiddingCampaignPopup;
