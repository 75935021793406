/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text, Heading } from "theme-ui";
import { FC, useEffect, useState, Fragment } from "react";
import Icon from "@swvl/icon";
import Popup from "@swvl/popup";
import { Button } from "@swvl/button";
import { useDeletePlan } from "resources/Plan/useDeletePlan";
import RelativeBiddingCampaignPopup from "./RelativeBiddingCampaignPopup";
import { AxiosResponse } from "axios";

type DeletePlanPopupProps = {
  onDismiss?: () => void;
  isOpen: boolean;
  planId: string;
};

const DeletePlanPopup: FC<DeletePlanPopupProps> = ({
  onDismiss,
  isOpen,
  planId,
}) => {
  const { mutate: deletePlan, error } = useDeletePlan();
  const [openBiddingCampaignPopup, setOpenBiddingCampaignPopup] =
    useState(false);
  useEffect(() => {
    if ((error as AxiosResponse)?.data.biddingCampaign)
      setOpenBiddingCampaignPopup(true);
  }, [error]);

  const Content = () => {
    return (
      <section sx={{ mt: 3, mx: 5 }} data-test-id="delete_popup">
        <div
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Icon name="delete" size={50} fill="#FFC659" />
        </div>
        <section sx={{ display: "flex", flexDirection: "column" }}>
          <Heading
            sx={{ mb: 3, fontWeight: "bold" }}
            variant={"headline"}
            data-test-id="popup_title"
          >
            Delete Plan
          </Heading>

          <Text
            sx={{ mb: 4, color: "opacity.black-54", lineHeight: "1.8" }}
            data-test-id="popup_message"
          >
            Are you sure you want to delete this plan? This will release any
            schedules assigned to this plan
          </Text>

          <div
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              type="button"
              variant="gray"
              onClick={onDismiss}
              data-test-id="cancel_button"
            >
              Cancel
            </Button>
            <Button
              type="button"
              variant="primary"
              onClick={() => {
                deletePlan(planId);
                onDismiss();
              }}
              data-test-id="confirm_button"
            >
              Confirm
            </Button>
          </div>
        </section>
      </section>
    );
  };

  return (
    <Fragment>
      <Popup withClose closePopup={onDismiss} isOpen={isOpen}>
        <Content />
      </Popup>
      {(error as AxiosResponse)?.data.biddingCampaign ? (
        <RelativeBiddingCampaignPopup
          isOpen={openBiddingCampaignPopup}
          onDismiss={() => setOpenBiddingCampaignPopup(false)}
          biddingCampaign={(error as AxiosResponse).data.biddingCampaign}
        />
      ) : null}
    </Fragment>
  );
};

export default DeletePlanPopup;
