import { useQuery, UseQueryResult } from "react-query";
import { AxiosResponse } from "axios";
import apiInstance from "api";

type CancellationReasonsResponse = { key: string; value: string }[];
type CancellationReasons = { label: string; value: string }[];

const getBidRejectionReasons = async () => {
  const response = await apiInstance.get<
    AxiosResponse,
    CancellationReasonsResponse
  >("bids/rejection_reasons");

  return response.map(({ key, value }) => ({ label: value, value: key }));
};

const useBidRejectionReasons = (): UseQueryResult<
  CancellationReasons,
  unknown
> => {
  const query = useQuery(
    ["bids", "rejection_reasons"],
    getBidRejectionReasons,
    {}
  );
  return query;
};

export { useBidRejectionReasons };
