import { useMutation, useQueryClient } from "react-query";
import apiInstance from "api";
import { showAlert } from "@swvl/alert";

const endBidding = async (id: string) => {
  const response = await apiInstance.patch(`bidding_campaigns/${id}/close`);
  return response;
};

const useEndBidding = (id) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(endBidding, {
    onSuccess: () => {
      queryClient.invalidateQueries(["bidding_campaigns", id]);
      queryClient.invalidateQueries(["bidding_campaigns", id, "bids"]);
      showAlert({
        message: "Bidding Campaign is cancelled successfully",
        type: "success",
        id: "alert-success",
      });
    },
  });
  return mutation;
};

export { useEndBidding };
