import { useMutation, useQueryClient } from "react-query";
import { showAlert } from "@swvl/alert";
import apiInstance from "api";

const assignCaptain = async ({ planId, captain }) => {
  return await apiInstance.patch(`plans/${planId}/assign_captain`, {
    captain,
  });
};

const useAssignCaptain = (planId) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(assignCaptain, {
    mutationKey: "plans/assign_captain",
    onSuccess: () => {
      queryClient.invalidateQueries(["plan/details", planId]);
      showAlert({
        message: "You have assigned captain to a plan successfully",
        type: "success",
        id: "alert-success",
      });
    },
  });
  return mutation;
};

export { useAssignCaptain, assignCaptain };
