/** @jsxRuntime classic */
/** @jsx jsx */

import { Fragment, useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { jsx, Heading, Divider, Text } from "theme-ui";
import { useFormik, FormikProvider } from "formik";
import { boolean, number, object, string, date } from "yup";
import Button from "@swvl/button";
import Toggle from "@swvl/toggle";
import Autocomplete from "@swvl/autocomplete";
import Spinner from "@swvl/spinner";
import Icon from "@swvl/icon";
import FormField from "components/FormField";
import add from "date-fns/fp/add";
import format from "date-fns/fp/format";
import first from "lodash/fp/first";

import {
  useSearchPlans,
  SearchPlansModel,
} from "resources/Plan/useSearchPlans";
import { useCreateBidding } from "resources/PlanSelling/useCreateBidding";

import DateTimePicker from "components/DateTimePicker";

import { AddBiddingPlanWrapper, Label } from "./styled";
import { FormData } from "./types";
import { CURRENCY_CONVERT, getFromQuery, handleQuery } from "utils";

const CreateBiddingCampaign = (): JSX.Element => {
  const [openForBid, setOpenForBid] = useState<boolean>(false);
  const { mutate: createBidding, isLoading } = useCreateBidding();

  // Plans Search
  const location = useLocation();
  const history = useHistory();
  const planId = getFromQuery("plan", location.search);

  const [searchPlan, setSearchPlan] = useState<string>(planId);
  const { data: planSuggestions, isFetching: isFetchingPlan } =
    useSearchPlans(searchPlan);
  const renderSearchPlanOptions = (option: SearchPlansModel) => {
    return (
      <div sx={{ cursor: "pointer" }}>
        <Text>{option.nameI18n?.en}</Text>
      </div>
    );
  };
  const [planInitialValue, setPlanInitialValue] = useState<string>();

  const formInitialValues: FormData = {
    Plan: planInitialValue,
    "Start Date Time": new Date(),
    "End Date Time": undefined,
    "Minimum Bus Year": undefined,
    "Maximum Bids To Receive": undefined,
    type: false,
    "Fixed Price": undefined,
    "Minimum Bid Price": undefined,
    "Maximum Bid Price": undefined,
  };

  const nextYear = new Date().getFullYear() + 1;
  const formSchema = object({
    Plan: string().required(),
    "Start Date Time": date().required(),
    "End Date Time": date()
      .required()
      .when("Start Date Time", (startDate, schema) => {
        const minimumDate = add({ minutes: 1 }, startDate);

        return schema.min(
          minimumDate,
          `End Date Time field must be later than ${format(
            "EEE, MMM do - hh:mm aaa",
            minimumDate
          )}`
        );
      }),
    "Minimum Bus Year": number()
      .min(2000, "Bus Year should be a minimum of the year 2000")
      .max(nextYear, `Minimum Bus Year shouldn't be past ${nextYear}`),
    "Maximum Bids To Receive": number()
      .min(1, "Maximum Bids To Receive should be larger than or equal to 1")
      .required(),
    type: boolean().required(),
    "Fixed Price": number()
      .min(0, "Fixed Price should be a positive number")
      .when("type", {
        is: false,
        then: number().required(),
      }),

    "Minimum Bid Price": number()
      .min(0, "Minimum Bid Price should be a positive number")
      .when("Maximum Bid Price", (maximumBidPrice, schema) =>
        maximumBidPrice !== undefined ? schema.max(maximumBidPrice - 1) : schema
      ),
    "Maximum Bid Price": number()
      .min(0, "Maximum Bid Price should be a positive number")
      .when("type", {
        is: true,
        then: number().required(),
      }),
  });

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: formSchema,
    onSubmit: ({
      Plan: plan,
      "Start Date Time": openDate,
      "End Date Time": closeDate,
      "Minimum Bus Year": minimumBusYear,
      "Maximum Bids To Receive": maximumBidsToReceive,
      type,
      "Fixed Price": fixedPrice,
      "Minimum Bid Price": minimumBidPrice,
      "Maximum Bid Price": maximumBidPrice,
    }: FormData) => {
      const commonValues = {
        type: type ? "variable_price" : "fixed_price",
        plan,
        open_date: openDate.toISOString(),
        close_date: closeDate.toISOString(),
        minimum_bus_year: minimumBusYear || undefined,
        maximum_bids_to_receive: maximumBidsToReceive,
      };

      const price = type
        ? {
            minimum_price: minimumBidPrice
              ? minimumBidPrice * CURRENCY_CONVERT
              : undefined,
            maximum_price: maximumBidPrice * CURRENCY_CONVERT,
          }
        : { fixed_price: fixedPrice * CURRENCY_CONVERT };
      createBidding({ ...commonValues, ...price });
    },
  });

  const { setFieldValue, setFieldTouched, touched, errors } = formik;
  useEffect(() => {
    if (planId) {
      setPlanInitialValue(first(planSuggestions)?.nameI18n?.en);
      setFieldValue("Plan", planId);
      setFieldTouched("Plan", true);
    }
  }, [planSuggestions]);
  return (
    <Fragment>
      <Heading data-test-id="create-bidding-header">
        Create A Bidding Campaign
      </Heading>
      <Divider color="gainsboro" sx={{ m: "20px -20px" }} />

      <FormikProvider value={formik}>
        <AddBiddingPlanWrapper onSubmit={formik.handleSubmit}>
          <div sx={{ mb: 20 }} data-test-id="plan">
            <Label htmlFor="plan">
              Plan
              <span
                sx={{
                  ml: 1,
                  color: "crimson",
                }}
              >
                *
              </span>
            </Label>
            <Autocomplete
              suggestions={planSuggestions}
              placeholder="Search using plan name or description"
              isLoading={isFetchingPlan}
              initialValue={planInitialValue}
              onChangeQuery={(input) => {
                setSearchPlan(input);
                setFieldTouched("Plan", true);
              }}
              getOptionLabel={(option) => option.nameI18n?.en}
              renderOption={(option) => renderSearchPlanOptions(option)}
              getOptionKey={(option) => option.id}
              onSelect={(option) => {
                const value = option.id;
                setFieldValue("Plan", value);
                handleQuery("remove", history, "plan");
              }}
              onClear={() => {
                setFieldValue("Plan", undefined);
                handleQuery("remove", history, "plan");
              }}
            />
            {errors.Plan && touched.Plan && (
              <div
                sx={{ marginTop: "6px", marginLeft: "5px", color: "crimson" }}
              >
                {errors.Plan}
              </div>
            )}
          </div>

          <div sx={{ mb: 20 }}>
            <DateTimePicker
              label="Bid Start Date Time"
              initialDate={formik.values["Start Date Time"]}
              minDate={new Date()}
              required={true}
              onChange={(date: Date) => {
                setFieldTouched("Start Date Time");
                setFieldValue("Start Date Time", date);
              }}
            />

            {errors["Start Date Time"] && touched["Start Date Time"] && (
              <div
                sx={{ marginTop: "6px", marginLeft: "5px", color: "crimson" }}
              >
                {errors["Start Date Time"]}
              </div>
            )}
          </div>

          <div sx={{ mb: 20 }}>
            <DateTimePicker
              label="Bid End Date Time"
              initialDate={add(
                { minutes: 1 },
                formik.values["Start Date Time"]
              )}
              minDate={formik.values["Start Date Time"]}
              required={true}
              onChange={(date: Date) => {
                setFieldTouched("End Date Time");
                setFieldValue("End Date Time", date);
              }}
            />

            {errors["End Date Time"] && touched["End Date Time"] && (
              <div
                sx={{ marginTop: "6px", marginLeft: "5px", color: "crimson" }}
              >
                {errors["End Date Time"]}
              </div>
            )}
          </div>

          <FormField
            label="Minimum Bus Year"
            name="Minimum Bus Year"
            placeholder="Enter Bus Year"
            type="number"
            min="2000"
            max={nextYear}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values["Minimum Bus Year"]}
          />

          <FormField
            label="Maximum Bids To Receive"
            name="Maximum Bids To Receive"
            placeholder="Enter Maximum Bids To Receive"
            type="number"
            min="1"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values["Maximum Bids To Receive"]}
            required
          />

          <div className="open-for-bid" sx={{ mb: 20 }}>
            <Toggle
              labelOn="Open For Bid"
              labelOff="Bid Closed"
              checked={openForBid}
              id="toggle-open-for-bid"
              onChange={(e) => {
                const checked = (e.target as HTMLInputElement).checked;
                setOpenForBid(checked);
                setFieldValue("type", checked);
                setFieldTouched("type", true);
              }}
              onOffLabelPosition={"start"}
            />
          </div>

          {openForBid ? (
            <div
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
              }}
            >
              <div sx={{ flex: 1 }}>
                <FormField
                  label="Minimum Bid Price"
                  name="Minimum Bid Price"
                  placeholder="Enter Minimum Bid Price"
                  type="number"
                  min="0"
                  max={formik.values["Maximum Bid Price"] - 1}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values["Minimum Bid Price"]}
                />
              </div>

              <span sx={{ margin: "35px 7px ", color: "#E3E4E5" }}> - </span>

              <div sx={{ flex: 1 }}>
                <FormField
                  label="Maximum Bid Price"
                  name="Maximum Bid Price"
                  placeholder="Enter Maximum Bid Price"
                  type="number"
                  min={formik.values["Minimum Bid Price"] + 1}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values["Maximum Bid Price"]}
                  required
                />
              </div>
            </div>
          ) : (
            <FormField
              label="Fixed Price"
              name="Fixed Price"
              placeholder="Enter Fixed Price"
              type="number"
              min="0"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values["Fixed Price"]}
              required
            />
          )}

          <div>
            {isLoading ? (
              <Spinner color="primary" />
            ) : (
              <div sx={{ display: "flex", flexDirection: "row-reverse" }}>
                <Button
                  data-test-id="Add-Bidding"
                  variant="primary"
                  icon={<Icon name="add" size={20} fill="currentColor" />}
                  type="submit"
                  disabled={!formik.isValid || !formik.dirty}
                >
                  Add Bidding
                </Button>
              </div>
            )}
          </div>
        </AddBiddingPlanWrapper>
      </FormikProvider>
    </Fragment>
  );
};

export default CreateBiddingCampaign;
