import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import apiInstance from "api";
import { isNil } from "lodash";

const cityAutocomplete = "city/autocomplete";
export interface SearchCitiesResponse {
  hits: {
    _id: string;
    _source: {
      name: string;
      currency: string;
    };
  }[];
}

export type SearchCitiesModel = {
  id: string;
  name: string;
  currency: string;
};

type SearchCitiesListModel = SearchCitiesModel[];

const transformResponse = (
  response: SearchCitiesResponse
): SearchCitiesListModel => {
  return response.hits.map((hit) => ({
    id: hit._id,
    ...hit._source,
  })) as SearchCitiesListModel;
};

const searchCities = async (input: string): Promise<SearchCitiesListModel> => {
  const response = await apiInstance.get<AxiosResponse, SearchCitiesResponse>(
    cityAutocomplete,
    {
      params: { query: input },
    }
  );

  return transformResponse(response);
};

const useSearchCities = (input: string) => {
  return useQuery([cityAutocomplete, input], () => searchCities(input), {
    enabled: !isNil(input),
    initialData: [] as SearchCitiesListModel,
    onError: undefined,
  });
};

export { useSearchCities, searchCities, transformResponse };
