/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import styled from "@emotion/styled";

export const Info = styled.div`
  display: flex;
  align-items: center;
  width: 33%;
  flex-wrap: wrap;
  padding-right: 5px;
  label {
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    white-space: break-spaces;
  }
  &.weekdays-info {
    div label {
      font-size: 13px;
    }
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
`;

export const Label = styled.label`
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  margin-bottom: 8px;
  margin-left: 8px;
  display: block;
`;

export const ToggleWrapper = styled.div`
  span {
    font-weight: bold;
  }
`;

export const TableToggleWrapper = styled.div`
  span {
    cursor: auto;
  }
`;

export const AutocompleteWrapper = styled.div`
  margin-bottom: 20px;
  input {
    height: 42px;
    line-height: 42px;
    background-color: #f7f8f9;
  }
`;

export const CreatableItemsWrapper = styled.div`
  span + div {
    background-color: whitesmoke;
    border-color: transparent;
    border-radius: 8px;
    box-shadow: none;
    &:hover {
      border-color: transparent;
    }
  }
`;

export const TabsWrapper = styled.div`
  ul.react-tabs__tab-list {
    border: 0;
    margin-bottom: 3px;

    li {
      background-color: whitesmoke;
      padding: 3px 15px;
      font-size: 13px;
      font-weight: bold;

      &:focus {
        box-shadow: none;
        border-color: slategray;
      }
    }
  }
`;

export const SearchWrapper = styled.div`
  input {
    height: 38px;
    line-height: 38px;
    background-color: #f7f8f9;
    font-size: 15px;
  }
`;

export const RTLWrapper = styled.div`
  direction: rtl;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ClearableDateWrapper = styled.div`
  .react-datepicker__close-icon {
    height: auto;
    top: 50%;
  }
  &.without-label {
    .react-datepicker__close-icon {
      transform: translateY(-50%);
    }
  }
  .react-datepicker__close-icon::after {
    background-color: #ef4349;
  }
`;

export const PlanConflictPopupHeader = styled.div`
  text-align: center;
  margin-bottom: 50px;

  h2::first-letter {
    text-transform: uppercase;
  }
`;

export const PlanConflictPopupConflictEntityGroup = styled.div`
  margin-bottom: 41px;
  text-align: left;

  div {
    text-transform: capitalize;
  }
`;

export const PlanConflictPopupConflictEntity = styled.div`
  border: 1px solid #d9dbe9;
  border-radius: 4px;
  padding: 12px 15px;
  margin-bottom: 12px;
`;

export const PlanConflictPopupInfo = styled.div`
  label {
    color: #787878;
    display: block;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 4px;
  }
  &:last-of-type label {
    text-align: right;
  }
  div {
    font-size: 14px;
    line-height: 16px;
  }
`;
