import { AxiosResponse } from "axios";
import apiInstance from "api";

export type SearchBusTypeModel = {
  label: string;
  value: string;
};

type SearchBusTypeListModel = SearchBusTypeModel[];

const transformResponse = ({
  data,
}: AxiosResponse<string[]>): SearchBusTypeListModel => {
  return data.map((name) => ({
    label: name,
    value: name,
  }));
};

const searchBusType = async (
  query: string,
  city: string
): Promise<SearchBusTypeListModel> => {
  const response = await apiInstance.get<string[]>(
    "bidding_campaigns/bus_type/autocomplete",
    {
      params: { query, city },
    }
  );

  return transformResponse(response);
};

export { searchBusType };
