export type SelectOption = {
  label: string;
  value: string;
};

export type PlanPrice = {
  amount: number;
  currency: string;
};

export const PLAN_TYPES: SelectOption[] = [
  { label: "Schedule", value: "schedule" },
];

export const ACTIVE_FILTER_OPTION = [
  { label: "All Plans", value: null },
  {
    label: "Active Plans",
    value: "true",
  },
  {
    label: "Inactive Plans",
    value: "false",
  },
];

export const CAPTAIN_FILTER_OPTION = [
  { label: "All Plans", value: null },
  {
    label: "Assigned to captain ",
    value: "true",
  },
  {
    label: "Unassigned to captain ",
    value: "false",
  },
];

export const BIDDING_FILTER_OPTION = [
  { label: "Show all plans", value: null },
  {
    label: "Show plans with campaign",
    value: "true",
  },
  {
    label: "Show plans without campaign",
    value: "false",
  },
];

export const WEEKDAY_FILTER_OPTION = [
  { label: "Monday", value: "1" },
  { label: "Tuesday", value: "2" },
  { label: "Wednesday", value: "3" },
  { label: "Thursday", value: "4" },
  { label: "Friday", value: "5" },
  { label: "Saturday", value: "6" },
  { label: "Sunday", value: "7" },
];

export const STATE_FILTER_OPTIONS = [
  { label: "Created", value: "created" },
  { label: "Assigned", value: "assigned" },
  { label: "Unassigned", value: "unassigned" },
  { label: "Expired", value: "expired" },
];

export type ListPlansParams = {
  page: number;
  city: string;
  captain: string;
  active: string;
  assigned: string;
  minRideKMs: string;
  maxRideKMs: string;
  isOnBidding: string;
  busTypes: string[];
  firstDistricts: string[];
  lastDistricts: string[];
  weekdays: string[];
  state: string[];
  labels: string[];
};
