import { jsx, Heading, Divider } from "theme-ui";

import React from "react";

const PageHeader = (props) => {
  return (
    <React.Fragment>
      <Heading>{props.title}</Heading>
      <Divider color="gainsboro" sx={{ m: "20px -20px" }} />
    </React.Fragment>
  );
};

export default PageHeader;
