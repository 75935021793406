import { FC, useRef } from "react";

import DatePicker from "@swvl/date-picker";

type PlanStartDatePickerProps = {
  selected: Date;
  onChange: (date: Date) => void;
  isClearable?: boolean;
  label?: string;
};

const PlanStartDatePicker: FC<PlanStartDatePickerProps> = ({
  selected,
  onChange,
  isClearable = false,
  label = "",
}) => {
  const startDateRef = useRef(null);
  const today = new Date();
  const minDate = new Date(today.setDate(today.getDate() + 1));
  const maxDate = new Date(today.setDate(today.getDate() + 30));

  const onPlanStartDateChange = (dates: [Date, Date]) => {
    const selected = dates && dates[0];
    onChange(selected);
  };

  return (
    <DatePicker
      ref={startDateRef}
      placeholder={!selected && "Plan start date"}
      label={label}
      minDate={minDate}
      maxDate={maxDate}
      startDate={selected}
      endDate={selected}
      selected={selected}
      onChange={onPlanStartDateChange}
      isClearable={isClearable}
    ></DatePicker>
  );
};

export default PlanStartDatePicker;
