import { useMutation, useQueryClient } from "react-query";
import { showAlert } from "@swvl/alert";
import apiInstance from "api";

const deactivatePlan = async (id: string) => {
  return await apiInstance.patch(`plans/${id}/deactivate`);
};

const useDeactivatePlan = (id) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(deactivatePlan, {
    mutationKey: "plans/deactivate",
    onSuccess: () => {
      queryClient.invalidateQueries(["plan/details", id]);
      showAlert({
        message: "You have deactivated a plan successfully",
        type: "success",
        id: "alert-success",
      });
    },
  });
  return mutation;
};

const activatePlan = async (id: string) => {
  return await apiInstance.patch(`plans/${id}/activate`);
};

const useActivatePlan = (id) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(activatePlan, {
    mutationKey: "plans/activate",
    onSuccess: () => {
      queryClient.invalidateQueries(["plan/details", id]);
      showAlert({
        message: "You have activated a plan successfully",
        type: "success",
        id: "alert-success",
      });
    },
  });
  return mutation;
};

export { useDeactivatePlan, useActivatePlan };
