import { useMutation, useQueryClient } from "react-query";
import apiInstance from "api";
import { showAlert } from "@swvl/alert";

type BiddingPlanParams = {
  id: string;
  params: {
    maximum_bids_to_receive: number;
  };
};
const updateBiddingMaxNumberOfBids = async ({
  id,
  params,
}: BiddingPlanParams) => {
  const response = await apiInstance.patch(
    `bidding_campaigns/${id}/maximum_bids_to_receive`,
    params
  );
  return response;
};

const useUpdateBiddingMaxNumberOfBids = (id) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(updateBiddingMaxNumberOfBids, {
    onSuccess: () => {
      queryClient.invalidateQueries(["bidding_campaigns", id]);
      showAlert({
        message: "Max Number of Bids is updated successfully",
        type: "success",
        id: "alert-success",
      });
    },
  });
  return mutation;
};

export { useUpdateBiddingMaxNumberOfBids };
