/** @jsxRuntime classic */
/** @jsx jsx */

import speakerIcon from "assets/speaker.svg";
import { useState, useEffect, Fragment } from "react";
import { useParams, useHistory } from "react-router-dom";

import { jsx, Box, Heading, Flex } from "theme-ui";
import { capitalize, first, isEmpty, omit } from "lodash";
import { format } from "date-fns";
import Tag from "@swvl/tag";
import Icon from "@swvl/icon";
import Input from "@swvl/input";
import Table from "@swvl/table";
import Toggle from "@swvl/toggle";
import Spinner from "@swvl/spinner";
import { IconButton, Button } from "@swvl/button";
import { WeekdaysCheckbox } from "@swvl/weekdays";
import { showAlert } from "@swvl/alert";
import { Column } from "react-table";
import PageHeader from "components/PageHeader";
import { usePlanDetails, Schedules } from "resources/Plan/usePlanDetails";
import { usePlanPrice } from "resources/Plan/useUpdatePlanPrice";
import { usePlanDescription } from "resources/Plan/useUpdatePlanDescription";
import { useCityDetails } from "resources/City/useCityDetails";
import { usePlanStartDate } from "resources/Plan/useUpdatePlanStartDate";
import {
  useBulkUnassign,
  bulkUnassignRequestType,
} from "resources/Plan/useBulkUnassign";
import { Info, InfoWrapper, RTLWrapper } from "./styled";
import PlanStatusPopup from "./PlanStatusPopup";
import PlanStartDatePicker from "./PlanStartDatePicker";
import {
  CURRENCY_CONVERT,
  WEEKDAYS,
  PAGE_SIZE,
  TIME_FORMAT,
  DATE_DASH_FORMAT,
  getAdminDashboardLink,
} from "utils";
import DeletePlanPopup from "./DeletePlanPopup";
import UnassignCaptainPopup from "./UnassignCaptainPopup";
import AssignCaptainPopup from "./AssignCaptainPopup";
import UpdatePlanLabelsPopup from "./UpdatePlanLabelsPopup";
import UpdatePlanBustypePopup from "./UpdatePlanBustypePopup";
import PlanConflictPopup from "./PlanConflictPopup";
import { AxiosResponse } from "axios";

export const PlanDetails = () => {
  type methodCalledAfterFixConflictType = {
    method: (...args) => void;
    params;
  };

  const history = useHistory();

  const { id: planId } = useParams<{ id: string }>();
  const { data: planDetails, isLoading, isError } = usePlanDetails(planId);

  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openUnassignCaptainPopup, setOpenUnassignCaptainPopup] =
    useState(false);
  const [openAssignCaptainPopup, setOpenAssignCaptainPopup] = useState(false);
  const [openPlanStatusPopup, setOpenPlanStatusPopup] = useState(false);
  const [openUpdatePlanLabelsPopup, setOpenUpdatePlanLabelsPopup] =
    useState(false);
  const [openUpdatePlanBustypePopup, setOpenUpdatePlanBustypePopup] =
    useState(false);
  const [openPlanPopupConflict, setOpenPlanPopupConflict] = useState(false);
  const [conflictData, setConflictData] = useState(null);
  const [methodCalledAfterFixConflict, setMethodCalledAfterFixConflict] =
    useState<methodCalledAfterFixConflictType>(null);
  const [captainId, setCaptainId] = useState("");

  const { mutate: updatePlanPrice } = usePlanPrice();
  const { mutate: updatePlanDescription } = usePlanDescription();
  const {
    mutate: updatePlanStartDate,
    error: updatePlanStartDateError,
    isSuccess: updatePlanStartDateSuccess,
  } = usePlanStartDate();
  const { mutate: bulkUnassign, isSuccess: isBulkUnAssignSuccess } =
    useBulkUnassign();
  const [sendStartDateToBulkUnassign, setSendStartDateToBulkUnassign] =
    useState(false);

  const { data: cityDetails } = useCityDetails(
    first(planDetails?.schedules)?.city?.id
  );
  const cityLanguages = cityDetails?.languages ? cityDetails.languages : null;
  const cityLocalLanguage = first(
    cityLanguages?.filter((lang) => lang !== "en")
  );

  const timezone = cityDetails?.timezone ? cityDetails.timezone : null;
  const [planPrice, setPlanPrice] = useState<number>();
  const [priceEditMode, setPriceEditMode] = useState(false);

  const [planDescription, setPlanDescription] = useState<{
    [key: string]: string;
  }>();
  const [descriptionEditMode, setDescriptionEditMode] = useState(false);

  const [page, setPage] = useState(1);
  const schedulesColumns: Column<Schedules>[] = [
    {
      Header: "Start Time",
      accessor: "startTime",
      Cell: ({ value }) => format(new Date(value - timezone), TIME_FORMAT),
    },
    {
      Header: "Predicted End Time",
      accessor: "predictedEndTime",
      Cell: ({ value }) => format(new Date(value - timezone), TIME_FORMAT),
    },
    {
      Header: "City",
      accessor: "city",
      Cell: ({ value }) => {
        return value.name;
      },
    },
    {
      Header: "Category",
      accessor: "category",
      Cell: ({ value }) => {
        return `${capitalize(value.rideType)} ${capitalize(value.serviceType)}`;
      },
    },
    {
      Header: "Ride KMs",
      accessor: "rideKMs",
    },
    {
      Header: "Route",
      accessor: "route",
      id: "routeName",
      Cell: ({ value: { id, nameI18n } }): JSX.Element => {
        return (
          <a
            title={nameI18n.en}
            href={`${getAdminDashboardLink()}/route/${id}`}
            target="_blank"
            rel="noreferrer"
          >
            {nameI18n.en}
          </a>
        );
      },
    },
    {
      Header: "Local Route Name",
      accessor: "route",
      id: "localRouteName",
      Cell: ({ value: { id, nameI18n } }) => {
        const localLanguages = omit(nameI18n, "en");
        const localLanguagesValue = Object.values(localLanguages).join("");
        return (
          <a
            title={localLanguagesValue}
            href={`${getAdminDashboardLink()}/route/${id}`}
            target="_blank"
            rel="noreferrer"
          >
            <RTLWrapper>{localLanguagesValue}</RTLWrapper>
          </a>
        );
      },
    },
  ];

  // Start Date
  const [startDateEditMode, setStartDateEditMode] = useState(false);
  const [planStartDate, setPlanStartDate] = useState<Date>(new Date());
  const [oldPlanStartDate, setOldPlanStartDate] = useState<Date>(null);
  function onPlanStartDateChange(selectedDate: Date) {
    setOldPlanStartDate(planStartDate);
    setPlanStartDate(selectedDate);
  }

  useEffect(() => {
    setPlanPrice(planDetails?.price?.amount / CURRENCY_CONVERT);
    setPlanDescription(planDetails?.descriptionI18n);
    const startDate = planDetails?.startDate && new Date(planDetails.startDate);
    setPlanStartDate(startDate);
    setOldPlanStartDate(startDate);
    setCaptainId(planDetails?.captain?.id);
  }, [planDetails]);

  useEffect(() => {
    if (updatePlanStartDateError) {
      setPlanStartDate(oldPlanStartDate);
    }
    if (
      (updatePlanStartDateError as AxiosResponse)?.data?.captainConflict &&
      Object.keys(
        (updatePlanStartDateError as AxiosResponse)?.data?.captainConflict
      ).length
    ) {
      setConflictData(
        (updatePlanStartDateError as AxiosResponse).data.captainConflict
      );
      setSendStartDateToBulkUnassign(true);
      setOpenPlanPopupConflict(true);
      setMethodCalledAfterFixConflict({
        method: updatePlanStartDate,
        params: {
          id: planId,
          startDate: planStartDate.toISOString(),
        },
      });
    }
  }, [updatePlanStartDateError]);

  useEffect(() => {
    setOldPlanStartDate(planStartDate);
  }, [updatePlanStartDateSuccess]);

  const submitPlanPrice = (newPrice) => {
    updatePlanPrice({
      id: planDetails?.id,
      price: {
        amount: newPrice * CURRENCY_CONVERT,
        currency: planDetails?.price?.currency,
      },
    });
  };
  const submitPlanStartDate = (newStartDate) => {
    if (
      (!oldPlanStartDate && !newStartDate) ||
      oldPlanStartDate?.getTime() === newStartDate?.getTime()
    ) {
      showAlert({
        message: "Plan start date is unchanged",
        type: "error",
        id: "alert-error",
      });
      return;
    }

    updatePlanStartDate({
      id: planDetails?.id,
      startDate: format(newStartDate, DATE_DASH_FORMAT),
    });
  };
  const checkDescription = (newDescription) => {
    for (const lang in newDescription) {
      if (
        isEmpty(newDescription[lang]) &&
        !Object.values(newDescription).every((desc) => isEmpty(desc))
      )
        return true;
    }
    return false;
  };

  const submitPlanDescription = (newDescription) => {
    if (Object.values(newDescription).every((desc) => isEmpty(desc)))
      newDescription = null;
    updatePlanDescription({
      id: planDetails?.id,
      descriptionI18n: newDescription,
    });
  };

  const onUnassignRides = () => {
    const newPlanStartDate = new Date(
      planStartDate.setHours(0, 0, 0, 0)
    ).toISOString();

    const bulkUnassignRequest: bulkUnassignRequestType = {
      planId,
      captain: captainId,
    };
    if (sendStartDateToBulkUnassign)
      bulkUnassignRequest.newPlanStartDate = newPlanStartDate;

    bulkUnassign(bulkUnassignRequest);
  };

  useEffect(() => {
    if (isBulkUnAssignSuccess) {
      setSendStartDateToBulkUnassign(false);
      const { method, params } = methodCalledAfterFixConflict;
      method(params);
      setOpenPlanPopupConflict(false);
    }
  }, [isBulkUnAssignSuccess]);
  return (
    <Fragment>
      <PageHeader title="Plan Details" />
      {isError ? (
        <Heading
          sx={{
            my: "30px",
            mx: "auto",
            display: "flex",
            color: "primary",
            justifyContent: "center",
          }}
        >
          There&apos;s something wrong!
        </Heading>
      ) : null}
      {isLoading ? (
        <Spinner color="primary" sx={{ m: "auto", display: "flex" }} />
      ) : (
        !isError &&
        planDetails &&
        cityDetails?.languages && (
          <div>
            {/* Plan Main Info */}
            <section data-test-id="plan_details">
              <div sx={{ display: "flex", justifyContent: "space-between" }}>
                {/* Status */}
                <Info sx={{ mx: "20px" }} data-test-id="status">
                  <Icon
                    name="assignment_status"
                    size={20}
                    fill="currentcolor"
                  />
                  <label>Status: </label>
                  <Toggle
                    id="plan-active"
                    labelOff="Inactive"
                    labelOn="Active"
                    aria-label="active-toggle"
                    checked={planDetails?.active}
                    onChange={() => setOpenPlanStatusPopup(true)}
                  />
                  <PlanStatusPopup
                    isOpen={openPlanStatusPopup}
                    onDismiss={() => setOpenPlanStatusPopup(false)}
                    planId={planId}
                    active={planDetails?.active}
                    captain={planDetails?.captain}
                    onCaptainConflict={(
                      conflictData,
                      methodCalledAfterFixConflict
                    ) => {
                      setOpenPlanPopupConflict(true);
                      setConflictData(conflictData);
                      setMethodCalledAfterFixConflict(
                        methodCalledAfterFixConflict
                      );
                    }}
                  />
                </Info>
                {/* Plan Actions */}
                <Flex>
                  <Button
                    variant="secondary"
                    sx={{ mx: 2 }}
                    icon={
                      <Icon name="gender" size={18} fill={"currentcolor"} />
                    }
                    onClick={() => {
                      setOpenAssignCaptainPopup(true);
                    }}
                    data-test-id="assign_captain_button"
                  >
                    Assign Captain
                  </Button>
                  <AssignCaptainPopup
                    isOpen={openAssignCaptainPopup}
                    onDismiss={() => setOpenAssignCaptainPopup(false)}
                    planId={planId}
                    setSelectedCaptain={setCaptainId}
                    onCaptainConflict={(
                      conflictData,
                      methodCalledAfterFixConflict
                    ) => {
                      setOpenPlanPopupConflict(true);
                      setConflictData(conflictData);
                      setMethodCalledAfterFixConflict(
                        methodCalledAfterFixConflict
                      );
                    }}
                  />
                  <Button
                    variant="secondary"
                    icon={
                      <Icon name="delete" size={18} fill={"currentcolor"} />
                    }
                    onClick={() => {
                      setOpenDeletePopup(true);
                    }}
                    data-test-id="delete_button"
                  >
                    Delete
                  </Button>
                  <DeletePlanPopup
                    isOpen={openDeletePopup}
                    onDismiss={() => setOpenDeletePopup(false)}
                    planId={planId}
                  />
                </Flex>
              </div>
              <Box
                sx={{
                  backgroundColor: "#FEF6F6",
                  border: "1px solid whitesmoke",
                  borderRadius: "4px",
                  m: "20px",
                  p: "20px",
                }}
              >
                <InfoWrapper sx={{ m: "0" }}>
                  <Info data-test-id="bidding-campaign">
                    <img sx={{ mr: "16px" }} src={speakerIcon} />
                    <label>Bidding campaign: </label>
                    {planDetails.biddingCampaign ? (
                      <button
                        sx={{
                          textDecoration: "underline",
                          background: "transparent",
                          cursor: "pointer",
                          fontSize: "16px",
                          border: 0,
                          px: 0,
                        }}
                        onClick={() => {
                          history.push(
                            `/plan-selling/${planDetails.biddingCampaign.id}/`
                          );
                        }}
                      >
                        Yes
                      </button>
                    ) : (
                      <span>No</span>
                    )}{" "}
                  </Info>
                  {planDetails.biddingCampaign ? (
                    <Fragment>
                      <Info data-test-id="number-of-bids">
                        <label>No. of bids received: </label>
                        <span>{planDetails.biddingCampaign.numberOfBids}</span>
                      </Info>
                      {planDetails.biddingCampaign.lowestReceivedBid ? (
                        <Info data-test-id="lowest-bid">
                          <label>Lowest bid: </label>
                          <span>
                            {planDetails.biddingCampaign.lowestReceivedBid}
                          </span>
                        </Info>
                      ) : null}
                    </Fragment>
                  ) : (
                    <Button
                      variant="primary"
                      disabled={Boolean(planDetails.captain?.id)}
                      sx={{ mx: 2 }}
                      icon={<Icon name="add" size={18} fill={"currentcolor"} />}
                      onClick={() => {
                        history.push(`/plan-selling/create?plan=${planId}`);
                      }}
                      data-test-id="create-bidding-btn"
                    >
                      Create new bidding campaign
                    </Button>
                  )}
                </InfoWrapper>
              </Box>
              <Box
                sx={{
                  border: "1px solid whitesmoke",
                  borderRadius: "4px",
                  m: "20px",
                  p: "20px",
                }}
              >
                {/* Name */}
                <InfoWrapper>
                  {/* Languages */}
                  {Object.keys(planDetails?.nameI18n).map(
                    (lang: string, index) => (
                      <Info key={index}>
                        <label>
                          Name <span className="text-uppercase">{lang}: </span>
                        </label>
                        <span>{planDetails?.nameI18n[lang]}</span>
                      </Info>
                    )
                  )}
                </InfoWrapper>
                {/* Description */}

                <InfoWrapper sx={{ maxHeight: "28px" }}>
                  {/* Languages */}
                  {Object.keys(planDetails?.nameI18n).map(
                    (lang: string, index) => (
                      <Info key={index}>
                        <label htmlFor={"description" + lang}>
                          Description{" "}
                          <span className="text-uppercase">{lang}: </span>
                        </label>
                        {!descriptionEditMode ? (
                          <span>{planDescription?.[lang]}</span>
                        ) : (
                          <Input
                            placeholder={
                              "Add New Plan Description " + lang.toUpperCase()
                            }
                            data-test-id={
                              "description-input-field-local-" + lang
                            }
                            type="text"
                            variant="plain"
                            height="small"
                            name={"description" + lang}
                            value={planDescription?.[lang]}
                            onChange={(e) => {
                              setPlanDescription({
                                ...planDescription,
                                [lang]: e.target.value,
                              });
                            }}
                          />
                        )}
                      </Info>
                    )
                  )}
                  {!descriptionEditMode ? (
                    // View Mode
                    <IconButton
                      data-test-id="edit_description_button"
                      variant="default"
                      sx={{ p: "6px", ml: "20px" }}
                      onClick={() => setDescriptionEditMode(true)}
                      icon={<Icon name="edit" size={20} fill={"gray"} />}
                    />
                  ) : (
                    // Edit Mode
                    <IconButton
                      variant="secondary"
                      data-test-id="save_description_button"
                      sx={{ p: "6px", ml: "20px" }}
                      onClick={() => {
                        setDescriptionEditMode(false);
                        submitPlanDescription(planDescription);
                      }}
                      disabled={checkDescription(planDescription)}
                      icon={
                        <Icon name="checked" size={20} fill={"currentcolor"} />
                      }
                    />
                  )}
                </InfoWrapper>

                <InfoWrapper>
                  {/* Type */}
                  <Info>
                    <label>Type: </label>
                    <span>{planDetails?.type}</span>
                  </Info>
                  {/* State */}
                  <Info data-test-id="state">
                    <label>State: </label>
                    <span>{planDetails?.state}</span>
                  </Info>
                  {/* Locked */}
                  <Info data-test-id="locked">
                    <label>Locked: </label>
                    <span>{planDetails?.locked ? " Yes" : " No"} </span>
                  </Info>
                </InfoWrapper>
                <InfoWrapper>
                  {/* busType */}
                  <Info>
                    <label>Bus Type: </label>
                    <span>{planDetails?.busType?.name}</span>
                    <IconButton
                      variant="default"
                      sx={{ p: "6px", ml: "20px" }}
                      onClick={() => setOpenUpdatePlanBustypePopup(true)}
                      icon={<Icon name="edit" size={20} fill={"gray"} />}
                      data-test-id="edit_bustype_button"
                    />
                    <UpdatePlanBustypePopup
                      isOpen={openUpdatePlanBustypePopup}
                      onDismiss={() => setOpenUpdatePlanBustypePopup(false)}
                      planId={planId}
                    />
                  </Info>
                  {/* Ride KMs */}
                  <Info data-test-id="plan_ridekm">
                    <label>Ride KMs: </label>
                    <span>{planDetails?.rideKMs} KM</span>
                  </Info>
                  {/* Dead KMs */}
                  <Info data-test-id="plan_deadkm">
                    <label>Dead KMs: </label>
                    <span>{planDetails?.deadKMs} KM</span>
                  </Info>
                </InfoWrapper>
                <InfoWrapper>
                  {/* Price */}
                  <Info>
                    <label htmlFor="price">Price: </label>
                    {priceEditMode ? (
                      // Edit Mode
                      <div
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          maxHeight: "28px",
                        }}
                      >
                        <Input
                          data-test-id="price_input"
                          placeholder="Add New Plan Price"
                          type="number"
                          variant="plain"
                          height="small"
                          name="price"
                          value={planPrice}
                          onChange={(e) => {
                            setPlanPrice(
                              Number((e.target as HTMLInputElement).value)
                            );
                          }}
                          endEnhancer={cityDetails?.currency}
                          min="0"
                          required
                        />

                        <IconButton
                          data-test-id="save_price_button"
                          variant="secondary"
                          sx={{ p: "6px", ml: "20px" }}
                          onClick={() => {
                            setPriceEditMode(false);
                            submitPlanPrice(planPrice);
                          }}
                          disabled={planPrice < 0 || !planPrice}
                          icon={
                            <Icon
                              name="checked"
                              size={20}
                              fill={"currentcolor"}
                            />
                          }
                        />
                      </div>
                    ) : (
                      // View Mode
                      <div sx={{ display: "flex", alignItems: "center" }}>
                        <span>
                          {planPrice} {planDetails?.price.currency}
                        </span>
                        {planDetails?.state !== "expired" &&
                        !planDetails?.locked ? (
                          <IconButton
                            data-test-id="edit_price_button"
                            variant="default"
                            sx={{ px: "6px", py: "3px", ml: "20px" }}
                            onClick={() => setPriceEditMode(true)}
                            icon={<Icon name="edit" size={20} fill={"gray"} />}
                          />
                        ) : null}
                      </div>
                    )}
                  </Info>
                  {/* Weekdays */}
                  <Info className="weekdays-info">
                    <label>Weekdays: </label>
                    <WeekdaysCheckbox
                      days={WEEKDAYS}
                      selectedDays={planDetails?.weekdays}
                    />
                  </Info>
                  {/* Labels */}
                  <Info data-test-id="labels">
                    <label>Labels: </label>
                    <div sx={{ display: "flex", flexWrap: "wrap" }}>
                      {planDetails?.labels?.map((label: string, index) => (
                        <Tag
                          key={index}
                          variant="mediumslateblue"
                          sx={{ mx: "5px", mb: "5px" }}
                        >
                          <span>{label}</span>
                        </Tag>
                      ))}
                    </div>
                    <IconButton
                      variant="default"
                      sx={{ p: "6px", ml: "20px" }}
                      onClick={() => setOpenUpdatePlanLabelsPopup(true)}
                      icon={<Icon name="edit" size={20} fill={"gray"} />}
                      data-test-id="edit_label_button"
                    />
                    <UpdatePlanLabelsPopup
                      isOpen={openUpdatePlanLabelsPopup}
                      onDismiss={() => setOpenUpdatePlanLabelsPopup(false)}
                      planId={planId}
                      labels={planDetails?.labels.map((label) => {
                        return { label, value: label };
                      })}
                    />
                  </Info>
                </InfoWrapper>
                <InfoWrapper>
                  {/* Start Location */}
                  <Info data-test-id="start_location">
                    <label>Start Location: </label>
                    <span>{planDetails?.firstDistrict.nameI18n?.en}</span>
                  </Info>
                  {/* End Location */}
                  <Info data-test-id="end_location">
                    <label>End Location: </label>
                    <span>{planDetails?.lastDistrict.nameI18n?.en}</span>
                  </Info>

                  {/* Captain */}
                  {planDetails?.captain ? (
                    <Info data-test-id="captain">
                      <label>Captain: </label>
                      <span data-test-id="captain-name">
                        {planDetails?.captain?.name}
                      </span>
                      <IconButton
                        data-test-id="unassign-captain-btn"
                        variant="default"
                        onClick={() => {
                          setOpenUnassignCaptainPopup(true);
                        }}
                        icon={<Icon name="error" size={20} />}
                        sx={{ p: 0, ml: "20px" }}
                      />
                      <UnassignCaptainPopup
                        isOpen={openUnassignCaptainPopup}
                        onDismiss={() => setOpenUnassignCaptainPopup(false)}
                        planId={planId}
                        captain={planDetails?.captain?.name}
                      />
                    </Info>
                  ) : null}
                </InfoWrapper>
                {cityLocalLanguage ? (
                  <InfoWrapper>
                    {/* Start Location */}
                    <Info data-test-id={"start_location_" + cityLocalLanguage}>
                      <label>
                        Start Location{" "}
                        <span
                          className="text-uppercase"
                          sx={{ direction: "rtl" }}
                        >
                          {cityLocalLanguage}:{" "}
                        </span>
                      </label>
                      <span>
                        {
                          planDetails?.firstDistrict.nameI18n?.[
                            cityLocalLanguage
                          ]
                        }
                      </span>
                    </Info>
                    {/* End Location */}
                    <Info data-test-id={"end_location_" + cityLocalLanguage}>
                      <label>
                        End Location{" "}
                        <span className="text-uppercase">
                          {cityLocalLanguage}:{" "}
                        </span>
                      </label>
                      <span>
                        {
                          planDetails?.lastDistrict.nameI18n?.[
                            cityLocalLanguage
                          ]
                        }
                      </span>
                    </Info>
                  </InfoWrapper>
                ) : null}

                <InfoWrapper>
                  <Info data-test-id="start_date">
                    <label>Start Date: </label>
                    {!startDateEditMode ? (
                      <div
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          maxHeight: "28px",
                        }}
                      >
                        <span>
                          {planStartDate
                            ? format(planStartDate, "dd/MM/yyyy")
                            : "Undefined"}
                        </span>
                        <IconButton
                          variant="default"
                          onClick={() => setStartDateEditMode(true)}
                          sx={{ px: "6px", py: "3px", ml: "20px" }}
                          icon={<Icon name="edit" size={20} fill={"gray"} />}
                          data-test-id="edit_start_date_button"
                        />
                      </div>
                    ) : (
                      <div
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mt: "5px",
                        }}
                      >
                        <PlanStartDatePicker
                          selected={planStartDate}
                          onChange={onPlanStartDateChange}
                        ></PlanStartDatePicker>
                        <IconButton
                          variant="secondary"
                          sx={{ p: "6px", ml: "20px" }}
                          data-test-id="save_start_date_button"
                          onClick={() => {
                            setStartDateEditMode(false);
                            submitPlanStartDate(planStartDate);
                          }}
                          icon={
                            <Icon
                              name="checked"
                              size={20}
                              fill={"currentcolor"}
                            />
                          }
                        />
                      </div>
                    )}
                  </Info>
                </InfoWrapper>
              </Box>
            </section>

            {/* Plan Schedules */}
            {planDetails?.schedules && timezone ? (
              <section data-test-id="schedule_details">
                <Heading as="h4" sx={{ mt: "40px", mb: "20px" }}>
                  {" "}
                  Schedules
                </Heading>
                <Table
                  useZebraStyles
                  useFullWidth
                  useTablePagination
                  pageSize={PAGE_SIZE}
                  totalCount={planDetails?.schedules?.length}
                  data={planDetails?.schedules}
                  columns={schedulesColumns}
                  onPageChange={(pageIndex) => setPage(pageIndex + 1)}
                  pageIndex={page - 1}
                  isLoading={isLoading}
                />
              </section>
            ) : null}

            {conflictData && (
              <PlanConflictPopup
                isOpen={openPlanPopupConflict}
                onDismiss={() => setOpenPlanPopupConflict(false)}
                onUnassignRides={onUnassignRides}
                conflictData={conflictData}
                timeZone={cityDetails?.timezone}
              />
            )}
          </div>
        )
      )}
    </Fragment>
  );
};

export default PlanDetails;
