import { AxiosResponse } from "axios";
import apiInstance from "api";

export type SearchStartLocationModel = {
  label: string;
  value: string;
};

type SearchStartLocationListModel = SearchStartLocationModel[];

const transformResponse = ({
  data,
}: AxiosResponse<string[]>): SearchStartLocationListModel => {
  return data.map((name) => ({
    label: name,
    value: name,
  }));
};

const searchStartLocation = async (
  query: string,
  city: string
): Promise<SearchStartLocationListModel> => {
  const response = await apiInstance.get<string[]>(
    "bidding_campaigns/start_location/autocomplete",
    {
      params: { query, city },
    }
  );

  return transformResponse(response);
};

export { searchStartLocation };
