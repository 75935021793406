import { FC } from "react";
import { Redirect, useHistory } from "react-router-dom";
import useLocalStorage from "../utils/useLocalStorage";
import { AuthenticationModel, initialState } from "../resources/Auth/useLogin";

const AuthControlWrapper: FC = ({ children }) => {
  const history = useHistory();
  const back = encodeURIComponent(history.location.pathname);
  const {
    storedValue: { token },
  } = useLocalStorage<AuthenticationModel>("ls.authdata", initialState);
  return token ? (
    <>{children}</>
  ) : (
    <Redirect
      to={{
        pathname: "/login",
        search: `back=${back}`,
      }}
    />
  );
};

export default AuthControlWrapper;
