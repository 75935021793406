import { useMutation } from "react-query";
import apiInstance from "api";
import { showAlert } from "@swvl/alert";

// Resources
const updatePlanStartDate = async ({ id, startDate }) => {
  const response = await apiInstance.patch(`plans/${id}/update_startDate`, {
    startDate,
  });
  return response;
};

const usePlanStartDate = () => {
  const mutation = useMutation(updatePlanStartDate, {
    mutationKey: "plan/updateStartDate",
    onSuccess: () => {
      showAlert({
        message: "Plan start date has been updated successfully",
        type: "success",
        id: "alert-success",
      });
    },
  });
  return mutation;
};

export { usePlanStartDate, updatePlanStartDate };
