import { useMutation } from "react-query";
import { AxiosResponse } from "axios";
import apiInstance from "api";

export type SearchCitiesModel = {
  name: string;
};

type SearchCitiesListModel = SearchCitiesModel[];

const transformResponse = ({
  data,
}: AxiosResponse<string[]>): SearchCitiesListModel => {
  return data.map((name) => ({ name }));
};

const searchCities = async (input: string): Promise<SearchCitiesListModel> => {
  const response = await apiInstance.get<string[]>(
    "bidding_campaigns/city/autocomplete",
    {
      params: { query: input },
    }
  );

  return transformResponse(response);
};

const useSearchBiddingCities = () => {
  return useMutation(["biddingCityAutocomplete"], searchCities, {
    onError: undefined,
  });
};

export { useSearchBiddingCities };
