/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { useHistory, useLocation } from "react-router-dom";
import { FC, useCallback, Fragment } from "react";
import Table from "@swvl/table";
import Spinner from "@swvl/spinner";
import { PAGE_SIZE, handleQuery } from "utils";
import { useListPlans } from "resources/Plan/useListPlans";
import NoResults from "components/NoListResults";
import useFilters from "hooks/useFilters";
import { planColumns } from "./PlanColumns";
import ListHeader from "./ListHeader";
import Filters from "./PlanFilters";
import { ListPlansParams } from "./constants";

const PlansList: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [filters, updateFilters] = useFilters<ListPlansParams>({ page: 1 });
  const { data: listPlans, isLoading } = useListPlans(filters);
  const planItems = listPlans?.plans;

  const getTrProps = useCallback((props, data) => {
    const { id } = data;
    return {
      ...props,
      onClick: (e) => {
        e.preventDefault();
        history.push(`${location.pathname}/${id}`);
      },
      sx: {
        "&[role][class]": {
          cursor: "pointer",
          transition: "background-color .15s",
          "&:hover": {
            backgroundColor: "gainsboro",
            cursor: "pointer",
          },
        },
      },
    };
  }, []);
  return (
    <Fragment>
      <ListHeader />
      <div>
        {/* Filters */}
        <Filters filters={filters} updateFilters={updateFilters} />

        {listPlans ? (
          <div data-test-id="plan-list-table">
            {/* Plan Tabel Items */}
            <Table
              useFullWidth
              useZebraStyles
              useSameSizeCells
              useTablePagination
              useControlledPagination
              pageSize={PAGE_SIZE}
              totalCount={listPlans?.count}
              data={planItems}
              columns={planColumns}
              onPageChange={(pageIndex) => {
                handleQuery("add", history, "page", (pageIndex + 1).toString());
              }}
              pageIndex={filters.page - 1}
              isLoading={isLoading}
              getTrProps={getTrProps}
              noDataComponent={
                <NoResults
                  css={{ width: 600, margin: "20px auto" }}
                  data-test-id="no-result-found"
                  title="No Results Found"
                  body="There are no plans yet."
                />
              }
            />
          </div>
        ) : isLoading ? (
          <Spinner color="primary" sx={{ m: "auto", display: "flex" }} />
        ) : null}
      </div>
    </Fragment>
  );
};

export default PlansList;
