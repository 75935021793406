/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from "theme-ui";
import { Fragment } from "react";
import { getAdminDashboardLink, CURRENCY_CONVERT } from "utils";
import Button from "@swvl/button";
import Icon from "@swvl/icon";
import startCase from "lodash/fp/startCase";
import { Column } from "react-table";
import { Hit } from "resources/PlanSelling/useBiddingPlanBids";
import Tooltip from "@swvl/tooltip";

const statusStyle = {
  padding: "2px 4px",
  color: "#fff",
  borderRadius: "4px",
  fontWeight: "bold",
};

const StatusPill = ({ status }) => {
  switch (status) {
    case "placed":
      return (
        <span
          sx={{
            ...statusStyle,
            backgroundColor: "goldenrod",
          }}
        >
          Pending
        </span>
      );

    case "cancelled_by_operator":
    case "cancelled_by_captain":
    case "rejected":
      return (
        <span
          sx={{
            ...statusStyle,
            backgroundColor: "crimson",
          }}
        >
          {startCase(status)}
        </span>
      );

    case "accepted":
      return (
        <span
          sx={{
            ...statusStyle,
            backgroundColor: "mediumseagreen",
          }}
        >
          Accepted
        </span>
      );

    default:
      return <span>{status}</span>;
  }
};

function convertAmount(amount) {
  const amountConverted = amount / CURRENCY_CONVERT;
  const roundedAmountIfNecessary =
    Math.round((amountConverted + Number.EPSILON) * 100) / 100;

  return roundedAmountIfNecessary;
}

export default (openAcceptBidPopup, openRejectionPopup): Column<Hit>[] => [
  {
    Header: "Captain Name",
    accessor: "captain",
    Cell: ({ value: { _id, name } }): JSX.Element => {
      return (
        <a
          title={name}
          href={`${getAdminDashboardLink()}/captain/${_id}`}
          target="_blank"
          rel="noreferrer"
        >
          {name}
        </a>
      );
    },
  },
  {
    Header: "Bid Amount",
    Cell: ({
      row: {
        original: { amount, currency },
      },
    }) => {
      const roundedAmountIfNecessary = convertAmount(amount);

      return `${currency} ${roundedAmountIfNecessary}`;
    },
  },
  {
    Header: "Plan Version",
    accessor: "plan_selling_contract_version_md5",
  },
  {
    Header: "Bid Status",
    accessor: "state",
    Cell: ({ value }) => <StatusPill status={value} />,
  },
  {
    Header: "Rejection / Cancellation Reason",
    Cell: ({
      row: {
        original: { rejection_reason, cancellation_reason },
      },
      cell: { getCellProps },
    }) => {
      const { key } = getCellProps();

      return (
        <Fragment>
          <span
            data-for={key}
            data-tip={rejection_reason || cancellation_reason}
          >
            {rejection_reason || cancellation_reason || "-"}
          </span>
          <Tooltip id={key} />
        </Fragment>
      );
    },
  },
  {
    Header: "Actions",
    Cell: ({
      row: {
        original: {
          state,
          _id,
          amount,
          currency,
          captain: { name: captainName },
        },
      },
    }) => {
      if (state === "placed") {
        const roundedAmountIfNecessary = convertAmount(amount);
        const price = `${currency} ${roundedAmountIfNecessary}`;

        return (
          <Button
            variant="default"
            icon={<Icon name={"cancel"} size={20} fill="crimson" />}
            onClick={() => openRejectionPopup(_id, captainName, price)}
            sx={{
              "&:hover": {
                backgroundColor: "crimson",

                span: {
                  color: "#fff",
                },

                svg: {
                  path: {
                    fill: "#fff",
                  },
                },
              },
            }}
          >
            <span sx={{ color: "crimson" }}>Reject Bid</span>
          </Button>
        );
      }
      return "";
    },
  },
  {
    id: "actions-accept-bid",
    Cell: ({
      row: {
        original: {
          state,
          _id,
          amount,
          currency,
          captain: { name: captainName },
        },
      },
    }) => {
      if (state === "placed") {
        const roundedAmountIfNecessary = convertAmount(amount);
        const price = `${currency} ${roundedAmountIfNecessary}`;

        return (
          <Button
            variant="default"
            icon={<Icon name={"checked"} size={20} fill="seagreen" />}
            onClick={() => openAcceptBidPopup(_id, captainName, price)}
            sx={{
              "&:hover": {
                backgroundColor: "seagreen",

                span: {
                  color: "#fff",
                },

                svg: {
                  path: {
                    fill: "#fff",
                  },
                },
              },
            }}
          >
            <span sx={{ color: "seagreen" }}>Accept Bid</span>
          </Button>
        );
      }
      return "";
    },
  },
];
