import { useMutation } from "react-query";
import apiInstance from "api";
import useLocalStorage from "utils/useLocalStorage";

// Types
export type AuthenticationModel = {
  token?: string;
  expiryDate?: string;
  refreshToken?: string;
  jwt?: string;
  role?: string;
  owner?: string;
  error?: string;
};

interface RefreshTokenResponse {
  __v: number;
  updatedAt: string;
  createdAt: string;
  owner: string;
  type: string;
  _id: string;
  expiry_date: string;
  refresh_token: string;
  token: string;
  is_expired: boolean;
  id: string;
  jwt: string;
  role: string;
}

export interface RefreshTokenRequest {
  refresh_token: string;
}

export type RefreshTokenTransform = Omit<AuthenticationModel, "error">;

// Transforms
const transformResponse = (response: unknown): RefreshTokenTransform => {
  const {
    token,
    expiry_date: expiryDate,
    jwt,
    refresh_token: refreshToken,
    role,
  } = response as RefreshTokenResponse;
  return { token, expiryDate, jwt, refreshToken, role };
};

// Initial State
const initialState: AuthenticationModel = {
  token: null,
  expiryDate: null,
  refreshToken: null,
  jwt: null,
  role: null,
  error: null,
};

// Resources
const refreshToken = async (data: RefreshTokenRequest) => {
  const response = await apiInstance.post<
    RefreshTokenRequest,
    RefreshTokenResponse
  >("token/refresh", data);
  return transformResponse(response);
};

const useRefreshToken = () => {
  const { setValue: setAuthenticationData } =
    useLocalStorage<AuthenticationModel>("ls.authdata");

  const mutation = useMutation(refreshToken, {
    mutationKey: "user/refresh-token",
    onSuccess: (response) => {
      setAuthenticationData(response);
    },
    onError: () => {
      setAuthenticationData(initialState);
    },
  });

  return mutation;
};

export { useRefreshToken, initialState };
