import { useMutation, useQueryClient } from "react-query";
import apiInstance from "api";
import { showAlert } from "@swvl/alert";
import { AxiosResponse } from "axios";

export interface UpdateLabelsResponse {
  planId: string;
  labels: string[];
}

export type UpdateLabelsModel = {
  labels: string[];
};

// Resources
const updatePlanLabels = async ({ planId, labels }: UpdateLabelsResponse) => {
  const response = await apiInstance.patch<UpdateLabelsResponse>(
    `plans/${planId}/update_labels`,
    { labels }
  );
  return response;
};

const usePlanLabels = (id) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(updatePlanLabels, {
    mutationKey: "plan/updateLabels",
    onSuccess: () => {
      queryClient.invalidateQueries(["plan/details", id]);
      showAlert({
        message: "Plan Labels have been updated successfully",
        type: "success",
        id: "alert-success",
      });
    },
    onError: (error: AxiosResponse) => {
      showAlert({
        message: error.data?.message,
        type: "error",
        id: "alert-error",
      });
    },
  });
  return mutation;
};

export { usePlanLabels };
