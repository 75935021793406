/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";

import React, { useEffect } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";

import AuthControl from "../components/AuthControlWrapper";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import sidebarTabs from "constants/sidebarTabs";
import { SIDEBAR_WIDTH } from "constants/styles";
import * as Sentry from "@sentry/react";

import Login from "./login";
import ErrorCallback from "components/ErrorCallback";
import AlertContainer from "@swvl/alert";

import useLocalStorage from "../utils/useLocalStorage";

import {
  AuthenticationModel,
  useRefreshToken,
} from "resources/Auth/useRefreshToken";
import {
  createRequestInterceptor,
  createResponseInterceptor,
  ejectInterceptors,
} from "../api";
import Plans from "./plans";
import PlanSelling from "./plan-selling";

/** Specify main modules */
export default function Main() {
  const { storedValue: authenticationData } =
    useLocalStorage<AuthenticationModel>("ls.authdata");
  const { mutate: refreshToken } = useRefreshToken();
  const history = useHistory();

  useEffect(() => {
    const reqInterceptor = createRequestInterceptor(refreshToken);
    const resInterceptor = createResponseInterceptor({ history });

    return () => ejectInterceptors(reqInterceptor, resInterceptor);
  }, [authenticationData]);

  return (
    <div
      sx={{
        position: "relative",
      }}
    >
      <Switch>
        <Route key="Login" path={`/login`} component={Login} />
        <AuthControl>
          <div
            sx={{
              display: "grid",
              gridTemplateAreas: `"header header" "sidebar main"`,
              gridTemplateColumns: `${SIDEBAR_WIDTH}px 1fr`,
              gridTemplateRows: "64px 1fr",
              minHeight: "100vh",
            }}
          >
            <Sidebar tabs={sidebarTabs} />
            <Header css={{ gridArea: "header" }} />

            <div css={{ gridArea: "main", padding: 20 }}>
              <Sentry.ErrorBoundary
                fallback={ErrorCallback}
                onReset={() => {
                  history.push("/");
                }}
              >
                <Route exact path="/">
                  <Redirect to="/plans" />
                </Route>
                <Route key="Plans" path="/plans" component={Plans} />

                <Route
                  key="PlanSelling"
                  path="/plan-selling"
                  component={PlanSelling}
                />
              </Sentry.ErrorBoundary>
            </div>
          </div>
        </AuthControl>
      </Switch>
      <div data-test-id="alert-container">
        <AlertContainer position="bottom-right" />
      </div>
    </div>
  );
}
