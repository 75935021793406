/** @jsxRuntime classic */
/** @jsx jsx */

import { Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Table from "@swvl/table";
import { jsx } from "theme-ui";
import { PAGE_SIZE } from "utils";
import {
  useListBidding,
  ListBiddingParams,
} from "resources/PlanSelling/useListBidding";
import { biddingListColumns } from "./BiddingListColumns";

import BiddingListHeader from "./BiddingListHeader";
import BiddingListFilters from "./BiddingListFilters";
import NoResults from "components/NoListResults";

import useFilters from "hooks/useFilters";

function BidingList(): JSX.Element {
  const history = useHistory();
  const location = useLocation();

  const [filters, updateFilters] = useFilters<ListBiddingParams>({ page: 1 });
  const { data: listBidding, isLoading } = useListBidding(filters);

  function getTrProps(props, data) {
    const { _id } = data;
    return {
      ...props,
      onClick: (e) => {
        e.preventDefault();
        history.push(`${location.pathname}/${_id}`);
      },
      sx: {
        "&[role][class]": {
          cursor: "pointer",
          transition: "background-color .15s",
          "&:hover": {
            backgroundColor: "gainsboro",
            cursor: "pointer",
          },
        },
      },
    };
  }

  return (
    <Fragment>
      <BiddingListHeader />
      <BiddingListFilters filters={filters} updateFilters={updateFilters} />

      {listBidding && (
        <div sx={{ mt: 4 }}>
          <Table
            useFullWidth
            useZebraStyles
            useSameSizeCells
            useTablePagination
            useControlledPagination
            pageSize={PAGE_SIZE}
            totalCount={listBidding.total}
            data={listBidding.hits}
            columns={biddingListColumns}
            onPageChange={(pageIndex) => updateFilters({ page: pageIndex + 1 })}
            pageIndex={filters.page - 1}
            isLoading={isLoading}
            getTrProps={getTrProps}
            noDataComponent={
              <NoResults
                css={{ width: 600, margin: "20px auto" }}
                title="No Results Found"
                body="There are no bidding plans yet."
              />
            }
          />
        </div>
      )}
    </Fragment>
  );
}

export default BidingList;
