import { useMutation } from "react-query";
import apiInstance from "api";
import { showAlert } from "@swvl/alert";

type DescriptionModel = {
  [key: string]: string;
};

export interface UpdateDescriptionResponse {
  id: string;
  descriptionI18n: DescriptionModel;
}

export type UpdateDescriptionModel = {
  descriptionI18n: DescriptionModel;
};

// Resources
const updatePlanDescription = async ({
  id,
  descriptionI18n,
}: UpdateDescriptionResponse) => {
  const response = await apiInstance.patch<UpdateDescriptionResponse>(
    `plans/${id}/update_description`,
    { descriptionI18n }
  );
  return response;
};

const usePlanDescription = () => {
  const mutation = useMutation(updatePlanDescription, {
    mutationKey: "plan/updateDescription",
    onSuccess: () => {
      showAlert({
        message: "Plan Description has been updated successfully",
        type: "success",
        id: "alert-success",
      });
    },
  });
  return mutation;
};

export { usePlanDescription, updatePlanDescription };
