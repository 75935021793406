/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text, Heading } from "theme-ui";
import { FC, Fragment, useEffect } from "react";
import Icon from "@swvl/icon";
import Popup from "@swvl/popup";
import { Button } from "@swvl/button";
import { useDeactivatePlan } from "resources/Plan/usePlanStatus";
import { useActivatePlan } from "resources/Plan/usePlanStatus";
import { AxiosResponse } from "axios";

type PlanStatusPopupProps = {
  isOpen: boolean;
  planId: string;
  active: boolean;
  captain: { id: string; name: string };
  onDismiss?: () => void;
  onCaptainConflict: (conflict, methodCalledAfterFixConflict) => void;
};

const PlanStatusPopup: FC<PlanStatusPopupProps> = ({
  isOpen,
  planId,
  active,
  captain,
  onDismiss,
  onCaptainConflict,
}) => {
  const { mutate: deactivatePlan } = useDeactivatePlan(planId);
  const { mutate: activatePlan, error } = useActivatePlan(planId);

  useEffect(() => {
    if (
      (error as AxiosResponse)?.data?.captainConflict &&
      Object.keys((error as AxiosResponse)?.data?.captainConflict).length
    )
      onCaptainConflict((error as AxiosResponse).data.captainConflict, {
        method: activatePlan,
        params: planId,
      });
  }, [error]);

  const Content = () => {
    return (
      <section sx={{ mt: 3, mx: 5 }} data-test-id="deactivate_popup">
        <div
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Icon name="updated" size={50} fill="#FFC659" />
        </div>
        <section sx={{ display: "flex", flexDirection: "column" }}>
          <Heading
            sx={{ mb: 3, fontWeight: "bold" }}
            variant={"headline"}
            data-test-id="popup_title"
          >
            {active ? "Deactivate Plan" : "Activate Plan"}
          </Heading>

          <Text
            sx={{ mb: 4, color: "opacity.black-54", lineHeight: "1.8" }}
            data-test-id="popup_message"
          >
            {active
              ? "Are you sure you want to deactivate this plan? This will deactivate all schedules assigned to this plan."
              : captain
              ? "Are you sure you want to activate this plan? This will activate all schedules in this plan."
              : "You are activating this plan with no captain assigned. This will begin ride dispatch from the next dispatcher run. Are you sure you want to perform this action?"}
          </Text>
          <div sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              type="button"
              variant="gray"
              onClick={onDismiss}
              data-test-id="cancel_button"
            >
              Cancel
            </Button>
            {active ? (
              <Button
                type="button"
                variant="primary"
                onClick={() => {
                  deactivatePlan(planId);
                  onDismiss();
                }}
                data-test-id="confirm_button"
              >
                Confirm
              </Button>
            ) : (
              <Button
                type="button"
                variant="primary"
                onClick={() => {
                  activatePlan(planId);
                  onDismiss();
                }}
                data-test-id="confirm_button"
              >
                Confirm
              </Button>
            )}
          </div>
        </section>
      </section>
    );
  };

  return (
    <Fragment>
      <Popup withClose closePopup={onDismiss} isOpen={isOpen} width={500}>
        <Content />
      </Popup>
    </Fragment>
  );
};

export default PlanStatusPopup;
