import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import apiInstance from "api";
import isNil from "lodash/fp/isNil";

const labelAutocomplete = "plans/autocomplete";
export interface SearchLabelsResponse {
  hits: {
    _source: {
      labels: string[];
    };
  }[];
}

export type SearchLabelsModel = {
  value: string;
  label: string;
};

type SearchLabelsListModel = SearchLabelsModel[];

const transformResponse = (
  response: SearchLabelsResponse
): SearchLabelsListModel => {
  const [rightMatch] = response.hits;

  return rightMatch?._source.labels.map((label) => ({
    value: label,
    label,
  }));
};

const searchLabels = async (input: string): Promise<SearchLabelsListModel> => {
  const response = await apiInstance.get<AxiosResponse, SearchLabelsResponse>(
    labelAutocomplete,
    {
      params: { query: input },
    }
  );

  return transformResponse(response);
};

const useSearchLabels = (input: string) => {
  return useQuery([labelAutocomplete, input], () => searchLabels(input), {
    enabled: !isNil(input),
    initialData: [] as SearchLabelsListModel,
    onError: undefined,
  });
};

export { useSearchLabels, searchLabels };
