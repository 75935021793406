/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text, Heading } from "theme-ui";
import { FC, useState, Fragment } from "react";
import Icon from "@swvl/icon";
import Popup from "@swvl/popup";
import { Button } from "@swvl/button";
import Autocomplete from "@swvl/autocomplete";
import { useUpdatePlanBustype } from "resources/Plan/useUpdatePlanBustype";
import { useSearchBustypes } from "resources/Bustype/useSearchBustypes";

type UpdatePlanBustypePopupProps = {
  onDismiss?: () => void;
  isOpen: boolean;
  planId: string;
};

const UpdatePlanBustypePopup: FC<UpdatePlanBustypePopupProps> = ({
  onDismiss,
  isOpen,
  planId,
}) => {
  const { mutate: updatePlanBustype } = useUpdatePlanBustype(planId);

  const Content = () => {
    const [searchBustype, setSearchBustype] = useState<string>();
    const [busType, setBusType] = useState<string>();
    const { data: bustypeSuggestions, isFetching: isFetchingBustype } =
      useSearchBustypes(searchBustype);

    return (
      <section sx={{ mt: 3, mx: 5 }} data-test-id="edit_bustype_popup">
        <div
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Icon name="bus" size={50} fill="#FFC659" />
        </div>
        <section sx={{ display: "flex", flexDirection: "column" }}>
          <Heading
            sx={{ mb: 3, fontWeight: "bold" }}
            variant={"headline"}
            data-test-id="popup_title"
          >
            Update Bustype
          </Heading>
          <Autocomplete
            sx={{ pb: "20px" }}
            suggestions={bustypeSuggestions}
            placeholder="Search Bustype"
            isLoading={isFetchingBustype}
            onChangeQuery={(input) => {
              setSearchBustype(input);
            }}
            getOptionLabel={(option) => option.name}
            renderOption={(option) => (
              <Text sx={{ cursor: "pointer" }}>{option.name}</Text>
            )}
            getOptionKey={(option) => option.id}
            onSelect={(option) => {
              setBusType(option.id);
            }}
          />
          <div sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
            <Button
              type="button"
              variant="gray"
              onClick={onDismiss}
              data-test-id="cancel_button"
            >
              Cancel
            </Button>
            <Button
              type="button"
              variant="primary"
              onClick={() => {
                updatePlanBustype({ planId, busType });
                onDismiss();
              }}
              data-test-id="confirm_button"
            >
              Confirm
            </Button>
          </div>
        </section>
      </section>
    );
  };

  return (
    <Fragment>
      <Popup withClose closePopup={onDismiss} isOpen={isOpen} width={500}>
        <Content />
      </Popup>
    </Fragment>
  );
};

export default UpdatePlanBustypePopup;
