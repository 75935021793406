import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import apiInstance from "api";
import { isNil } from "lodash";

export interface CityDetailsResponse {
  _id: string;
  name: string;
  currency: string;
  languages: string[];
  timezone: number;
}

export type CityDetailsModel = {
  id: string;
  name: string;
  currency: string;
  languages: string[];
  timezone: number;
};

const transformResponse = (response: CityDetailsResponse): CityDetailsModel => {
  return { ...response, id: response._id };
};

const getCityDetails = async ({
  id,
}: {
  id: string;
}): Promise<CityDetailsModel> => {
  const response = await apiInstance.get<AxiosResponse, CityDetailsResponse>(
    `city/${id}`
  );

  return transformResponse(response);
};

const useCityDetails = (id: string) => {
  return useQuery(["city", id], () => getCityDetails({ id }), {
    enabled: !isNil(id),
    onError: undefined,
  });
};

export { useCityDetails, getCityDetails, transformResponse };
