/** @jsxRuntime classic */
/** @jsx jsx */
import {
  createContext,
  useContext,
  useState,
  FC,
  Dispatch,
  SetStateAction,
  ReactNode,
} from "react";
import { jsx } from "theme-ui";
import Icon from "@swvl/icon";
import { IconButton } from "@swvl/button";
import Spinner from "@swvl/spinner";

type EditFieldContextValue = {
  isEditMode: boolean;
  setIsEditMode: Dispatch<SetStateAction<boolean>>;
};

type ViewProps = {
  isLoading?: boolean;
};

type EditProps = {
  submit: () => void;
  submitDisabled?: boolean;
};

const EditFieldContext = createContext<EditFieldContextValue>(null);

function useEditFieldContext() {
  const context = useContext(EditFieldContext);

  if (!context) {
    throw new Error(
      `Edit Field compound components cannot be rendered outside the Edit Field Controls component`
    );
  }
  return context;
}

const EditFieldControls = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  return (
    <EditFieldContext.Provider value={{ isEditMode, setIsEditMode }}>
      {children}
    </EditFieldContext.Provider>
  );
};

const Edit: FC<EditProps> = ({ children, submit, submitDisabled = false }) => {
  const { isEditMode, setIsEditMode } = useEditFieldContext();
  return isEditMode ? (
    <div sx={{ display: "flex", alignItems: "center" }}>
      <div sx={{ flexGrow: 1 }}>{children}</div>
      <div sx={{ ml: 1, display: "flex" }}>
        <IconButton
          variant="default"
          icon={<Icon name="cancel" size={20} fill="crimson" />}
          onClick={() => setIsEditMode(false)}
        />

        <IconButton
          variant="default"
          icon={
            <Icon
              name="checked"
              size={15}
              fill={submitDisabled ? "black-08" : "mediumseagreen"}
            />
          }
          onClick={() => {
            setIsEditMode(false);
            submit();
          }}
          disabled={submitDisabled}
        />
      </div>
    </div>
  ) : null;
};

const View: FC<ViewProps> = ({ children, isLoading = false }) => {
  const { isEditMode, setIsEditMode } = useEditFieldContext();
  return isEditMode ? null : (
    <div sx={{ display: "flex", alignItems: "center" }}>
      <div sx={{ flexGrow: 1 }}>{children}</div>
      <div sx={{ ml: 1, mb: -2 }}>
        {isLoading ? (
          <Spinner color="primary" size="24" />
        ) : (
          <IconButton
            variant="default"
            icon={<Icon name="edit" size={15} fill="mediumblue" />}
            onClick={() => setIsEditMode(true)}
          />
        )}
      </div>
    </div>
  );
};

EditFieldControls.Edit = Edit;
EditFieldControls.View = View;

export default EditFieldControls;
