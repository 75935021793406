/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text, Heading } from "theme-ui";
import { FC, useState, useEffect, Fragment } from "react";
import Icon from "@swvl/icon";
import Popup from "@swvl/popup";
import { Button } from "@swvl/button";
import Autocomplete from "@swvl/autocomplete";
import { useAssignCaptain } from "resources/Plan/useAssignCaptain";
import { useSearchCaptains } from "resources/Captain/useSearchCaptain";
import { AxiosResponse } from "axios";

type AssignCaptainPopupProps = {
  isOpen: boolean;
  planId: string;
  onDismiss?: () => void;
  setSelectedCaptain: (captainId: string) => void;
  onCaptainConflict: (conflict, methodCalledAfterFixConflict) => void;
};

const AssignCaptainPopup: FC<AssignCaptainPopupProps> = ({
  isOpen,
  planId,
  onDismiss,
  setSelectedCaptain,
  onCaptainConflict,
}) => {
  const { mutate: assignCaptain, error } = useAssignCaptain(planId);
  const [captainId, setCaptainId] = useState<string>();

  useEffect(() => {
    if (
      (error as AxiosResponse)?.data?.captainConflict &&
      Object.keys((error as AxiosResponse)?.data?.captainConflict).length &&
      captainId
    )
      onCaptainConflict((error as AxiosResponse).data.captainConflict, {
        method: assignCaptain,
        params: { planId, captain: captainId },
      });
  }, [error, captainId]);

  const Content = () => {
    const [searchCaptain, setSearchCaptain] = useState<string>();
    const [captain, setCaptain] = useState<string>();
    const { data: captainSuggestions, isFetching: isFetchingCaptain } =
      useSearchCaptains(searchCaptain);

    return (
      <section sx={{ mt: 3, mx: 5 }} data-test-id="assign_captain_popup">
        <div
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Icon name="assign_employees" size={50} fill="#FFC659" />
        </div>
        <section sx={{ display: "flex", flexDirection: "column" }}>
          <Heading
            sx={{ mb: 3, fontWeight: "bold" }}
            variant={"headline"}
            data-test-id="popup_title"
          >
            Assign Captain
          </Heading>
          <Autocomplete
            sx={{ pb: "20px" }}
            suggestions={captainSuggestions}
            placeholder="Search Captain"
            isLoading={isFetchingCaptain}
            onChangeQuery={(input) => {
              setSearchCaptain(input);
            }}
            getOptionLabel={(option) => option.name}
            renderOption={(option) => (
              <Text
                sx={{ cursor: "pointer" }}
              >{`${option.name} - ${option.phone}`}</Text>
            )}
            getOptionKey={(option) => option.id}
            onSelect={(option) => {
              setCaptain(option.id);
            }}
          />
          <div sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
            <Button
              type="button"
              variant="gray"
              onClick={onDismiss}
              data-test-id="cancel_button"
            >
              Cancel
            </Button>
            <Button
              type="button"
              variant="primary"
              onClick={() => {
                setSelectedCaptain(captain);
                setCaptainId(captain);
                assignCaptain({ planId, captain });
                onDismiss();
              }}
              data-test-id="confirm_button"
            >
              Confirm
            </Button>
          </div>
        </section>
      </section>
    );
  };

  return (
    <Fragment>
      <Popup withClose closePopup={onDismiss} isOpen={isOpen} width={500}>
        <Content />
      </Popup>
    </Fragment>
  );
};

export default AssignCaptainPopup;
