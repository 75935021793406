/** @jsxRuntime classic */
/** @jsx jsx */

import { useState, useEffect, Fragment } from "react";
import { jsx, Heading, Divider, Text } from "theme-ui";
import { useParams, useHistory, useLocation, Link } from "react-router-dom";
import { useFormik, FormikProvider } from "formik";
import { object, number, date } from "yup";
import Icon from "@swvl/icon";
import Button from "@swvl/button";
import Toggle from "@swvl/toggle";
import Spinner from "@swvl/spinner";
import { WeekdaysCheckbox } from "@swvl/weekdays";
import Table from "@swvl/table";
import startCase from "lodash/fp/startCase";
import format from "date-fns/fp/format";
import add from "date-fns/fp/add";
import sub from "date-fns/fp/sub";

import EditFieldControls from "components/EditFieldControls";
import NoResults from "components/NoListResults";
import FormField from "components/FormField";
import DateTimePicker from "components/DateTimePicker";
import Menu from "components/Menu";
import biddingPlanBidsColumns from "./BiddingPlanBidsColumns";
import CancelCampaignPopup from "./CancelCampaignPopup";
import BidAcceptancePopupData from "./BidAcceptancePopupData";
import BidRejectionPopupData from "./BidRejectionPopupData";
import HideBiddingPopup from "./HideBiddingPopup";
import EndCampaignPopup from "./EndCampaignPopup";

import {
  CURRENCY_CONVERT,
  WEEKDAYS,
  PAGE_SIZE,
  getTimeOfMilliseconds,
  pluralizeWord,
  getFromQuery,
  handleQuery,
} from "utils";
import { useBiddingPlanDetails } from "resources/PlanSelling/useBiddingPlanDetails";
import { useBiddingPlanBids } from "resources/PlanSelling/useBiddingPlanBids";
import { useUpdateBiddingMaxNumberOfBids } from "resources/PlanSelling/useUpdateBiddingMaxNumberOfBids";
import { useUpdateBiddingMinimumBusYear } from "resources/PlanSelling/useUpdateBiddingMinimumBusYear";
import { useUpdateBiddingOpenDate } from "resources/PlanSelling/useUpdateBiddingOpenDate";
import { useUpdateBiddingCloseDate } from "resources/PlanSelling/useUpdateBiddingCloseDate";

import { Row, DetailsContainer } from "./styled";

const BiddingDetails = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const currentPage = getFromQuery("page", location.search);
  const [page, setPage] = useState<number>(currentPage ? +currentPage : 1);
  const [isCancelCampaignPopupOpen, setIsCancelCampaignPopupOpen] =
    useState(false);
  const [acceptancePopupData, setAcceptancePopupData] = useState<{
    id: string;
    captainName: string;
    price: string;
  }>(null);
  const [rejectionPopupData, setRejectionPopupData] = useState<{
    id: string;
    captainName: string;
    price: string;
  }>(null);
  const [hideBiddingPopupData, setHideBiddingPopupData] =
    useState<{ hidden: boolean }>(null);
  const [isEndCampaignPopupOpen, setIsEndCampaignPopupOpen] =
    useState<boolean>(false);

  const { id: biddingPlanId } = useParams<{ id: string }>();
  const { data: biddingPlanDetails, isLoading: isBiddingPlanDetailsLoading } =
    useBiddingPlanDetails(biddingPlanId);
  const { data: biddingPlanBids, isLoading: isBiddingPlanBidsLoading } =
    useBiddingPlanBids(biddingPlanId);
  const openForBid = biddingPlanDetails?.type === "variable_price";

  const {
    mutate: updateMaxNumberOfBids,
    isLoading: isUpdateMaxNumberOfBidsLoading,
  } = useUpdateBiddingMaxNumberOfBids(biddingPlanId);
  const {
    mutate: updateMinimumBusYear,
    isLoading: isUpdateMinimumBusYearLoading,
  } = useUpdateBiddingMinimumBusYear(biddingPlanId);
  const { mutate: updateOpenDate, isLoading: isUpdateOpenDateLoading } =
    useUpdateBiddingOpenDate(biddingPlanId);
  const { mutate: updateCloseDate, isLoading: isUpdateCloseDateLoading } =
    useUpdateBiddingCloseDate(biddingPlanId);

  const isCancelCampaignDisabled = !["scheduled", "opened", "closed"].includes(
    biddingPlanDetails?.state
  );
  const isToggleVisibilityDisabled = !["scheduled", "opened"].includes(
    biddingPlanDetails?.state
  );
  const isEndCampaignDisabled = biddingPlanDetails?.state !== "opened";

  const maximumStartDateValidation = biddingPlanDetails?.close_date
    ? sub({ minutes: 1 }, new Date(biddingPlanDetails.close_date))
    : 0;
  const minimumEndDateValidation = biddingPlanDetails?.open_date
    ? add({ minutes: 1 }, new Date(biddingPlanDetails.open_date))
    : 0;

  const biddingDetailsFormInitialData = {
    "Max Number of Bids": biddingPlanDetails?.maximum_bids_to_receive,
    "Minimum Bus Year": biddingPlanDetails?.minimum_bus_year,
    "Start Date Time":
      biddingPlanDetails?.open_date && new Date(biddingPlanDetails.open_date),
    "End Date Time":
      biddingPlanDetails?.close_date && new Date(biddingPlanDetails.close_date),
  };

  const biddingDetailsFormScheme = object({
    "Max Number of Bids": number().required(),
    "Minimum Bus Year": number()
      .min(2000)
      .max(biddingPlanDetails?.minimum_bus_year)
      .required(),
    "Start Date Time": date()
      .max(
        maximumStartDateValidation,
        `Start Date Time field must be at earlier than or equal to ${format(
          "EEE, MMM do - hh:mm aaa",
          maximumStartDateValidation
        )}`
      )
      .required(),
    "End Date Time": date()
      .min(
        minimumEndDateValidation,
        `End Date Time field must be later than or equal to ${format(
          "EEE, MMM do - hh:mm aaa",
          minimumEndDateValidation
        )}`
      )
      .required(),
  });

  const formik = useFormik({
    initialValues: biddingDetailsFormInitialData,
    enableReinitialize: true,
    validationSchema: biddingDetailsFormScheme,
    onSubmit: () => null,
  });

  const {
    errors,
    touched,
    values,
    handleBlur,
    handleChange,
    setFieldTouched,
    setFieldValue,
  } = formik;

  function getTrProps(props) {
    return {
      ...props,
      sx: {
        "&[role][class]": {
          cursor: "pointer",
          transition: "background-color .15s",
          "&:hover": {
            backgroundColor: "gainsboro",
            cursor: "pointer",
          },
        },
      },
    };
  }

  function openAcceptBidPopup(id, captainName, price) {
    setAcceptancePopupData({ id, captainName, price });
  }

  function openRejectionPopup(id, captainName, price) {
    setRejectionPopupData({ id, captainName, price });
  }

  useEffect(() => {
    handleQuery("add", history, "page", page.toString());
  }, [page]);

  const CampaignHidden = (): JSX.Element => {
    const campaignVisible = (
      <Row>
        <Icon name="view" fill="mediumseagreen" />
        <Text sx={{ ml: 1 }}>Campaign Visible</Text>
      </Row>
    );
    const campaignHidden = (
      <Row>
        <Icon name="view" fill="crimson" />
        <Text sx={{ ml: 1 }}>Campaign Hidden</Text>
      </Row>
    );

    return biddingPlanDetails.hidden ? campaignHidden : campaignVisible;
  };

  return (
    <Fragment>
      <Row>
        <Heading data-test-id="bidding-plan">Bidding Plan</Heading>

        {!isBiddingPlanDetailsLoading && biddingPlanDetails && (
          <Row>
            <Menu>
              <Menu.Trigger disabled={isToggleVisibilityDisabled}>
                <CampaignHidden />
              </Menu.Trigger>

              <Menu.MenuWrapper>
                {biddingPlanDetails?.hidden ? (
                  <Menu.Item
                    onClick={() =>
                      setHideBiddingPopupData({
                        hidden: true,
                      })
                    }
                  >
                    <Text sx={{ color: "rgba(0,0,0,0.5)" }}>
                      Make campaign{" "}
                      <span sx={{ fontWeight: "bold" }}>Visible</span> for
                      bidding
                    </Text>
                  </Menu.Item>
                ) : (
                  <Menu.Item
                    onClick={() =>
                      setHideBiddingPopupData({
                        hidden: false,
                      })
                    }
                  >
                    <Text sx={{ color: "rgba(0,0,0,0.5)" }}>
                      <span sx={{ fontWeight: "bold" }}>Hide</span> Campaign
                      from bidding
                    </Text>
                  </Menu.Item>
                )}
              </Menu.MenuWrapper>
            </Menu>

            <Row sx={{ borderLeft: "1px solid #e5e5e5", ml: 3 }}>
              <Button
                variant="ghost"
                icon={<Icon name="clock" size={15} fill="currentcolor" />}
                sx={{ ml: 3 }}
                disabled={isEndCampaignDisabled}
                onClick={() => setIsEndCampaignPopupOpen(true)}
              >
                End Campaign Early
              </Button>
              <Button
                variant="secondary"
                icon={<Icon name="error" size={15} fill="currentcolor" />}
                sx={{ ml: 3 }}
                onClick={() => setIsCancelCampaignPopupOpen(true)}
                disabled={isCancelCampaignDisabled}
              >
                Cancel Campaign
              </Button>
            </Row>
          </Row>
        )}
      </Row>
      <Divider color="gainsboro" sx={{ m: "20px -20px" }} />

      {isBiddingPlanDetailsLoading ? (
        <Spinner color="primary" sx={{ m: "auto", display: "flex" }} />
      ) : (
        biddingPlanDetails && (
          <Fragment>
            <Text sx={{ fontSize: 2 }}>Plan Details</Text>
            <DetailsContainer sx={{ mt: 2 }}>
              <div className="details-main-info">
                <Text>Plan Name</Text>
                <Link
                  sx={{ mt: 2, fontWeight: "bold", color: "darkblue" }}
                  to={`/plans/${biddingPlanDetails.active_contract.plan}`}
                >
                  {biddingPlanDetails.active_contract.plan_name}
                </Link>

                <Text sx={{ mt: 3 }}>City</Text>
                <Text sx={{ mt: 2, fontWeight: "bold" }}>
                  {biddingPlanDetails.active_contract.cities
                    .map(({ name }) => name)
                    .join(", ")}
                </Text>
              </div>

              <div
                sx={{
                  flexGrow: 1,
                  display: "grid",
                  gridTemplateColumns: "repeat(4, 2fr) 4fr",
                  gridTemplateRows: "1fr 1fr",
                }}
              >
                <div className="details-info-wrapper">
                  <div className="details-info">
                    <Icon name="address" size={15} fill="black-54" />
                    <Text sx={{ color: "black-54" }}>Start District</Text>
                    <Text sx={{ mt: 2, fontWeight: "bold" }}>
                      {
                        biddingPlanDetails.active_contract.start_location
                          .name_i18n.en
                      }
                    </Text>
                  </div>
                </div>

                <div className="details-info-wrapper">
                  <div className="details-info">
                    <Icon name="clock" size={15} fill="black-54" />
                    <Text sx={{ color: "black-54" }}>Start Time</Text>
                    <Text sx={{ mt: 2, fontWeight: "bold" }}>
                      {getTimeOfMilliseconds(
                        biddingPlanDetails.active_contract.start_time
                      )}
                    </Text>
                  </div>
                </div>

                <div className="details-info-wrapper">
                  <div className="details-info">
                    <Icon name="cancel" size={15} fill="black-54" />
                    <Text sx={{ color: "black-54" }}>Dead KM</Text>
                    <Text sx={{ mt: 2, fontWeight: "bold" }}>
                      {biddingPlanDetails.active_contract.dead_kilometers} KM
                    </Text>
                  </div>
                </div>

                <div className="details-info-wrapper">
                  <div className="details-info">
                    <Icon name="shift_direction" size={15} fill="black-54" />
                    <Text sx={{ color: "black-54" }}>Daily Rides</Text>
                    <Text sx={{ mt: 2, fontWeight: "bold" }}>
                      {`${
                        biddingPlanDetails.active_contract.number_of_rides
                      } ${pluralizeWord(
                        biddingPlanDetails.active_contract.number_of_rides,
                        "Ride"
                      )}`}
                    </Text>
                  </div>
                </div>

                <div className="details-info-wrapper">
                  <div className="details-info">
                    <Icon name="scheduling" size={15} fill="black-54" />
                    <Text sx={{ color: "black-54" }}>Weekdays</Text>
                    <div sx={{ mt: 2 }}>
                      <WeekdaysCheckbox
                        days={WEEKDAYS}
                        isEditable={false}
                        selectedDays={
                          biddingPlanDetails.active_contract.weekdays
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="details-info-wrapper">
                  <div className="details-info">
                    <Icon name="address" size={15} fill="black-54" />
                    <Text sx={{ color: "black-54" }}>End District</Text>
                    <Text sx={{ mt: 2, fontWeight: "bold" }}>
                      {
                        biddingPlanDetails.active_contract.end_location
                          .name_i18n.en
                      }
                    </Text>
                  </div>
                </div>

                <div className="details-info-wrapper">
                  <div className="details-info">
                    <Icon name="clock" size={15} fill="black-54" />
                    <Text sx={{ color: "black-54" }}>End Time</Text>
                    <Text sx={{ mt: 2, fontWeight: "bold" }}>
                      {getTimeOfMilliseconds(
                        biddingPlanDetails.active_contract.predicted_end_time
                      )}
                    </Text>
                  </div>
                </div>

                <div className="details-info-wrapper">
                  <div className="details-info">
                    <Icon name="station" size={15} fill="black-54" />
                    <Text sx={{ color: "black-54" }}>Ride KM</Text>
                    <Text sx={{ mt: 2, fontWeight: "bold" }}>
                      {biddingPlanDetails.active_contract.ride_kilometers} KM
                    </Text>
                  </div>
                </div>

                <div className="details-info-wrapper">
                  <div className="details-info">
                    <Icon name="bus_alt" size={15} fill="black-54" />
                    <Text sx={{ color: "black-54" }}>Vehicle Type</Text>
                    <Text sx={{ mt: 2, fontWeight: "bold" }}>
                      {biddingPlanDetails.active_contract.bus_type.name}
                    </Text>
                  </div>
                </div>
              </div>
            </DetailsContainer>

            <Text sx={{ fontSize: 2, mt: 4 }}>Campaign Details</Text>
            <DetailsContainer sx={{ mt: 2 }}>
              <div className="details-main-info">
                <div className="toggle-wrapper">
                  <Toggle
                    id="open-closed-for-bid"
                    labelOn="Open For Bid"
                    labelOff="Bid Closed"
                    checked={openForBid}
                    onOffLabelPosition={"start"}
                  />
                </div>

                {openForBid ? (
                  <Fragment>
                    <Text sx={{ mt: 3 }}>Min Price - Max Price</Text>
                    <Text sx={{ mt: 2, fontWeight: "bold" }}>
                      {`${biddingPlanDetails.active_contract.currency} ${
                        biddingPlanDetails.minimum_price / CURRENCY_CONVERT
                      } - ${biddingPlanDetails.active_contract.currency} ${
                        biddingPlanDetails.maximum_price / CURRENCY_CONVERT
                      }`}
                    </Text>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Text sx={{ mt: 3 }}>Price</Text>
                    <Text sx={{ mt: 2, fontWeight: "bold" }}>
                      {`${biddingPlanDetails.active_contract.currency} ${
                        biddingPlanDetails.fixed_price / CURRENCY_CONVERT
                      }`}
                    </Text>
                  </Fragment>
                )}
              </div>

              <FormikProvider value={formik}>
                <form
                  sx={{
                    flexGrow: 1,
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    gridTemplateRows: "1fr 1fr",
                  }}
                >
                  <div className="details-info-wrapper">
                    <div className="details-info">
                      <Icon name="clock" size={15} fill="black-54" />
                      <Text sx={{ color: "black-54" }}>Start Date Time</Text>
                      <EditFieldControls>
                        <EditFieldControls.View
                          isLoading={isUpdateOpenDateLoading}
                        >
                          <Text sx={{ mt: 2, fontWeight: "bold" }}>
                            {format(
                              "dd/MM/yy, hh:mm a",
                              new Date(biddingPlanDetails.open_date)
                            )}
                          </Text>
                        </EditFieldControls.View>
                        <EditFieldControls.Edit
                          submit={() =>
                            updateOpenDate({
                              id: biddingPlanId,
                              params: {
                                open_date:
                                  values["Start Date Time"].toISOString(),
                              },
                            })
                          }
                          submitDisabled={Boolean(errors["Start Date Time"])}
                        >
                          <DateTimePicker
                            initialDate={values["Start Date Time"]}
                            minDate={new Date()}
                            onChange={(date: Date) => {
                              setFieldTouched("Start Date Time");
                              setFieldValue("Start Date Time", date);
                            }}
                          />

                          {errors["Start Date Time"] &&
                            touched["Start Date Time"] && (
                              <div
                                sx={{
                                  marginTop: "6px",
                                  marginLeft: "5px",
                                  color: "crimson",
                                }}
                              >
                                {errors["Start Date Time"]}
                              </div>
                            )}
                        </EditFieldControls.Edit>
                      </EditFieldControls>
                    </div>
                  </div>

                  <div className="details-info-wrapper">
                    <div className="details-info">
                      <Icon name="invoice_filled" size={15} fill="black-54" />
                      <Text sx={{ color: "black-54" }}>Max Number of Bids</Text>
                      <EditFieldControls>
                        <EditFieldControls.View
                          isLoading={isUpdateMaxNumberOfBidsLoading}
                        >
                          <Text sx={{ mt: 2, fontWeight: "bold" }}>
                            {`${
                              biddingPlanDetails.maximum_bids_to_receive
                            } ${pluralizeWord(
                              biddingPlanDetails.maximum_bids_to_receive,
                              "Bid"
                            )}`}
                          </Text>
                        </EditFieldControls.View>
                        <EditFieldControls.Edit
                          submit={() =>
                            updateMaxNumberOfBids({
                              id: biddingPlanId,
                              params: {
                                maximum_bids_to_receive:
                                  values["Max Number of Bids"],
                              },
                            })
                          }
                          submitDisabled={Boolean(errors["Max Number of Bids"])}
                        >
                          <FormField
                            name="Max Number of Bids"
                            type="number"
                            min={biddingPlanBids?.total}
                            value={values["Max Number of Bids"]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                          />
                        </EditFieldControls.Edit>
                      </EditFieldControls>
                    </div>
                  </div>

                  <div className="details-info-wrapper">
                    <div className="details-info">
                      <Icon name="info" size={15} fill="black-54" />
                      <Text sx={{ color: "black-54" }}>State</Text>
                      <Text sx={{ mt: 2, fontWeight: "bold" }}>
                        {startCase(biddingPlanDetails.state)}
                      </Text>
                    </div>
                  </div>

                  <div className="details-info-wrapper">
                    <div className="details-info">
                      <Icon name="clock" size={15} fill="black-54" />
                      <Text sx={{ color: "black-54" }}>End Date Time</Text>
                      <EditFieldControls>
                        <EditFieldControls.View
                          isLoading={isUpdateCloseDateLoading}
                        >
                          <Text sx={{ mt: 2, fontWeight: "bold" }}>
                            {format(
                              "dd/MM/yy, hh:mm a",
                              new Date(biddingPlanDetails.close_date)
                            )}
                          </Text>
                        </EditFieldControls.View>
                        <EditFieldControls.Edit
                          submit={() =>
                            updateCloseDate({
                              id: biddingPlanId,
                              params: {
                                close_date:
                                  values["End Date Time"].toISOString(),
                              },
                            })
                          }
                          submitDisabled={Boolean(errors["End Date Time"])}
                        >
                          <DateTimePicker
                            initialDate={values["End Date Time"]}
                            minDate={values["Start Date Time"]}
                            onChange={(date: Date) => {
                              setFieldTouched("End Date Time");
                              setFieldValue("End Date Time", date);
                            }}
                          />

                          {errors["End Date Time"] && touched["End Date Time"] && (
                            <div
                              sx={{
                                marginTop: "6px",
                                marginLeft: "5px",
                                color: "crimson",
                              }}
                            >
                              {errors["End Date Time"]}
                            </div>
                          )}
                        </EditFieldControls.Edit>
                      </EditFieldControls>
                    </div>
                  </div>

                  <div className="details-info-wrapper">
                    <div className="details-info">
                      <Icon name="bus_alt" size={15} fill="black-54" />
                      <Text sx={{ color: "black-54" }}>Minimum Bus Year</Text>
                      <EditFieldControls>
                        <EditFieldControls.View
                          isLoading={isUpdateMinimumBusYearLoading}
                        >
                          <Text sx={{ mt: 2, fontWeight: "bold" }}>
                            {biddingPlanDetails.minimum_bus_year}
                          </Text>
                        </EditFieldControls.View>
                        <EditFieldControls.Edit
                          submit={() =>
                            updateMinimumBusYear({
                              id: biddingPlanId,
                              params: {
                                minimum_bus_year: values["Minimum Bus Year"],
                              },
                            })
                          }
                          submitDisabled={Boolean(errors["Minimum Bus Year"])}
                        >
                          <FormField
                            name="Minimum Bus Year"
                            type="number"
                            min={2000}
                            max={biddingPlanDetails.minimum_bus_year}
                            value={values["Minimum Bus Year"]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                          />
                        </EditFieldControls.Edit>
                      </EditFieldControls>
                    </div>
                  </div>
                </form>
              </FormikProvider>
            </DetailsContainer>
          </Fragment>
        )
      )}

      {biddingPlanBids && (
        <div sx={{ mt: 4 }}>
          <Heading>Bids Received</Heading>
          <div sx={{ mt: 2 }}>
            <Table
              useFullWidth
              useZebraStyles
              useSameSizeCells
              useTablePagination
              useControlledPagination
              pageSize={PAGE_SIZE}
              totalCount={biddingPlanBids.total}
              data={biddingPlanBids.hits}
              columns={biddingPlanBidsColumns(
                openAcceptBidPopup,
                openRejectionPopup
              )}
              onPageChange={(pageIndex) => setPage(pageIndex + 1)}
              pageIndex={page - 1}
              isLoading={isBiddingPlanBidsLoading}
              getTrProps={getTrProps}
              noDataComponent={
                <NoResults
                  css={{ width: 600, margin: "20px auto" }}
                  title="No Results Found"
                  body="There are no bids yet."
                />
              }
            />
          </div>
        </div>
      )}

      {isCancelCampaignPopupOpen && (
        <CancelCampaignPopup
          isOpen={isCancelCampaignPopupOpen}
          onDismiss={() => setIsCancelCampaignPopupOpen(false)}
          campaignId={biddingPlanId}
          state={biddingPlanDetails?.state}
          bids={biddingPlanBids?.total}
        />
      )}

      {isEndCampaignPopupOpen && (
        <EndCampaignPopup
          isOpen={isEndCampaignPopupOpen}
          onDismiss={() => setIsEndCampaignPopupOpen(false)}
          campaignId={biddingPlanId}
          state={biddingPlanDetails?.state}
        />
      )}

      {acceptancePopupData && (
        <BidAcceptancePopupData
          biddingPlanId={biddingPlanId}
          data={acceptancePopupData}
          onDismiss={() => setAcceptancePopupData(null)}
        />
      )}

      {rejectionPopupData && (
        <BidRejectionPopupData
          biddingPlanId={biddingPlanId}
          data={rejectionPopupData}
          onDismiss={() => setRejectionPopupData(null)}
        />
      )}

      {hideBiddingPopupData && (
        <HideBiddingPopup
          biddingPlanId={biddingPlanId}
          data={hideBiddingPopupData}
          onDismiss={() => setHideBiddingPopupData(null)}
        />
      )}
    </Fragment>
  );
};

export default BiddingDetails;
