/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text, Grid, Flex } from "theme-ui";
import { useState, FC } from "react";
import { useHistory } from "react-router-dom";
import Input from "@swvl/input";
import { Select, MultiSelect, MultiSelectAutocomplete } from "@swvl/select";
import isNil from "lodash/fp/isNil";
import Autocomplete from "@swvl/autocomplete";
import {
  ACTIVE_FILTER_OPTION,
  CAPTAIN_FILTER_OPTION,
  WEEKDAY_FILTER_OPTION,
  STATE_FILTER_OPTIONS,
  BIDDING_FILTER_OPTION,
} from "./constants";
import { OptionType } from "utils/types";
import { SearchWrapper } from "./styled";
import {
  useSearchPlans,
  SearchPlansModel,
} from "resources/Plan/useSearchPlans";
import {
  useSearchCities,
  SearchCitiesModel,
} from "resources/City/useSearchCities";
import { searchBustypes } from "resources/Bustype/useSearchBustypes";
import { searchDistricts } from "resources/District/useSearchDistricts";
import { searchLabels } from "resources/Plan/useSearchLabels";

import {
  useSearchCaptains,
  SearchCaptainsModel,
} from "resources/Captain/useSearchCaptain";
import { ListPlansParams } from "./constants";

type ListFiltersProp = {
  filters: ListPlansParams;
  updateFilters: (newFilters: Partial<ListPlansParams>) => void;
};

const ListFilters: FC<ListFiltersProp> = ({ filters, updateFilters }) => {
  const history = useHistory();
  // Plans Search
  const [searchPlan, setSearchPlan] = useState<string>();
  const { data: planSuggestions, isFetching: isFetchingPlan } =
    useSearchPlans(searchPlan);
  const renderSearchPlanOptions = (option: SearchPlansModel) => {
    return (
      <div sx={{ cursor: "pointer" }}>
        <Text>{option.nameI18n?.en}</Text>
      </div>
    );
  };

  // City
  const [searchCity, setSearchCity] = useState<string>();
  const { data: citySuggestions = [], isLoading: isLoadingCity } =
    useSearchCities(searchCity);
  const renderSearchCityOptions = (option: SearchCitiesModel) => {
    return (
      <div sx={{ cursor: "pointer" }}>
        <Text>{option.name}</Text>
      </div>
    );
  };

  // Captain
  const [searchCaptain, setSearchCaptain] = useState<string>();
  const { data: captainSuggestions, isLoading: isLoadingCaptain } =
    useSearchCaptains(searchCaptain);
  const renderSearchCaptainOptions = (option: SearchCaptainsModel) => {
    return (
      <div sx={{ cursor: "pointer" }}>
        <Text>{option.name}</Text>
      </div>
    );
  };
  const labelStyle = {
    variant: "text.body-small",
    fontWeight: "bold",
    mb: 1,
    ml: 2,
    display: "inline-block",
  };

  return (
    <section>
      <Grid columns={[3]} sx={{ mb: "12px" }}>
        <SearchWrapper data-test-id="search-using-plan-name-or-description">
          <label sx={labelStyle}>Search Plans</label>
          <Autocomplete
            suggestions={planSuggestions}
            isLoading={isFetchingPlan}
            onChangeQuery={(input) => {
              setSearchPlan(input);
            }}
            getOptionLabel={(option) => option.nameI18n?.en}
            renderOption={(option) => renderSearchPlanOptions(option)}
            getOptionKey={(option) => option.id}
            onSelect={(option) => {
              history.push(`/plans/${option.id}`);
            }}
          />
        </SearchWrapper>

        <SearchWrapper data-test-id="search-by-city">
          <label sx={labelStyle}>City</label>
          <Autocomplete
            suggestions={citySuggestions}
            placeholder="Search City"
            isLoading={isLoadingCity}
            onChangeQuery={(input) => {
              setSearchCity(input);
            }}
            initialValue={
              filters.city ? JSON.parse(sessionStorage.getItem("city")) : " "
            }
            getOptionLabel={(option) => option.name}
            renderOption={(option) => renderSearchCityOptions(option)}
            getOptionKey={(option) => option.id}
            onSelect={(option) => {
              option
                ? sessionStorage.setItem("city", JSON.stringify(option.name))
                : "";
              updateFilters({ city: option.id });
            }}
            onClear={() => {
              updateFilters({
                city: undefined,
              });
            }}
          />
        </SearchWrapper>
        <SearchWrapper data-test-id="search-by-captain">
          <label sx={labelStyle}>Captain</label>
          <Autocomplete
            suggestions={captainSuggestions}
            isLoading={isLoadingCaptain}
            onChangeQuery={(input) => {
              setSearchCaptain(input);
            }}
            initialValue={
              filters.captain
                ? JSON.parse(sessionStorage.getItem("captain"))
                : " "
            }
            getOptionLabel={(option) => option.name}
            renderOption={(option) => renderSearchCaptainOptions(option)}
            getOptionKey={(option) => option.id}
            onSelect={(option) => {
              option
                ? sessionStorage.setItem("captain", JSON.stringify(option.name))
                : "";
              updateFilters({ captain: option.id });
            }}
            onClear={() => {
              updateFilters({
                captain: undefined,
              });
            }}
          />
        </SearchWrapper>
        <div data-test-id="search-by-bustype">
          <MultiSelectAutocomplete
            label="Bus Type(s)"
            sx={{ maxWidth: "542px" }}
            name="bus-type"
            placeholder=""
            loadOptions={(query) => {
              return searchBustypes(query);
            }}
            value={
              filters.busTypes
                ? JSON.parse(sessionStorage.getItem("busTypes"))
                : null
            }
            handleChange={(options: OptionType[]) => {
              const busTypesIds = [];
              options?.forEach((option) => {
                busTypesIds.push(option.value);
              });
              options
                ? sessionStorage.setItem("busTypes", JSON.stringify(options))
                : null;
              updateFilters({
                busTypes: busTypesIds,
              });
            }}
          />
        </div>

        <div data-test-id="search-by-start-location">
          <MultiSelectAutocomplete
            label="Start Locations(s)"
            sx={{ maxWidth: "542px" }}
            name="start-location"
            placeholder=""
            loadOptions={(query) => {
              return searchDistricts(query);
            }}
            value={
              filters.firstDistricts
                ? JSON.parse(sessionStorage.getItem("firstDistricts"))
                : null
            }
            handleChange={(options: OptionType[]) => {
              const firstDistrictIds = [];
              options?.forEach((option) => {
                firstDistrictIds.push(option.value);
              });
              options
                ? sessionStorage.setItem(
                    "firstDistricts",
                    JSON.stringify(options)
                  )
                : null;
              updateFilters({
                firstDistricts: firstDistrictIds,
              });
            }}
          />
        </div>
        <div data-test-id="search-by-end-location">
          <MultiSelectAutocomplete
            label={"End Locations(s)"}
            sx={{ maxWidth: "542px" }}
            name="end-location"
            placeholder=""
            loadOptions={(query) => {
              return searchDistricts(query);
            }}
            value={
              filters.lastDistricts
                ? JSON.parse(sessionStorage.getItem("lastDistricts"))
                : null
            }
            handleChange={(options: OptionType[]) => {
              const lastDistrictIds = [];
              options?.forEach((option) => {
                lastDistrictIds.push(option.value);
              });
              options
                ? sessionStorage.setItem(
                    "lastDistricts",
                    JSON.stringify(options)
                  )
                : null;
              updateFilters({
                lastDistricts: lastDistrictIds,
              });
            }}
          />
        </div>
        <div data-test-id="search-by-labels">
          <MultiSelectAutocomplete
            label="Labels"
            sx={{ maxWidth: "542px" }}
            name="labels"
            placeholder=""
            loadOptions={(query) => {
              return searchLabels(query);
            }}
            value={
              (filters.labels?.map((label) => ({
                label: label,
                value: label,
              })) as unknown as OptionType) || null
            }
            handleChange={(options) => {
              updateFilters({
                labels: options?.map(({ value }) => value),
              });
            }}
          />
        </div>
        <div data-test-id="search-by-plan-status">
          <Select
            label="Plan Status"
            height="small"
            options={ACTIVE_FILTER_OPTION}
            aria-label="status"
            value={
              ACTIVE_FILTER_OPTION.find(
                ({ value }) => filters.active === value
              ) || null
            }
            placeholder=""
            name="active"
            handleChange={(option: OptionType) => {
              isNil(option.value)
                ? updateFilters({ active: undefined })
                : updateFilters({ active: option.value });
            }}
          />
        </div>

        <div data-test-id="search-by-plan-assigned-to-captain">
          <Select
            label="Plan Assigned to captain"
            height="small"
            options={CAPTAIN_FILTER_OPTION}
            aria-label="assigned"
            value={
              CAPTAIN_FILTER_OPTION.find(
                ({ value }) => filters.assigned === value
              ) || null
            }
            placeholder=""
            name="assigned"
            handleChange={(option: OptionType) => {
              isNil(option.value)
                ? updateFilters({ assigned: undefined })
                : updateFilters({ assigned: option.value });
            }}
          />
        </div>
        <Grid columns={[2]}>
          <Input
            label="Plan Ride KMs min"
            role="searchbox"
            data-test-id="search-by-plan-ride-km-min"
            variant="plain"
            height="small"
            type="number"
            value={filters.minRideKMs || ""}
            sx={{
              backgroundColor: "whitesmoke",
              fontSize: "15px",
            }}
            onChange={(e) => {
              updateFilters({ minRideKMs: e.target.value });
            }}
          />
          <Input
            label="Plan Ride KMs max"
            role="searchbox"
            data-test-id="search-by-plan-ride-km-max"
            variant="plain"
            height="small"
            type="number"
            value={filters.maxRideKMs || ""}
            sx={{
              backgroundColor: "whitesmoke",
              fontSize: "15px",
            }}
            onChange={(e) => {
              updateFilters({ maxRideKMs: e.target.value });
            }}
          />
        </Grid>
        <div data-test-id="search-by-plan-weekdays">
          <MultiSelect
            label="Plan Weekdays"
            height="small"
            options={WEEKDAY_FILTER_OPTION}
            aria-label="weekdays"
            placeholder=""
            name="weekdays"
            value={
              (WEEKDAY_FILTER_OPTION.filter(({ value }) =>
                filters.weekdays?.includes(value)
              ) as unknown as OptionType) || null
            }
            handleChange={(options) => {
              updateFilters({
                weekdays: options?.map(({ value }) => value),
              });
            }}
          />
        </div>
        <div data-test-id="search-by-plan-state">
          <MultiSelect
            label="Plan States"
            height="small"
            options={STATE_FILTER_OPTIONS}
            aria-label="states"
            placeholder=""
            name="state"
            value={
              (STATE_FILTER_OPTIONS.filter(({ value }) =>
                filters.state?.includes(value)
              ) as unknown as OptionType) || null
            }
            handleChange={(options) => {
              updateFilters({
                state: options?.map(({ value }) => value),
              });
            }}
          />
        </div>
      </Grid>
      <Flex
        sx={{
          borderTop: "1px solid #EBEBEB",
          justifyContent: "flex-end",
          p: "8px 0",
        }}
      >
        <div
          sx={{ flex: "0.2 0.25 auto" }}
          data-test-id="search-by-plan-assigned-to-campagin"
        >
          <Select
            label={null}
            height="small"
            options={BIDDING_FILTER_OPTION}
            aria-label="isOnBidding"
            value={
              BIDDING_FILTER_OPTION.find(
                ({ value }) => filters.isOnBidding === value
              ) || null
            }
            placeholder="Bidding Campaign"
            name="isOnBidding"
            handleChange={(option: OptionType) =>
              updateFilters({ isOnBidding: option.value })
            }
          />
        </div>
      </Flex>
    </section>
  );
};

export default ListFilters;
