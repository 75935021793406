/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import { FC, useEffect } from "react";
import forEach from "lodash/fp/forEach";
import { useFormik, FormikProvider } from "formik";
import { string, object } from "yup";
import Spinner from "@swvl/spinner";
import Button from "@swvl/button";
import Icon from "@swvl/icon";
import swvlLogo from "assets/swvl-logo.svg";
import Field from "components/FormField";
import { useLogin } from "resources/Auth/useLogin";
import { Form, ErrorBanner } from "./styled";

export const loginSchema = object()
  .shape({
    email: string()
      .required("Email is required")
      .email("Please enter a valid email")
      .defined(),
    password: string().required("Password is required"),
  })
  .defined();

const LoginForm: FC = () => {
  const {
    mutate: loginUser,
    isError,
    status,
    validationErrorMessage,
  } = useLogin();
  const isLoading = status === "loading";

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      const email = values.email.trim();
      const password = values.password.trim();
      loginUser({ email, password });
    },
  });

  useEffect(() => {
    if (isError) {
      forEach<string>((key) => {
        formik.setFieldError(key, " ");
      })(Object.keys(formik.values));
    }
  }, [isError]);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <img src={swvlLogo} />
        <div>
          <Text
            variant="title-large"
            sx={{
              fontWeight: "bold",
            }}
          >
            Sign Into Your Account
          </Text>
          <Text
            variant="body-small"
            sx={{
              color: "opacity.black-54",
              marginTop: "10px",
              display: "block",
            }}
          >
            Welcome! Please fill in email address and password to sign into your
            account
          </Text>
        </div>
        {isError ? (
          <ErrorBanner data-test-id="login-failure-banner">
            <div>
              <Icon name="error" size={24} />
            </div>
            <Text
              variant="body-small"
              sx={{
                color: "crimson",
                marginLeft: "1rem",
                lineHeight: "15px",
              }}
            >
              {validationErrorMessage}
            </Text>
          </ErrorBanner>
        ) : null}
        <div id="fields">
          <Field
            label="Email Address"
            id="user-email"
            name="email"
            placeholder="Add Email Address"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            required
          />
          <Field
            label="Password"
            id="password"
            name="password"
            placeholder="Add Password"
            type="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            required
          />
        </div>
        {isLoading ? (
          <Spinner color="primary" />
        ) : (
          <Button
            sx={{
              width: "110px",
              height: "38px",
            }}
            variant="primary"
            icon={<Icon name="right_arrow" size={15} fill="currentcolor" />}
            iconPosition="right"
            type="submit"
            disabled={!formik.isValid || !formik.dirty}
          >
            <span sx={{ fontWeight: "bold", fontSize: "15px" }}>Sign In</span>
          </Button>
        )}
      </Form>
    </FormikProvider>
  );
};

export default LoginForm;
