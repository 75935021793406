/** @jsxRuntime classic */
/** @jsx jsx */
import { Fragment, useRef, useState, useMemo } from "react";
import { jsx, Text } from "theme-ui";
import debounce from "lodash/debounce";
import { Select, MultiSelect, MultiSelectAutocomplete } from "@swvl/select";
import Icon from "@swvl/icon";
import DatePicker from "@swvl/date-picker";
import Autocomplete from "@swvl/autocomplete";
import Button from "@swvl/button";
import Input from "@swvl/input";
import set from "date-fns/fp/set";

import { FilterWrapper, disabled } from "./styled";

import {
  useSearchBiddingCities,
  SearchCitiesModel,
} from "resources/PlanSelling/useSearchBiddingCity";
import { searchStartLocation } from "resources/PlanSelling/useSearchBiddingStartLocation";
import { searchEndLocation } from "resources/PlanSelling/useSearchBiddingEndLocation";
import { searchBusType } from "resources/PlanSelling/useSearchBiddingBusType";
import { ListBiddingParams } from "resources/PlanSelling/useListBidding";
import { STATE_OPTIONS, BOOLEAN_OPTIONS, TYPE_OPTIONS } from "./constants";
import { OptionType } from "utils/types";
import { toLocaleDate } from "utils";

type ListFiltersProp = {
  filters: ListBiddingParams;
  updateFilters: (newFilters: Partial<ListBiddingParams>) => void;
};

const renderSearchCityOptions = (option: SearchCitiesModel) => {
  return (
    <div sx={{ cursor: "pointer" }}>
      <Text>{option.name}</Text>
    </div>
  );
};

const setStartOfDay = set({ hours: 0, minutes: 0 });
const setEndOfDay = set({ hours: 23, minutes: 59 });

function ListFilters({ filters, updateFilters }: ListFiltersProp): JSX.Element {
  const dateRef = useRef(null);
  const [startDate, setStartDate] = useState(
    toLocaleDate(filters["open_date[from]"] || filters["close_date[from]"])
  );
  const [endDate, setEndDate] = useState(
    toLocaleDate(filters["open_date[to]"] || filters["close_date[to]"])
  );
  const [planId, setPlanId] = useState(filters.plan_id);

  const updateFiltersDebounced = useMemo(
    () =>
      debounce((value: string) => {
        updateFilters({
          plan_id: value,
        });
      }, 200),
    [updateFilters]
  );
  const {
    mutate: queryCity,
    data: citySuggestions = [],
    isLoading: isLoadingCity,
  } = useSearchBiddingCities();

  function onDateChange(dates: [Date, Date]) {
    const [datePickerStartDate, datePickerEndDate] = dates;

    setStartDate(datePickerStartDate);
    setEndDate(datePickerEndDate);

    updateFilters({
      "open_date[from]": setStartOfDay(datePickerStartDate).toISOString(),
      "close_date[from]": setStartOfDay(datePickerStartDate).toISOString(),

      "open_date[to]": datePickerEndDate
        ? setEndOfDay(datePickerEndDate).toISOString()
        : undefined,
      "close_date[to]": datePickerEndDate
        ? setEndOfDay(datePickerEndDate).toISOString()
        : undefined,
    });
  }

  return (
    <Fragment>
      <FilterWrapper>
        <div>
          <label
            sx={{
              variant: "text.body-small",
              fontWeight: "bold",
              mb: 2,
              ml: 2,
              display: "inline-block",
            }}
          >
            Search City
          </label>

          <Autocomplete
            suggestions={citySuggestions}
            placeholder="Search City"
            isLoading={isLoadingCity}
            initialValue={filters.city}
            onChangeQuery={queryCity}
            getOptionLabel={(option) => option.name}
            renderOption={(option) => renderSearchCityOptions(option)}
            getOptionKey={(option) => option.name}
            onSelect={(option) => {
              updateFilters({ city: option.name });
            }}
            onClear={() => {
              updateFilters({
                city: undefined,
                start_location: undefined,
                end_location: undefined,
                bus_type: undefined,
              });
            }}
          />
        </div>

        <div css={!filters.city ? disabled : ""}>
          <MultiSelectAutocomplete
            label="Start Location(s)"
            name="start-location"
            placeholder="Pick Start Location(s)"
            isDisabled={!filters.city}
            loadOptions={(query) => searchStartLocation(query, filters.city)}
            value={
              filters.start_location?.map((location) => ({
                label: location,
                value: location,
              })) as unknown as {
                label: string;
                value: string;
              }
            }
            handleChange={(options: OptionType[]) => {
              updateFilters({
                start_location: options?.map(({ value }) => value),
              });
            }}
          />
        </div>

        <div css={!filters.city ? disabled : ""}>
          <MultiSelectAutocomplete
            label="End Location(s)"
            name="end-location"
            placeholder="Pick End Location(s)"
            isDisabled={!filters.city}
            loadOptions={(query) => searchEndLocation(query, filters.city)}
            value={
              filters.end_location?.map((location) => ({
                label: location,
                value: location,
              })) as unknown as {
                label: string;
                value: string;
              }
            }
            handleChange={(options: OptionType[]) => {
              updateFilters({
                end_location: options?.map(({ value }) => value),
              });
            }}
          />
        </div>
        <div css={!filters.city ? disabled : ""}>
          <MultiSelectAutocomplete
            label="Bus Type(s)"
            name="bus-type"
            placeholder="Select Bus Type(s)"
            isDisabled={!filters.city}
            loadOptions={(query) => searchBusType(query, filters.city)}
            value={
              filters.bus_type?.map((type) => ({
                label: type,
                value: type,
              })) as unknown as {
                label: string;
                value: string;
              }
            }
            handleChange={(options: OptionType[]) => {
              updateFilters({
                bus_type: options?.map(({ value }) => value),
              });
            }}
          />
        </div>
      </FilterWrapper>

      <FilterWrapper>
        <div>
          <DatePicker
            ref={dateRef}
            placeholder={
              !filters["open_date[from]"] &&
              !filters["close_date[from]"] &&
              "Pick Start / End Date"
            }
            label="Start / End Date"
            minDate={null}
            startDate={startDate}
            endDate={endDate}
            selected={startDate}
            onChange={onDateChange}
          >
            <div>
              <Button
                variant="primary"
                onClick={() => dateRef.current.setOpen(false)}
              >
                close
              </Button>
            </div>
          </DatePicker>
        </div>

        <div>
          <Select
            label="Type"
            name="type"
            placeholder="Select Type"
            value={TYPE_OPTIONS.find(({ value }) => filters.type === value)}
            startComponent={
              <Icon name="address" size={20} fill="rgba(0, 0, 0, 0.54)" />
            }
            options={TYPE_OPTIONS}
            handleChange={(option: OptionType) => {
              updateFilters({ type: option.value });
            }}
          />
        </div>

        <div>
          <MultiSelect
            label="State"
            name="state"
            placeholder="Select State"
            startComponent={
              <Icon name="address" size={20} fill="rgba(0, 0, 0, 0.54)" />
            }
            options={STATE_OPTIONS}
            value={
              STATE_OPTIONS.filter(({ value }) =>
                filters.state?.includes(value)
              ) as unknown as {
                label: string;
                value: string;
              }
            }
            handleChange={(options: OptionType[]) => {
              updateFilters({
                state: options?.map(({ value }) => value),
              });
            }}
          />
        </div>

        <div>
          <Select
            label="Max Bids Reached"
            startComponent={
              <Icon name="address" size={20} fill="rgba(0, 0, 0, 0.54)" />
            }
            name="Max Bids Reached"
            value={BOOLEAN_OPTIONS.find(
              ({ value }) => filters.maximum_bids_reached === value
            )}
            options={BOOLEAN_OPTIONS}
            handleChange={(option) => {
              updateFilters({ maximum_bids_reached: option.value });
            }}
          />
        </div>
      </FilterWrapper>

      <FilterWrapper>
        <div>
          <Select
            label="Has Active Bids"
            startComponent={
              <Icon name="completed" size={20} fill="rgba(0, 0, 0, 0.54)" />
            }
            name="Has Active Bids"
            value={BOOLEAN_OPTIONS.find(
              ({ value }) => filters.has_active_bids === value
            )}
            options={BOOLEAN_OPTIONS}
            handleChange={(option) => {
              updateFilters({ has_active_bids: option.value });
            }}
          />
        </div>
        <div>
          <Input
            type="text"
            variant="plain"
            label="Plan Id"
            name="Plan Id"
            placeholder="Search by plan id"
            value={planId}
            startEnhancer={
              <Icon name="search" size={20} fill="rgba(0, 0, 0, 0.54)" />
            }
            endEnhancer={
              planId ? (
                <div
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setPlanId("");
                    updateFilters({ plan_id: null });
                  }}
                >
                  <Icon name="cancel" size={20} fill="rgba(0, 0, 0, 0.54)" />
                </div>
              ) : (
                ""
              )
            }
            onChange={(e) => {
              setPlanId(e.target.value);
              updateFiltersDebounced(e.target.value ? e.target.value : null);
            }}
          />
        </div>
      </FilterWrapper>
    </Fragment>
  );
}

export default ListFilters;
