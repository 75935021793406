import { useQuery, UseQueryResult } from "react-query";
import { AxiosResponse } from "axios";
import apiInstance, { checkIfRequestIntrceptorUsed } from "api";

type ActiveContract = {
  _id: string;
  cities: BusType[];
  currency: string;
  ride_kilometers: number;
  dead_kilometers: number;
  weekdays: number[];
  start_location: Location;
  end_location: Location;
  start_time: number;
  end_time: number;
  duration_milliseconds: number;
  number_of_rides: number;
  bus_type: BusType;
  version: string;
};

type BusType = {
  _id: string;
  name: string;
};

type Location = {
  coordinates: Coordinates;
  district_name: string;
  city: BusType;
  name_i18n: { en: string; [key: string]: string };
};

type Coordinates = {
  lat: number;
  lng: number;
};

type ListBiddingPlansResponse = {
  total: number;
  hits: Hit[];
};

export type Hit = {
  _id: string;
  start_time: string;
  end_time: string;
  open_date: string;
  close_date: string;
  minimum_bus_year: string;
  hidden_flag: boolean;
  state: string;
  type: string;
  fixed_price: number;
  maximum_price: number;
  minimum_price: number;
  maximum_bids_to_receive: number;
  minimum_placed_bid_amount: number;
  total_placed_bids: number;
  active_contract: ActiveContract;
};

export type ListBiddingParams = {
  page: number;
  city: string;
  start_location: string[];
  end_location: string[];
  bus_type: string[];
  "open_date[from]": string;
  "open_date[to]": string;
  "close_date[from]": string;
  "close_date[to]": string;
  type: string;
  state: string[];
  maximum_bids_reached: string;
  has_active_bids: string;
  plan_id: string;
};

const getAllBiddingPlans = async (params: ListBiddingParams) => {
  const response = await apiInstance.get<
    AxiosResponse,
    ListBiddingPlansResponse
  >("bidding_campaigns", {
    params,
  });

  return response;
};

const useListBidding = (
  params: ListBiddingParams
): UseQueryResult<ListBiddingPlansResponse, unknown> => {
  const query = useQuery(
    ["bidding_plans", params],
    () => getAllBiddingPlans(params),
    {
      enabled: checkIfRequestIntrceptorUsed(),
      keepPreviousData: true,
    }
  );
  return query;
};

export { useListBidding };
