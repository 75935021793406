import { useMutation } from "react-query";
import apiInstance from "api";
import { showAlert } from "@swvl/alert";
import { useHistory } from "react-router-dom";
import useLocalStorage from "utils/useLocalStorage";
import { useLocation } from "react-router";
import queryString from "query-string";

// Types
export type AuthenticationModel = {
  token?: string;
  expiryDate?: string;
  refreshToken?: string;
  jwt?: string;
  role?: string;
  owner?: string;
  error?: string;
};

interface LoginRequest {
  email: string;
  password: string;
}

interface LoginResponse {
  __v: number;
  updatedAt: string;
  createdAt: string;
  owner: string;
  type: string;
  _id: string;
  expiry_date: string;
  refresh_token: string;
  token: string;
  is_expired: boolean;
  id: string;
  jwt: string;
  role: string;
}

type LoginResponseTransform = Omit<AuthenticationModel, "error">;

// Transforms
const transformResponse = (response: unknown): LoginResponseTransform => {
  const {
    token,
    expiry_date: expiryDate,
    jwt,
    refresh_token: refreshToken,
    role,
    owner,
  } = response as LoginResponse;
  return { token, expiryDate, jwt, refreshToken, role, owner };
};

// Initial State
const initialState: AuthenticationModel = {
  token: null,
  expiryDate: null,
  refreshToken: null,
  jwt: null,
  role: null,
  error: null,
};

// Resources
const loginUser = async (data: LoginRequest) => {
  const response = await apiInstance.post<LoginRequest, LoginResponse>(
    "sign_in",
    data
  );
  return transformResponse(response);
};

const useLogin = () => {
  const location = useLocation();
  const { setValue: setAuthenticationData } =
    useLocalStorage<AuthenticationModel>("ls.authdata", initialState);

  const history = useHistory();

  const mutation = useMutation(loginUser, {
    mutationKey: "user/login",
    onSuccess: (data) => {
      setAuthenticationData(data);
      const searchParams = queryString.parse(location.search, {
        arrayFormat: "bracket",
      });
      if (searchParams.back) {
        const back = decodeURIComponent(searchParams.back as string);
        history.push(back);
      } else {
        history.push("/");
      }
      showAlert({
        message: "Login successfully",
        type: "success",
        id: "alert-success",
      });
    },
    onError: undefined,
  });
  return {
    ...mutation,
    validationErrorMessage: "Invalid email address or password",
  };
};

export { useLogin, initialState };
