import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";

export default function useFilters<T extends Record<string, unknown>>(
  initialValue: Partial<T>
): [T, (newFilters: Partial<T>) => void] {
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = queryString.parse(search, {
    arrayFormat: "bracket",
  });
  const mergedFilters = {
    ...initialValue,
    ...searchParams,
  };
  const [filters, setFilters] = useState<T>(mergedFilters as T);

  const updateFilters = (newFilters: Partial<T>) => {
    const allFilters = {
      ...filters,
      ...newFilters,
    };
    setFilters(allFilters);

    const allFiltersString = queryString.stringify(allFilters, {
      arrayFormat: "bracket",
    });
    history.push({ search: allFiltersString });
  };

  useEffect(() => {
    const searchParams = queryString.parse(search, {
      arrayFormat: "bracket",
    });
    if (JSON.stringify(searchParams) !== JSON.stringify(filters)) {
      setFilters(searchParams as T);
    }
  }, [search]);

  useEffect(() => {
    const allFiltersString = queryString.stringify(filters, {
      arrayFormat: "bracket",
    });
    history.push({ search: allFiltersString });
  }, []);

  return [filters, updateFilters];
}
