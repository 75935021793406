import { useMutation, useQueryClient } from "react-query";
import apiInstance from "api";
import { showAlert } from "@swvl/alert";

type BiddingPlanParams = {
  id: string;
  params: {
    minimum_bus_year: number;
  };
};
const updateBiddingMinimumBusYear = async ({
  id,
  params,
}: BiddingPlanParams) => {
  const response = await apiInstance.patch(
    `bidding_campaigns/${id}/minimum_bus_year`,
    params
  );
  return response;
};

const useUpdateBiddingMinimumBusYear = (id) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(updateBiddingMinimumBusYear, {
    onSuccess: () => {
      queryClient.invalidateQueries(["bidding_campaigns", id]);
      showAlert({
        message: "Minimum Bus Year is updated successfully",
        type: "success",
        id: "alert-success",
      });
    },
  });
  return mutation;
};

export { useUpdateBiddingMinimumBusYear };
