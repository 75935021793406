/** @jsxRuntime classic */
/** @jsx jsx */
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import CreatableSelect from "react-select/creatable";
import { FC, useState, useCallback, useEffect, Fragment } from "react";
import { jsx, Grid, Box, Text } from "theme-ui";
import { format } from "date-fns";
import { useFormik, FormikProvider } from "formik";
import { array, boolean, number, object, string } from "yup";
import { WeekdaysCheckbox } from "@swvl/weekdays";
import Autocomplete from "@swvl/autocomplete";
import { Button } from "@swvl/button";
import { Select } from "@swvl/select";
import Toggle from "@swvl/toggle";
import Input from "@swvl/input";
import Icon from "@swvl/icon";
import first from "lodash/fp/first";
import { WEEKDAYS, CURRENCY_CONVERT, DATE_DASH_FORMAT } from "utils";
import PageHeader from "components/PageHeader";
import {
  useCreatePlan,
  CreatePlanFormData,
} from "resources/Plan/useCreatePlan";

import { useCityDetails } from "resources/City/useCityDetails";
import {
  useSearchCities,
  SearchCitiesModel,
} from "resources/City/useSearchCities";
import {
  useSearchBustypes,
  SearchBustypesModel,
} from "resources/Bustype/useSearchBustypes";

import PlanStartDatePicker from "./PlanStartDatePicker";

import {
  Label,
  ToggleWrapper,
  AutocompleteWrapper,
  CreatableItemsWrapper,
  TabsWrapper,
  ClearableDateWrapper,
} from "./styled";
import { PLAN_TYPES, SelectOption, PlanPrice } from "./constants";

import PlanConflictPopup from "./PlanConflictPopup";
import GotoBiddingCreationPopup from "./GotoBiddingCreationPopup";
export const CreatePlan: FC = () => {
  const [planName, setPlanName] = useState<{ [key: string]: string }>();
  const [planDescription, setPlanDescription] =
    useState<{ [key: string]: string }>();
  const {
    mutateAsync: createPlan,
    data: planCreationSuccess,
    isSuccess: isPlanCreaionSuccess,
    isLoading: isLoadingPlanCreation,
  } = useCreatePlan();

  // City
  const [searchCity, setSearchCity] = useState<string>();
  const [cityCurrency, setCityCurrency] = useState<string>();
  const [cityId, setCityId] = useState<string>();

  const { data: cityDetails } = useCityDetails(cityId);

  const { data: citySuggestions, isFetching: isFetchingCity } =
    useSearchCities(searchCity);
  const renderSearchCityOptions = useCallback(
    (option: SearchCitiesModel) => {
      return (
        <div sx={{ cursor: "pointer" }}>
          <Text>{option.name}</Text>
        </div>
      );
    },
    [citySuggestions]
  );

  // Bustype
  const [searchBustype, setSearchBustype] = useState<string>();
  const { data: bustypeSuggestions, isFetching: isFetchingBustype } =
    useSearchBustypes(searchBustype);
  const renderSearchBustypeOptions = useCallback(
    (option: SearchBustypesModel) => {
      return (
        <div sx={{ cursor: "pointer" }}>
          <Text>{option.name}</Text>
        </div>
      );
    },
    [bustypeSuggestions]
  );

  type FormData = {
    weekdays: number[];
    nameI18n: { en: string };
    descriptionI18n: { [key: string]: string };
    schedules: SelectOption[];
    labels: SelectOption[];
    busType: string;
    type: "schedule" | "time";
    city: string;
    price: number;
    active: boolean;
    startDate?: string | null;
  };

  const formInitialValues: FormData = {
    weekdays: [],
    nameI18n: { en: "" },
    descriptionI18n: {},
    city: "",
    busType: "",
    active: false,
    type: "schedule",
    labels: [],
    schedules: [],
    price: undefined,
  };

  const formSchema = object()
    .shape({
      weekdays: array().min(1, "You must pick at least one day"),
      nameI18n: object().shape({
        en: string().required(),
        ar: cityDetails?.languages.includes("ar")
          ? string().required()
          : string(),
        ur: cityDetails?.languages.includes("ur")
          ? string().required()
          : string(),
      }),
      descriptionI18n: object(),
      city: string().required(),
      busType: string().required(),
      type: string().required(),
      labels: array(),
      schedules: array().required().min(1),
      active: boolean(),
      price: number().required().min(0),
    })
    .defined();

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: formSchema,
    onSubmit: (data) => {
      const price: PlanPrice = {
        amount: data.price * CURRENCY_CONVERT,
        currency: cityCurrency,
      };
      const labels = data.labels.map((label: SelectOption) => {
        return label.value;
      });
      const schedules = data.schedules.map((schedule: SelectOption) => {
        return schedule.value;
      });

      const startDate =
        planStartDate && format(planStartDate, DATE_DASH_FORMAT);

      const requestBody: CreatePlanFormData = {
        ...data,
        price,
        labels,
        schedules,
        nameI18n: planName,
        descriptionI18n: planDescription,
        ...(planStartDate && { startDate }),
      };
      createPlan(requestBody).catch((error) => {
        if (
          error?.data?.captainConflict &&
          Object.keys(error?.data?.captainConflict).length
        ) {
          setConflictData(error.data.captainConflict);
          setOpenPlanPopupConflict(true);
        }
      });
    },
  });

  const { setFieldValue, setFieldTouched, values } = formik;

  // Start Date
  const [planStartDate, setPlanStartDate] = useState<Date>();
  function onPlanStartDateChange(selectedDate: Date) {
    setPlanStartDate(selectedDate);
  }

  // Conflict Popup
  const [openPlanPopupConflict, setOpenPlanPopupConflict] = useState(false);
  const [conflictData, setConflictData] = useState(null);

  // Goto bidding creation popup
  const [openGotoBiddingCreationPopup, setOpenGotoBiddingCreationPopup] =
    useState(false);

  useEffect(() => {
    if (isPlanCreaionSuccess) {
      setOpenGotoBiddingCreationPopup(true);
    }
  }, [isPlanCreaionSuccess]);

  return (
    <Fragment>
      <PageHeader title="Create Plan" />
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Grid columns={[3]}>
            <Box>
              {/* City */}
              <AutocompleteWrapper data-test-id="search-city">
                <Label htmlFor="city">
                  {" "}
                  City
                  <span
                    sx={{
                      ml: 1,
                      color: "crimson",
                    }}
                  >
                    *
                  </span>
                </Label>
                <Autocomplete
                  sx={{ pb: "20px", minHeight: "42px" }}
                  suggestions={citySuggestions}
                  placeholder="Search City"
                  isLoading={isFetchingCity}
                  onChangeQuery={(input) => setSearchCity(input)}
                  getOptionLabel={(option) => option.name}
                  renderOption={(option) => renderSearchCityOptions(option)}
                  getOptionKey={(option) => option.id}
                  onSelect={(option) => {
                    setCityCurrency(option.currency);
                    setCityId(option.id);
                    setFieldValue("city", option.id, true);
                    setFieldTouched("city", true);
                    if (planName && planName.en) {
                      setPlanName({
                        en: planName.en,
                      });
                    }
                    if (planDescription && planDescription.en) {
                      setPlanDescription({
                        en: planDescription.en,
                      });
                    }
                  }}
                />
              </AutocompleteWrapper>
              <TabsWrapper sx={{ mb: "20px" }}>
                <Tabs>
                  <TabList>
                    {!cityDetails?.languages?.includes("en") ? (
                      <Tab data-test-id="name_en">
                        Name EN
                        <span
                          sx={{
                            ml: 1,
                            color: "crimson",
                          }}
                        >
                          *
                        </span>
                      </Tab>
                    ) : null}
                    {cityDetails?.languages?.map((lang: string, index) => (
                      <Tab key={index} data-test-id={"name_" + lang}>
                        Name{" "}
                        <span sx={{ textTransform: "uppercase" }}>{lang}</span>
                        <span
                          sx={{
                            ml: 1,
                            color: "crimson",
                          }}
                        >
                          *
                        </span>
                      </Tab>
                    ))}
                  </TabList>

                  {!cityDetails?.languages?.includes("en") ? (
                    <TabPanel>
                      <Input
                        placeholder="Add Plan Name"
                        data-test-id="name_en_input"
                        type="text"
                        variant="plain"
                        value={planName?.en}
                        onChange={(e) => {
                          setPlanName({ ...planName, en: e.target.value });
                          setFieldValue("nameI18n", planName, true);
                          setFieldTouched("nameI18n", true);
                        }}
                        required
                      />
                    </TabPanel>
                  ) : null}

                  {cityDetails?.languages?.map((lang, index) => (
                    <TabPanel key={index}>
                      <Input
                        placeholder="Add Plan Name"
                        data-test-id={"name_" + lang + "_input"}
                        type="text"
                        variant="plain"
                        value={planName?.[lang]}
                        onChange={(e) => {
                          setPlanName({ ...planName, [lang]: e.target.value });
                          setFieldValue("nameI18n", planName, true);
                          setFieldTouched("nameI18n", true);
                        }}
                        required
                      />
                    </TabPanel>
                  ))}
                </Tabs>
              </TabsWrapper>
              {/* Description */}
              <TabsWrapper sx={{ mb: "20px" }}>
                <Tabs>
                  <TabList>
                    {!cityDetails?.languages?.includes("en") ? (
                      <Tab data-test-id="description_en">Description EN</Tab>
                    ) : null}
                    {cityDetails?.languages?.map((lang: string, index) => (
                      <Tab key={index} data-test-id={"description_" + lang}>
                        Description{" "}
                        <span sx={{ textTransform: "uppercase" }}>{lang}</span>
                      </Tab>
                    ))}
                  </TabList>

                  {!cityDetails?.languages?.includes("en") ? (
                    <TabPanel>
                      <Input
                        placeholder="Add Plan Description"
                        data-test-id="description_en_input"
                        type="text"
                        variant="plain"
                        value={planDescription?.en}
                        onChange={(e) => {
                          setPlanDescription({
                            ...planDescription,
                            en: e.target.value,
                          });
                          setFieldValue("descriptionI18n", planDescription);
                        }}
                      />
                    </TabPanel>
                  ) : null}

                  {cityDetails?.languages?.map((lang, index) => (
                    <TabPanel key={index}>
                      <Input
                        placeholder="Add Plan Description"
                        data-test-id={"description_" + lang + "_input"}
                        type="text"
                        variant="plain"
                        value={planDescription?.[lang]}
                        onChange={(e) => {
                          setPlanDescription({
                            ...planDescription,
                            [lang]: e.target.value,
                          });
                          setFieldValue("descriptionI18n", planDescription);
                        }}
                      />
                    </TabPanel>
                  ))}
                </Tabs>
              </TabsWrapper>

              {/* Labels */}
              <CreatableItemsWrapper data-test-id="labels_input">
                <Label htmlFor="labels">Labels</Label>
                <CreatableSelect
                  isMulti
                  isClearable
                  trim={true}
                  placeholder="Add Plan Labels and press enter..."
                  onChange={(options) => {
                    setFieldValue("labels", options);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === " ") {
                      e.preventDefault();
                    }
                  }}
                  options={[]}
                />
              </CreatableItemsWrapper>
            </Box>
            <Box>
              {/* Plan type*/}
              <div data-test-id="plan_type">
                <Select
                  value={first(PLAN_TYPES)}
                  options={PLAN_TYPES}
                  placeholder="Select Plan Type"
                  label="Type"
                  name="planType"
                  required
                  sx={{
                    mb: "20px",
                    cursor: "pointer",
                    backgroundColor: "whitesmoke",
                  }}
                  handleChange={(option: SelectOption) => {
                    setFieldValue("type", option.value, true);
                    setFieldTouched("type", true);
                  }}
                />
              </div>
              {/* Bustype */}
              <AutocompleteWrapper data-test-id="search-bustype">
                <Label htmlFor="busType">
                  {" "}
                  Bustype
                  <span
                    sx={{
                      ml: 1,
                      color: "crimson",
                    }}
                  >
                    *
                  </span>
                </Label>
                <Autocomplete
                  sx={{ pb: "20px" }}
                  suggestions={bustypeSuggestions}
                  placeholder="Search Bustype"
                  isLoading={isFetchingBustype}
                  onChangeQuery={(input) => setSearchBustype(input)}
                  getOptionLabel={(option) => option.name}
                  renderOption={(option) => renderSearchBustypeOptions(option)}
                  getOptionKey={(option) => option.id}
                  onSelect={(option) => {
                    setFieldValue("busType", option.id, true);
                    setFieldTouched("busType", true);
                  }}
                />
              </AutocompleteWrapper>
              {/* Price */}
              <Input
                data-test-id="price-input"
                label="Price"
                placeholder="Add Plan Price"
                type="number"
                variant="plain"
                onChange={(e) => {
                  setFieldValue("price", e.target.value, true);
                  setFieldTouched("price", true);
                }}
                endEnhancer={cityCurrency}
                min="0"
                required
              />
              {/* Schedule Ids */}
              <CreatableItemsWrapper
                data-test-id="schedule_id_input"
                sx={{ mt: "20px" }}
              >
                <Label htmlFor="labels">
                  Schedule Ids{" "}
                  <span
                    sx={{
                      ml: 1,
                      color: "crimson",
                    }}
                  >
                    *
                  </span>
                </Label>
                <CreatableSelect
                  isMulti
                  isClearable
                  trim={true}
                  placeholder="Add Schedule Ids and press enter..."
                  onChange={(options) => {
                    setFieldValue("schedules", options, true);
                    setFieldTouched("schedules", true);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === " ") {
                      e.preventDefault();
                    }
                  }}
                  options={[]}
                />
              </CreatableItemsWrapper>
            </Box>
            <Box>
              {/* Start Date */}
              <ClearableDateWrapper sx={{ mb: "20px" }}>
                <PlanStartDatePicker
                  label="Plan Start Date"
                  selected={planStartDate}
                  onChange={onPlanStartDateChange}
                  isClearable={true}
                ></PlanStartDatePicker>
              </ClearableDateWrapper>
              {/* Weekdays */}
              <div data-test-id="weekdays_input" sx={{ mb: "20px" }}>
                <Label htmlFor="weekdays">
                  {" "}
                  Weekdays
                  <span
                    sx={{
                      ml: 1,
                      color: "crimson",
                    }}
                  >
                    *
                  </span>
                </Label>
                <WeekdaysCheckbox
                  days={WEEKDAYS}
                  selectedDays={values.weekdays}
                  setSelectedDays={(days) => {
                    setFieldValue("weekdays", days, true);
                    setFieldTouched("weekdays", true);
                  }}
                />
              </div>
              {/* Active */}
              <ToggleWrapper data-test-id="toggle-active">
                <Toggle
                  labelOn={"Active"}
                  labelOff={"Inactive"}
                  checked={values.active}
                  id="toggle-active"
                  onChange={(e) => {
                    setFieldValue(
                      "active",
                      (e.target as HTMLInputElement).checked
                    );
                  }}
                  onOffLabelPosition={"start"}
                />
              </ToggleWrapper>
            </Box>
          </Grid>
          <Button
            icon={<Icon name="add" size={20} fill={"white"} />}
            sx={{ mt: "20px" }}
            variant="primary"
            type="submit"
            disabled={!formik.isValid || !formik.dirty || isLoadingPlanCreation}
            data-test-id="confirm_plan_btn"
          >
            Add Plan
          </Button>
        </form>
      </FormikProvider>
      {conflictData && (
        <PlanConflictPopup
          isOpen={openPlanPopupConflict}
          showUnassignButton={false}
          onDismiss={() => setOpenPlanPopupConflict(false)}
          conflictData={conflictData}
          timeZone={cityDetails?.timezone}
        />
      )}

      <GotoBiddingCreationPopup
        isOpen={openGotoBiddingCreationPopup}
        planId={planCreationSuccess?.id}
        onDismiss={() => setOpenGotoBiddingCreationPopup(false)}
      />
    </Fragment>
  );
};

export default CreatePlan;
