/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, Text } from "theme-ui";
import Popup from "@swvl/popup";
import Icon from "@swvl/icon";
import Button from "@swvl/button";

import { useBiddingHidden } from "resources/PlanSelling/useBiddingHidden";

type Props = {
  biddingPlanId: string;
  data: { hidden: boolean } | null;
  onDismiss: () => void;
};

const HideBiddingPopup = ({
  biddingPlanId,
  data,
  onDismiss,
}: Props): JSX.Element => {
  const { hidden } = data || {};
  const { mutate: updateBiddingHidden } = useBiddingHidden(biddingPlanId);

  return (
    <Popup isOpen={data !== null} closePopup={onDismiss} withClose>
      <div
        sx={{
          py: 3,
          px: 4,
        }}
      >
        <Icon name="warning" size={48} />
        <Text sx={{ fontSize: 3, mt: 3, fontWeight: "bold" }}>
          Make campaign {hidden ? "visible" : "hidden"}
        </Text>
        <Text sx={{ fontSize: 1, mt: 3, color: "black-54" }}>
          {hidden
            ? "Are you sure you want unhide this campaign from bidding, this will allow captains to be able to see and place bids on this campaign?"
            : "Are you sure you want hide this campaign from bidding?"}
        </Text>

        <div
          sx={{
            mt: 4,
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button variant="gray" onClick={onDismiss}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              onDismiss();
              updateBiddingHidden({ id: biddingPlanId, hidden: !hidden });
            }}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default HideBiddingPopup;
