import styled from "@emotion/styled";
import css from "@emotion/css";

export const FilterWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 16px;

  & + & {
    margin-top: 16px;
  }
`;

export const AddBiddingPlanWrapper = styled.form`
  width: 30%;

  .open-for-bid {
    display: flex;

    > div {
      flex-direction: row-reverse;
    }
  }
`;

export const Label = styled.label`
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  margin-bottom: 8px;
  margin-left: 8px;
  display: block;
`;

export const disabled = css`
  > label + div {
    opacity: 0.3;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DetailsContainer = styled.div`
  border: 1px solid #f8f8f8;
  border-radius: 6px;
  padding: 4px;
  display: flex;

  .details-main-info {
    width: 170px;
    padding: 24px 16px;
    background-color: #f8f8f8;
    border-radius: 3px;
    margin-right: 16px;

    a {
      display: block;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .toggle-wrapper {
      label {
        width: 100%;
        font-family: inherit;

        div[class$="SwitchWrapper"] {
          display: block;
          width: 100%;

          div[class$="OnOffTextWrapper"] {
            font-size: initial;
            font-family: inherit;
          }

          span[class$="Switch"] {
            margin-top: 8px;
          }
        }
      }
    }
  }

  .details-info-wrapper {
    padding: 24px 16px;

    .details-info {
      position: relative;
      padding-left: 23px;

      > svg {
        position: absolute;
        left: 0;
      }
    }
  }
`;
