import { useQuery, UseQueryResult } from "react-query";
import { AxiosResponse } from "axios";
import apiInstance, { checkIfRequestIntrceptorUsed } from "api";
import isNil from "lodash/fp/isNil";
interface BiddingPlanDetailsResponse {
  _id: string;
  plan: string;
  open_date: string;
  close_date: string;
  minimum_bus_year: number;
  hidden: boolean;
  state: string;
  type: string;
  fixed_price: number;
  maximum_price: number;
  minimum_price: number;
  maximum_bids_to_receive: number;
  minimum_placed_bid_amount: number;
  total_placed_bids: number;
  active_contract: ActiveContract;
}

interface ActiveContract {
  plan: string;
  plan_type: string;
  cities: City[];
  currency: string;
  ride_kilometers: number;
  dead_kilometers: number;
  weekdays: number[];
  start_location: Location;
  end_location: Location;
  start_time: number;
  predicted_end_time: number;
  duration_milliseconds: number;
  number_of_rides: number;
  bus_type: Bus;
  version_md5: string;
  schedules: Schedule[];
  plan_name: string;
}

interface City {
  _id: string;
  name: string;
}

interface Bus {
  _id: string;
  name: string;
}

interface Location {
  coordinates: Coordinates;
  name_i18n: NameI18N;
  city: City;
}

interface Coordinates {
  lat: number;
  lng: number;
}

interface NameI18N {
  en: string;
}

interface Schedule {
  start_location: Location;
  end_location: Location;
  start_time: number;
  predicted_end_time: number;
}

const getBiddingPlanDetails = async (id: string) => {
  const response = await apiInstance.get<
    AxiosResponse,
    BiddingPlanDetailsResponse
  >(`bidding_campaigns/${id}`);

  return response;
};
const useBiddingPlanDetails = (
  id: string
): UseQueryResult<BiddingPlanDetailsResponse, unknown> => {
  const query = useQuery(
    ["bidding_campaigns", id],
    () => getBiddingPlanDetails(id),
    {
      enabled: !isNil(id) && checkIfRequestIntrceptorUsed(),
    }
  );
  return query;
};

export { useBiddingPlanDetails };
