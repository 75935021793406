import { Switch, Route, useRouteMatch } from "react-router-dom";
import BidingList from "./BiddingList";
import CreateBiddingCampaign from "./CreateBiddingCampaign";
import BiddingDetails from "./BiddingDetails";

function PlanSelling(): JSX.Element {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact key="Biding List" path={path} component={BidingList} />
      <Route
        exact
        key="Create Bidding Campaign"
        path={`${path}/create`}
        component={CreateBiddingCampaign}
      />
      <Route
        exact
        key="Bidding Plan Details"
        path={`${path}/:id`}
        component={BiddingDetails}
      />
    </Switch>
  );
}

export default PlanSelling;
