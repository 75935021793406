import { isPast } from "date-fns";
import mapKeys from "lodash/fp/mapKeys";
import camelCase from "lodash/fp/camelCase";
import queryString from "query-string";
import addMinutes from "date-fns/addMinutes";
import { History } from "history";
import { format } from "date-fns";
import { AxiosResponse } from "axios";
import { showAlert } from "@swvl/alert";
export const CURRENCY_CONVERT = 100;
export const PAGE_SIZE = 10;
export const TIME_FORMAT = "hh:mm a";
export const DATE_DASH_FORMAT = "yyyy-MM-dd";
export const ISO_FORMT = "yyyy-MM-dd'T'HH:mm:ss.sss'Z'";
export const DATE_TIME_FORMAT = "dd/MM/yyy hh:mm a";
export const TIME_ZONE_OFFSET = new Date().getTimezoneOffset();
const SIGN_IN_URL = "sign_in";

export const getTimeOfMilliseconds = (milliseconds: number): string => {
  return getTimeOfMillisecondsWithTimezone(milliseconds, 0);
};

export const getTimeOfMillisecondsWithTimezone = (
  milliseconds: number,
  timezone: number
): string => {
  return format(new Date(milliseconds - timezone), TIME_FORMAT);
};

export type EmptyObject = Record<string, never>;

export const isExpired = (expiry: string) => {
  const expiryDate = new Date(expiry);
  return isPast(expiryDate);
};

export const toCamelCase = (object) => {
  return mapKeys((key: string) => {
    return camelCase(key);
  })(object);
};

export const WEEKDAYS = [
  { label: "Mo", value: 1 },
  { label: "Tu", value: 2 },
  { label: "We", value: 3 },
  { label: "Th", value: 4 },
  { label: "Fr", value: 5 },
  { label: "Sa", value: 6 },
  { label: "Su", value: 7 },
];

export const getFromQuery = (key: string, search: string): string => {
  const queryParams = queryString.parse(search, {
    arrayFormat: "bracket",
  });
  return queryParams[key] as string;
};

export const getAllQueries = (search: string): queryString.ParsedQuery => {
  return queryString.parse(search, {
    arrayFormat: "bracket",
  });
};

export const handleQuery = (
  type: "add" | "remove",
  history: History,
  key: string,
  value?: string | string[] | null
): void => {
  const { location } = history;
  const searchParams = queryString.parse(location.search, {
    arrayFormat: "bracket",
  });
  const pathname = location.pathname;
  if (type === "add") {
    searchParams[key] = value;
  } else if (type === "remove") {
    delete searchParams[key];
  }
  const stringifiedParams = queryString.stringify(searchParams, {
    arrayFormat: "bracket",
  });
  history.replace({
    ...history.location,
    pathname,
    search: stringifiedParams,
  });
};

export function millisecondsToMin(milliseconds: number): number {
  return milliseconds / 60000;
}

export function toUTCISOString(date: Date): string {
  return date ? addMinutes(date, TIME_ZONE_OFFSET * -1).toISOString() : null;
}

export function toUTCZonedDate(date: Date, timezone: number) {
  const zonedDate = addMinutes(date, timezone * -1);
  return format(zonedDate, ISO_FORMT);
}

export function toLocaleDate(date: string): Date {
  return date ? addMinutes(new Date(date), TIME_ZONE_OFFSET) : null;
}

export function getAdminDashboardLink(): string {
  let adminDashboardLink = "https://dashboard.swvl.io";

  if (window.__env__.NODE_ENV === "development") {
    const apiURL = window.__env__.API_BASE_URL;
    const envName = apiURL.substring(
      apiURL.indexOf("/") + 2,
      apiURL.indexOf("-api")
    );
    const cluster = apiURL.match(/(?<=api)(.*)(?=swvl)/gm);
    adminDashboardLink = `https://${envName}-admin-dashboard${cluster}swvl.io`;
  }

  return adminDashboardLink;
}

export const pluralize = (
  count: number,
  text: string,
  suffix = "s"
): string => {
  return `There ${count !== 1 ? "are" : "is"} ${count} ${text}  ${
    count !== 1 ? suffix : ""
  }`;
};

export const pluralizeWord = (
  count: number,
  word: string,
  suffix = "s"
): string => {
  return `${word}${count !== 1 ? suffix : ""}`;
};

export function getErrorMessage(response: AxiosResponse): string {
  let message = "";
  if (response?.data) {
    message = response.data.message || response.data.error.message;
  }
  return message;
}

export function onErrorHandler(response: AxiosResponse): void {
  if (!response) return;

  const message = getErrorMessage(response);
  if (response.config?.url !== SIGN_IN_URL) {
    showAlert({
      message,
      type: "error",
      id: "alert-error",
    });
  }
}
