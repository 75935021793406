/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { FC, useEffect } from "react";
import { useHistory } from "react-router-dom";
import isNil from "lodash/fp/isNil";
import loginBackground from "assets/login@1x.png";
import { BackgroundImage, FormWrapper } from "./styled";
import Form from "./LoginForm";
import useLocalStorage from "utils/useLocalStorage";
import { AuthenticationModel, initialState } from "resources/Auth/useLogin";

const Login: FC = () => {
  const history = useHistory();
  const {
    storedValue: { token },
  } = useLocalStorage<AuthenticationModel>("ls.authdata", initialState);

  useEffect(() => {
    if (!isNil(token)) {
      history.push("/");
    }
  }, [token]);

  return (
    <BackgroundImage img={loginBackground} gradient>
      <FormWrapper>
        <Form />
      </FormWrapper>
    </BackgroundImage>
  );
};

export default Login;
