import { Switch, Route, useRouteMatch } from "react-router-dom";
import CreatePlan from "./CreatePlan";
import PlanDetails from "./PlanDetails";
import PlansList from "./PlansList";

function Plans() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact key="Listing" path={`${path}`} component={PlansList} />
      <Route
        exact
        key="Create"
        path={`${path}/create`}
        component={CreatePlan}
      />
      <Route exact key="Details" path={`${path}/:id`} component={PlanDetails} />
    </Switch>
  );
}

export default Plans;
