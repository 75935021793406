import { useQuery, UseQueryResult } from "react-query";
import { AxiosResponse } from "axios";
import apiInstance from "api";

type CancellationReasonsResponse = { key: string; value: string }[];
type CancellationReasons = { label: string; value: string }[];

const getBiddingCancellationReasons = async () => {
  const response = await apiInstance.get<
    AxiosResponse,
    CancellationReasonsResponse
  >("bidding_campaigns/cancellation_reasons");

  return response.map(({ key, value }) => ({ label: value, value: key }));
};

const useBiddingCancellationReasons = (): UseQueryResult<
  CancellationReasons,
  unknown
> => {
  const query = useQuery(
    ["bidding_campaigns", "cancellation_reasons"],
    getBiddingCancellationReasons,
    {}
  );
  return query;
};

export { useBiddingCancellationReasons };
