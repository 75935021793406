import { useMutation, useQueryClient } from "react-query";
import apiInstance from "api";
import { showAlert } from "@swvl/alert";

type BiddingPlanParams = {
  id: string;
  rejection_reason_key: string;
};
const acceptBid = async ({ id, rejection_reason_key }: BiddingPlanParams) => {
  const response = await apiInstance.patch(`bids/${id}/reject`, {
    rejection_reason_key,
  });
  return response;
};

const useRejectBid = (id, captainName, price) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(acceptBid, {
    onSuccess: () => {
      queryClient.invalidateQueries(["bidding_campaigns", id]);
      queryClient.invalidateQueries(["bidding_campaigns", id, "bids"]);
      showAlert({
        message: `You have rejected this bid successfully with captain ${captainName} with the price ${price}`,
        type: "success",
        id: "alert-success",
      });
    },
  });
  return mutation;
};

export { useRejectBid };
