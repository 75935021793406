import { Fragment } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Flex, Heading, Divider } from "theme-ui";
import { Button } from "@swvl/button";
import Icon from "@swvl/icon";

function ListingHeader(): JSX.Element {
  const history = useHistory();
  const { path } = useRouteMatch();

  return (
    <Fragment>
      <Flex
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Heading>All Bidding Plans</Heading>
        <Button
          variant="primary"
          icon={<Icon name="add" size={20} fill={"white"} />}
          onClick={() => {
            history.push(`${path}/create`);
          }}
          data-test-id="create_new_bidding_btn"
        >
          Create New Bidding Plan
        </Button>
      </Flex>
      <Divider color="gainsboro" sx={{ m: "20px -20px" }} />
    </Fragment>
  );
}

export default ListingHeader;
