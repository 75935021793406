import { useMutation, useQueryClient } from "react-query";
import apiInstance from "api";
import { showAlert } from "@swvl/alert";

type BiddingHiddenParams = {
  id: string;
  hidden: boolean;
};
const updateBiddingHidden = async ({ id, hidden }: BiddingHiddenParams) => {
  const response = await apiInstance.patch(`bidding_campaigns/${id}/hidden`, {
    hidden,
  });
  return response;
};

const useBiddingHidden = (id) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(updateBiddingHidden, {
    onSuccess: () => {
      queryClient.invalidateQueries(["bidding_campaigns", id]);
      queryClient.invalidateQueries(["bidding_campaigns", id, "bids"]);
      showAlert({
        message: "Bidding Visibility is updated successfully",
        type: "success",
        id: "alert-success",
      });
    },
  });
  return mutation;
};

export { useBiddingHidden };
