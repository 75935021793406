import { AxiosResponse } from "axios";
import apiInstance from "api";

export type SearchEndLocationModel = {
  label: string;
  value: string;
};

type SearchEndLocationListModel = SearchEndLocationModel[];

const transformResponse = ({
  data,
}: AxiosResponse<string[]>): SearchEndLocationListModel => {
  return data.map((name) => ({
    label: name,
    value: name,
  }));
};

const searchEndLocation = async (
  query: string,
  city: string
): Promise<SearchEndLocationListModel> => {
  const response = await apiInstance.get<string[]>(
    "bidding_campaigns/end_location/autocomplete",
    {
      params: { query, city },
    }
  );

  return transformResponse(response);
};

export { searchEndLocation };
