/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Heading } from "theme-ui";
import { FC, useState } from "react";
import Icon from "@swvl/icon";
import Popup from "@swvl/popup";
import { Button } from "@swvl/button";
import CreatableSelect from "react-select/creatable";
import { usePlanLabels } from "resources/Plan/usePlanLabels";
import { CreatableItemsWrapper } from "./styled";

type UpdatePlanLabelsPopupProps = {
  onDismiss?: () => void;
  isOpen: boolean;
  planId: string;
  labels: { label: string; value: string }[];
};

const UpdatePlanLabelsPopup: FC<UpdatePlanLabelsPopupProps> = ({
  onDismiss,
  isOpen,
  planId,
  labels,
}) => {
  const { mutate: updateLabels } = usePlanLabels(planId);

  const Content = () => {
    const [planLabels, setPlanLabels] =
      useState<Array<{ label: string; value: string }>>();

    const handelUpdateLabels = (formatLabels) => {
      const labels = formatLabels?.map((label) => {
        return label.value;
      });
      updateLabels({ planId, labels });
    };
    return (
      <section sx={{ mt: 3, mx: 5 }} data-test-id="edit_label_popup">
        <div
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Icon
            name="warning"
            size={50}
            fill="#FFC659"
            sx={{ borderRadius: "50%" }}
          />
        </div>
        <section sx={{ display: "flex", flexDirection: "column" }}>
          <Heading
            sx={{ mb: 3, fontWeight: "bold" }}
            variant={"headline"}
            data-test-id="popup_title"
          >
            Update Plan Labels
          </Heading>
          <CreatableItemsWrapper data-test-id="label_item">
            <CreatableSelect
              sx={{ mb: 3 }}
              isMulti
              isClearable
              trim={true}
              placeholder="Add Plan Labels and press enter..."
              defaultValue={labels}
              onChange={(inputs) => {
                setPlanLabels(
                  inputs as Array<{ label: string; value: string }>
                );
              }}
              onKeyDown={(e) => {
                if (e.key === " ") {
                  e.preventDefault();
                }
              }}
              options={[]}
            />
          </CreatableItemsWrapper>
          <div sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
            <Button
              type="button"
              variant="gray"
              onClick={onDismiss}
              data-test-id="cancel_button"
            >
              Cancel
            </Button>
            <Button
              type="button"
              variant="primary"
              disabled={!planLabels}
              onClick={() => {
                handelUpdateLabels(planLabels);
                onDismiss();
              }}
              data-test-id="confirm_button"
            >
              Confirm
            </Button>
          </div>
        </section>
      </section>
    );
  };

  return (
    <Popup withClose closePopup={onDismiss} isOpen={isOpen} width={500}>
      <Content />
    </Popup>
  );
};

export default UpdatePlanLabelsPopup;
