import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import apiInstance from "api";
import { isNil } from "lodash";

const bustypeAutocomplete = "bustype/autocomplete";
export interface SearchBustypesResponse {
  hits: {
    _id: string;
    _source: {
      name: string;
    };
  }[];
}

export type SearchBustypesModel = {
  id: string;
  name: string;
};

type SearchBustypesListModel = SearchBustypesModel[];

const transformResponse = (
  response: SearchBustypesResponse
): SearchBustypesListModel => {
  return response.hits.map((hit) => ({
    id: hit._id,
    ...hit._source,
    value: hit._id,
    label: hit._source.name,
  })) as SearchBustypesListModel;
};

const searchBustypes = async (
  input: string
): Promise<SearchBustypesListModel> => {
  const response = await apiInstance.get<AxiosResponse, SearchBustypesResponse>(
    bustypeAutocomplete,
    {
      params: { query: input },
    }
  );

  return transformResponse(response);
};

const useSearchBustypes = (input: string) => {
  return useQuery([bustypeAutocomplete, input], () => searchBustypes(input), {
    enabled: !isNil(input),
    initialData: [] as SearchBustypesListModel,
    onError: undefined,
  });
};

export { useSearchBustypes, searchBustypes, transformResponse };
