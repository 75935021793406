import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "theme-ui";
import theme from "@swvl/theme";
import { AxiosResponse } from "axios";
import { onErrorHandler } from "utils";
import Main from "./pages";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      onError: (response) => {
        onErrorHandler(response as AxiosResponse);
      },
    },
    mutations: {
      onError: (response) => {
        onErrorHandler(response as AxiosResponse);
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Router basename={"/marketplace"}>
          <Switch>
            <Route key="Main" path="/" component={Main} />
          </Switch>
        </Router>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
