import { useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import { showAlert } from "@swvl/alert";
import apiInstance from "api";

const deletePlan = async (id: string) => {
  return await apiInstance.delete(`plans/${id}`);
};

const useDeletePlan = () => {
  const history = useHistory();
  const mutation = useMutation(deletePlan, {
    mutationKey: "plans/delete",
    onSuccess: () => {
      history.push(`/plans`);
      showAlert({
        message: "You have deleted a plan successfully",
        type: "success",
        id: "alert-success",
      });
    },
  });
  return mutation;
};

export { useDeletePlan, deletePlan };
