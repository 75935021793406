/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text, Heading } from "theme-ui";
import { FC } from "react";
import Icon from "@swvl/icon";
import Popup from "@swvl/popup";
import { Button } from "@swvl/button";
import { useUnassignCaptain } from "resources/Plan/useUnassignCaptain";

type UnassignCaptainPopupProps = {
  onDismiss?: () => void;
  isOpen: boolean;
  planId: string;
  captain: string;
};

const UnassignCaptainPopup: FC<UnassignCaptainPopupProps> = ({
  onDismiss,
  isOpen,
  planId,
  captain,
}) => {
  const { mutate: unassignCaptain } = useUnassignCaptain(planId);

  const Content = () => {
    return (
      <section sx={{ mt: 3, mx: 5 }}>
        <div
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Icon name="unassign" size={50} fill={"#FFC659"} />
        </div>
        <section sx={{ display: "flex", flexDirection: "column" }}>
          <Heading sx={{ mb: 3, fontWeight: "bold" }} variant={"headline"}>
            Unassign Captain
          </Heading>
          <Text sx={{ mb: 4, color: "opacity.black-54", lineHeight: "1.8" }}>
            Are you sure you want to unassign the captain{" "}
            <span sx={{ fontWeight: "bold" }}>{captain}</span> from this plan?
          </Text>
          <div sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
            <Button
              data-test-id="cancel-button"
              type="button"
              variant="gray"
              onClick={onDismiss}
            >
              Cancel
            </Button>
            <Button
              data-test-id="confirm-button"
              type="button"
              variant="primary"
              onClick={() => {
                unassignCaptain({ planId, captain });
                onDismiss();
              }}
            >
              Confirm
            </Button>
          </div>
        </section>
      </section>
    );
  };

  return (
    <Popup withClose closePopup={onDismiss} isOpen={isOpen} width={500}>
      <Content />
    </Popup>
  );
};

export default UnassignCaptainPopup;
