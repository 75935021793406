import Icon from "@swvl/icon";
import { SidebarTabs } from "../components/Sidebar";

export const sidebarTabs: SidebarTabs = [
  {
    id: "plans",
    text: "Plans",
    icon: <Icon name="scheduling" fill="currentcolor" />,
    link: "/plans?page=1",
  },
  {
    id: "plan-selling",
    text: "Plan Selling",
    icon: <Icon name="employees" fill="currentcolor" />,
    link: "/plan-selling",
  },
];

export default sidebarTabs;
