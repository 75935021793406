import { useMutation } from "react-query";
import apiInstance from "api";
import { showAlert } from "@swvl/alert";
import { EmptyObject } from "utils/index";

type Type = "schedule" | "time";
export interface CreatePlanRequest {
  city: string;
  price: { amount: number; currency: string };
  type: string;
  active: boolean;
  busType: string;
  captain?: string;
  nameI18n: EmptyObject;
  weekdays: number[];
  descriptionI18n?: EmptyObject;
  labels?: string[];
  schedules?: [];
}

export interface CreatePlanResponse {
  id: string;
}

export interface CreatePlanFormData {
  city: string;
  price: { amount: number; currency: string };
  active: boolean;
  busType: string;
  captain?: string;
  nameI18n: { [key: string]: string };
  weekdays: number[];
  descriptionI18n?: { [key: string]: string };
  labels?: string[];
  schedules?: string[];
  type: Type;
  startDate?: string | null;
}

// Resources
const createPlan = async (form: CreatePlanFormData) => {
  const response = await apiInstance.post<
    CreatePlanRequest,
    CreatePlanResponse
  >("plans", form);
  return response;
};

const useCreatePlan = () => {
  const mutation = useMutation(createPlan, {
    mutationKey: "plan/create",
    onSuccess: () => {
      showAlert({
        message: "You have added a new plan successfully",
        type: "success",
        id: "alert-success",
      });
    },
  });
  return mutation;
};

export { useCreatePlan, createPlan };
