import { useQuery, UseQueryResult } from "react-query";
import { AxiosResponse } from "axios";
import apiInstance, { checkIfRequestIntrceptorUsed } from "api";
import { ListPlansParams } from "../../pages/plans/constants";

type PriceModel = {
  amount: number;
  currency: string;
};
type NameModel = {
  [key: string]: string;
};

type ScheduleModel = {
  city: {
    id: string;
    timezone: number;
  };
  id: string;
  predictedEndTime: number;
  time: number;
};

export interface ListPlansResponse {
  count: number;
  plans: Plan[];
}

export type Plan = {
  active: boolean;
  labels: string[];
  state: string;
  startTime: number;
  endTime: number;
  id: string;
  nameI18n: NameModel;
  price: PriceModel;
  schedules: ScheduleModel[];
  rideKMs: number;
  type: string;
  busType: {
    id: string;
    name: string;
  };
  noOfSchedules: number;
  isOnBidding: boolean;
};

const getAllPlans = async (params: ListPlansParams) => {
  const response = await apiInstance.get<AxiosResponse, ListPlansResponse>(
    "plans",
    {
      params,
    }
  );

  return response;
};

const useListPlans = (
  params: ListPlansParams
): UseQueryResult<ListPlansResponse> => {
  const query = useQuery(["plans", params], () => getAllPlans(params), {
    enabled: checkIfRequestIntrceptorUsed(),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  return query;
};
export { useListPlans };
