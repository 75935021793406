import { useMutation } from "react-query";
import apiInstance from "api";
import { showAlert } from "@swvl/alert";

type PriceModel = {
  amount: number;
  currency: string;
};

export interface UpdatePriceResponse {
  id: string;
  price: PriceModel;
}

export type UpdatePriceModel = {
  price: PriceModel;
};

// Resources
const updatePlanPrice = async ({ id, price }: UpdatePriceResponse) => {
  const response = await apiInstance.patch<UpdatePriceResponse>(
    `plans/${id}/update_price`,
    { price }
  );
  return response;
};

const usePlanPrice = () => {
  const mutation = useMutation(updatePlanPrice, {
    mutationKey: "plan/updatePrice",
    onSuccess: () => {
      showAlert({
        message: "Plan price has been updated successfully",
        type: "success",
        id: "alert-success",
      });
    },
  });
  return mutation;
};

export { usePlanPrice, updatePlanPrice };
