import axios from "axios";
import isNil from "lodash/fp/isNil";
import { showAlert } from "@swvl/alert";
import { isExpired } from "../utils";
import {
  RefreshTokenRequest,
  RefreshTokenTransform,
  initialState,
} from "../resources/Auth/useRefreshToken";
import { UseMutateFunction } from "react-query";
import { RouterHistory } from "../utils/types";

const apiInstance = axios.create({
  // eslint-disable-next-line
  // @ts-ignore
  baseURL: window.__env__.API_BASE_URL,
  timeout: 20000,
});

/** Request interceptor for
 * - Adding the `Authorization` header in every non-auth url
 * - Refreshing expired tokens before proceeding
 */
export const createRequestInterceptor = (
  makeRefreshToken: UseMutateFunction<
    RefreshTokenTransform,
    unknown,
    RefreshTokenRequest,
    unknown
  >
) => {
  return apiInstance.interceptors.request.use((config) => {
    const authData = window.localStorage.getItem("ls.authdata");
    const { token, expiryDate, refreshToken } = authData
      ? JSON.parse(authData)
      : initialState;

    let authToken = token;
    /** Check if token is available, skip sign in request */
    if (config.url !== "sign_in" && !isNil(token)) {
      /** Refresh if token is expired, skip refresh token request */
      if (config.url !== "token/refresh" && isExpired(expiryDate)) {
        makeRefreshToken({ refresh_token: refreshToken });
        authToken = JSON.parse(
          window.localStorage.getItem("ls.authdata")
        )?.token;
      }

      /** Add Authorization header */
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  });
};

/** Response interceptor for
 * - Getting the data from success response
 * - Logging out if response status is 401 UNAUTHORIZED
 * - Rejecting the error response
 */
export const createResponseInterceptor = ({ history }: RouterHistory) => {
  const UNAUTHORIZED = 401;

  return apiInstance.interceptors.response.use(
    (response) => {
      return response.data;
    },
    (error) => {
      if (error.response.status === UNAUTHORIZED) {
        const back = encodeURIComponent(history.location.pathname);
        window.localStorage.setItem(
          "ls.authdata",
          JSON.stringify(initialState)
        );
        history.push({
          pathname: "/login",
          search: `back=${back}`,
        });
        showAlert({
          message: "Session has been expired",
          type: "error",
          id: "alert-error",
        });
      }
      return Promise.reject(error.response);
    }
  );
};

/** Response interceptor without store for nock testing **/
export const createResponseInterceptorTest = () => {
  return apiInstance.interceptors.response.use((response) => {
    return response.data;
  });
};

/** Eject both request and response interceptors */
export const ejectInterceptors = (reqInterceptor, resInterceptor) => {
  apiInstance.interceptors.request.eject(reqInterceptor);
  apiInstance.interceptors.response.eject(resInterceptor);
};

/** Flag that check if request interceptors used or not */
export const checkIfRequestIntrceptorUsed = (): boolean =>
  Boolean(apiInstance.interceptors.request["handlers"].length);

export default apiInstance;
