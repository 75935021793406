/** @jsxRuntime classic */
/** @jsx jsx */
import { FC } from "react";
import { jsx, Text } from "theme-ui";

type NoListResultsProps = {
  title?: string;
  body: string;
};

const NoListResults: FC<NoListResultsProps> = ({ title, body, ...rest }) => (
  <div
    sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    {...rest}
  >
    <Text variant="headline" sx={{ fontWeight: "bold" }}>
      {title}
    </Text>
    <Text variant="body-large" css={{ textAlign: "center" }}>
      {body}
    </Text>
  </div>
);

export default NoListResults;
