/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text, Heading } from "theme-ui";
import { FC } from "react";
import Icon from "@swvl/icon";
import Popup from "@swvl/popup";
import { Button } from "@swvl/button";
import { useHistory } from "react-router-dom";

type GotoBiddingCreationPopupProps = {
  onDismiss?: () => void;
  isOpen: boolean;
  planId: string;
};

const GotoBiddingCreationPopup: FC<GotoBiddingCreationPopupProps> = ({
  onDismiss,
  isOpen,
  planId,
}) => {
  const Content = () => {
    const history = useHistory();
    return (
      <section sx={{ mt: 3, mx: 5 }}>
        <div
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Icon name="employees" size={50} fill={"#FFC659"} />
        </div>
        <section sx={{ display: "flex", flexDirection: "column" }}>
          <Heading
            sx={{ mb: 3, fontWeight: "bold" }}
            variant={"headline"}
            data-test-id="popup-title"
          >
            Create a new bidding campaign
          </Heading>
          <Text
            sx={{ mb: 4, color: "opacity.black-54", lineHeight: "1.8" }}
            data-test-id="popup-description"
          >
            Would you like to create a new bidding campaign ?
          </Text>
          <div sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
            <Button
              data-test-id="cancel-button"
              type="button"
              variant="gray"
              onClick={() => {
                onDismiss();
                history.push(`/plans/${planId}`);
              }}
            >
              Cancel
            </Button>
            <Button
              data-test-id="confirm-button"
              type="button"
              variant="primary"
              icon={<Icon name="add" size={20} fill={"white"} />}
              onClick={() => {
                history.push(`/plan-selling/create?plan=${planId}`);
                onDismiss();
              }}
            >
              Create new bidding campaign
            </Button>
          </div>
        </section>
      </section>
    );
  };

  return (
    <Popup withClose closePopup={onDismiss} isOpen={isOpen} width={500}>
      <Content />
    </Popup>
  );
};

export default GotoBiddingCreationPopup;
