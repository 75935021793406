import { useMutation, useQueryClient } from "react-query";
import apiInstance from "api";
import { showAlert } from "@swvl/alert";

type BiddingPlanParams = {
  id: string;
  params: {
    close_date: string;
  };
};
const updateBiddingCloseDate = async ({ id, params }: BiddingPlanParams) => {
  const response = await apiInstance.patch(
    `bidding_campaigns/${id}/close_date`,
    params
  );
  return response;
};

const useUpdateBiddingCloseDate = (id) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(updateBiddingCloseDate, {
    onSuccess: () => {
      queryClient.invalidateQueries(["bidding_campaigns", id]);
      showAlert({
        message: "Start Date Time is updated successfully",
        type: "success",
        id: "alert-success",
      });
    },
  });
  return mutation;
};

export { useUpdateBiddingCloseDate };
