/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Text, Flex } from "theme-ui";
import { FC, memo, useState, useMemo, useEffect } from "react";
import { matchPath, useLocation, Link } from "react-router-dom";
import { SIDEBAR_WIDTH, NAV_HEIGHT } from "constants/styles";
import LogoImage from "assets/swvl-logo.svg";

export type SidebarState = {
  activeTabKey: string;
};

export type SidebarTabProps = {
  id?: string;
  icon?: JSX.Element;
  link?: string;
  text: string;
  isActive?: boolean;
  onSelect?: () => void;
};

export type SidebarTabs = SidebarTabProps[];

type SidebarProps = {
  tabs: SidebarTabs;
};

const StyledSidebarTab = (props) => (
  <li
    sx={{
      bg: "deepdarkslategray",
      color: "whitesmoke",
      overflow: "hidden",
      display: "flex",
      justifyContent: "space-between",
      pl: "10px",
      height: 56,
      "&.active": {
        bg: "darkslategray",
      },
      span: {
        position: "absolute",
        left: 0,
        height: "inherit",
        width: "3px",
        bg: "primary",
        transition: "transform 0.2s",
        transform: "translate3d(-100%, 0, 0)",
        "&.active": {
          transitionDuration: "0.2s",
          fontWeight: 700,
          transitionDelay: "0.2s",
          transform: "translate3d(0, 0, 0)",
        },
      },
    }}
    {...props}
  />
);

const IconWrapper = (props) => (
  <div
    sx={{
      width: 32,
      height: 32,
      display: "flex",
      alignItems: "center",
      borderRadius: "50%",
      justifyContent: "center",
    }}
    {...props}
  />
);

const SidebarTab: FC<SidebarTabProps> = (props) => {
  const { id, icon, text, link, isActive, onSelect } = props;

  /** Match route */
  const location = useLocation();
  const match = useMemo(
    () =>
      matchPath(location.pathname, {
        path: link,
      }),
    [location.pathname, link]
  );

  /** Navigate to tab on route matched */
  useEffect(() => {
    if (match && match.path) onSelect();
  }, [match]);

  return (
    <Link
      to={link}
      sx={{
        textDecoration: "none",
      }}
    >
      <StyledSidebarTab
        className={`${isActive ? "active" : ""}`}
        onClick={() => onSelect()}
      >
        <span className={`${isActive ? "active" : ""}`} />
        <Flex
          sx={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {icon && <IconWrapper isActive={isActive}>{icon}</IconWrapper>}
          <Text
            as="p"
            id={id + "Tab"}
            variant="body-large"
            sx={{ flex: 1, ml: 2, fontWeight: isActive ? 700 : "normal" }}
          >
            {text}
          </Text>
        </Flex>
      </StyledSidebarTab>
    </Link>
  );
};

const Sidebar: FC<SidebarProps> = (props) => {
  const { tabs } = props;
  const [activeTabKey, selectTab] = useState<string>(null);

  const renderTabs = () => {
    return tabs.map((tab) => {
      return (
        <SidebarTab
          id={tab.id}
          key={tab.id}
          link={tab.link.startsWith("/") ? tab.link : `/${tab.link}`}
          isActive={activeTabKey === tab.id}
          onSelect={() => selectTab(tab.id)}
          {...tab}
        />
      );
    });
  };

  return (
    <div css={{ gridArea: "sidebar" }}>
      <div
        sx={{
          pt: 24,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          position: "fixed",
          width: SIDEBAR_WIDTH,
          height: `calc(100vh - ${NAV_HEIGHT}px)`,
          backgroundColor: "deepdarkslategray",
          zIndex: 9999,
          overflow: "auto",
        }}
      >
        <ul
          sx={{
            margin: 0,
            padding: 0,
            position: "relative",
            listStyle: "none",
          }}
        >
          {renderTabs()}
        </ul>
        <div
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "2rem 0",
            backgroundColor: "deepdarkslategray",
            position: "sticky",
            bottom: 0,
          }}
        >
          <Text
            variant="subhead"
            sx={{
              marginRight: "10px",
              marginBottom: 0,
              fontWeight: 700,
              color: "white",
            }}
          >
            Powered by
          </Text>
          <img
            alt="Swvl Logo"
            width={70}
            src={LogoImage}
            sx={{
              borderRadius: "4px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(Sidebar);
